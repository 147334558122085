import { Image } from "antd";

import styles from "../styles/PageNotFound.module.css";

import rexvenLogo from "../components/assets/rexven-logo.svg";

const Home: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Image preview={false} src="/rexven-logo.jpg" alt="Rexven-Logo" />
        <Image preview={false} src={rexvenLogo} alt="Rexven-Logo" />
      </div>
    </div>
  );
};
export default Home;
