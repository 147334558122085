import { Providers } from "../../../interfaces/adminInterfaces";
import React, { useState } from 'react';
import { Button, Image, Modal, Pagination, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import adminService from "../../../services/adminService";
import { Link } from "react-router-dom";
import alertNotification from "../../../utils/alertNotification";
import { API_IMAGE_URL, SP_IMAGE_URL } from "../../../config";
import { PaginationProps } from "antd/es/pagination";
import {
  InfoCircleOutlined,
  DeleteFilled,
  CheckOutlined
} from "@ant-design/icons";


interface ProvidersTableProps {
  providers: Providers[]
  totalPage?: number,
  getProviders: (query: string) => void
  current: number,
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  totalPageCount: number
}


const ProvidersTable: React.FC<ProvidersTableProps> = ({ providers, getProviders, current, setCurrent }) => {

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<Providers>()

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = (id: number | string) => {
    adminService.deleteProviders(id).then((res) => {
      alertNotification('success', `${id} - Hizmet Sağlayıcı başarıyla silindi`)
    }).catch((err) => {
      alertNotification('error', `${id} - Hizmet Sağlayıcı silinemedi!`)
    }).finally(() => {
      getProviders('all');
    })
  }

  const handleRowClick = (data: typeof providers[0]) => {
    showModal()
    setSelectedData(data)
  }

  const updateProvider = (id: number | string, requestStatus: string) => {
    const payload = {
      id,
      requestStatus,
      status: requestStatus === 'approved' ? 'active' : 'passive'
    }

    adminService.updateProvider(Number(id), payload)
      .then(() => {
        alertNotification('success', 'Hizmet Sağlayıcı başarıyla eklendi.')
        getProviders('status=all&requestStatus=')
        handleCancel()
      }).catch(e => {
        alertNotification('error', e.response.data.message)
      })

  }

  // const onChange: PaginationProps["onChange"] = (value) => {
  //   setCurrent(value)
  // };

  const defineBackground = (status: string): string => {
    if (status?.toLowerCase() === 'approved') return '#87d068'
    else if (status?.toLowerCase() === 'declined') return "#f50"
    else return '#2db7f5'

  }


  const columns: ColumnsType<Providers> = [
    {
      title: "Order",
      dataIndex: 'order',
      key: "Order",
      render: (_, __, index) => index + 1

    },
    {
      title: "Image",
      dataIndex: "imagePath",
      render: (_, record) => (
        <img style={{ height: "70px", width: "70px" }} src={`${SP_IMAGE_URL}/${record.profile_image}`} alt="gelmedi" />
      ),
      key: "imagePath",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
    },
    {
      title: "Description",
      key: "Description",
      render: (_, record) => (
        <Space size="middle">
          {<p>{`${record.description.substring(0, 80)}`}</p>}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
    },
    {
      title: "Request Status",
      dataIndex: "request_status",
      key: "Request Status",
      render: (_, record) => (
        <div onClick={() => record.request_status?.toLowerCase() === 'requested' && handleRowClick(record)} style={{ cursor: `${record.request_status?.toLowerCase() === 'requested' ? 'pointer' : ''}` }}>
          <Tag
            color={defineBackground(record.request_status!)}>{record.request_status}</Tag>
          {record.request_status?.toLowerCase() === 'requested' && <Tooltip placement="topRight" title='incele'  >

            <InfoCircleOutlined />
          </Tooltip>}
        </div>
      ),

    },
    {
      title: "Services",
      dataIndex: "servicesString",
      key: "Services",
    },
    {
      title: 'Premium',
      dataIndex: 'is_premium',
      key: 'premium',
      render: (_, record) => (
        record.is_premium && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#87d068', fontSize: '24px' }}>

            <CheckOutlined color="green" />
          </div>
        )
      )


    },
    {
      title: "Update",
      render: (_, record) => (
        <Space size="middle">
          {
            record.request_status?.toLowerCase() !== 'requested' &&
            <Link to={`/service-providers/${record.id}`}>Edit</Link>
          }
        </Space>
      ),
      key: "UpdateDelete",
    },
    {
      title: "Delete",
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>

          <DeleteFilled style={{ fontSize: '24px', color: 'red' }} onClick={() => handleClick(record.id)} />
        </div>

      ),
      key: "delete",
    },
  ];

  const renderTitle = () => {
    return <div style={{ display: 'flex', alignItems: 'center' }}>
      <Image src={`${SP_IMAGE_URL}/${selectedData?.profile_image}`} width={75} />
      <div style={{ margin: '10px' }}>

        <p>{selectedData?.name}</p>
        <p style={{ fontWeight: 'normal', fontSize: '14px' }}>{selectedData?.email}</p>
      </div>
    </div>
  }

  return (
    <div>
      <>
        <Modal title={selectedData && renderTitle()} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={
          [
            <Button type='primary' danger onClick={() => updateProvider(selectedData?.id!, 'declined')}>Decline</Button>,
            <Button type='primary' onClick={() => updateProvider(selectedData?.id!, 'approved')} > Approve</Button>
          ]
        } >
          <p style={{ marginBottom: '10px' }}>{selectedData?.description}</p>
          <hr style={{ border: '1px solid black', marginBottom: '15px' }} />
          {selectedData?.services?.length ? (
            <div>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Hizmetler:</h3>
              {selectedData.services.map((service, index) => (
                <div key={index} style={{ marginBottom: '15px' }}>
                  <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    {index + 1}. {service.serviceName}: {service.serviceFee} TL
                  </p>
                  <p style={{ marginBottom: '5px' }}>
                    <span style={{ marginLeft: '10px' }}>{service.description}</span>
                  </p>
                </div>
              ))}
            </div>
          ) : null}
          <hr style={{ border: '1px solid black', marginBottom: '15px' }} />


          {/* <p> <span style={{ fontWeight: 'bold' }} >Hizmet Bedeli :</span> {selectedData?.serviceFee}</p> */}

          {

            selectedData?.social_media_links ? (
              <>
                <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Sosyal Medya:</h3>
                {
                  selectedData?.social_media_links.socialMedia && selectedData.social_media_links.website &&
                  <>
                    <p><span style={{ fontWeight: 'bold' }}>Website : </span> </p>
                    <p><a href={selectedData.social_media_links.website} target="blank">{selectedData?.social_media_links?.website!}</a></p>
                  </>
                }
                <p style={{ fontWeight: 'bold' }}>Social Media : </p>
                {
                  selectedData?.social_media_links.socialMedia && JSON.parse(selectedData?.social_media_links?.socialMedia as unknown as string).map((itm: string, idx: number) => {
                    return <p key={idx}><a href={itm} target="blank">{itm}</a></p>
                  })
                }
              </>
            ) : null
          }



          <div>


          </div>
        </Modal>
      </>

      <Table
        dataSource={providers}
        pagination={false}
        columns={columns}
      />
      {/* <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        defaultCurrent={1}
        total={20}
        style={{ marginTop: "1rem" }}
      /> */}
    </div >
  );
}

export default ProvidersTable