import { Card, Col, Row } from 'antd';

import { UserDetail as DataType } from '../../../../interfaces/adminInterfaces';
import LoadingCard from '../../LoadingCard';
import styles from '../UserDetailCard.module.css';

interface UserDetailPage {
  detailedUser?: DataType;
  loading: boolean;
}

const UserDetailedAddressCard: React.FC<UserDetailPage> = ({
  detailedUser,
  loading,
}) => {
  let content: JSX.Element | any;

  if (detailedUser?.billingAddresses?.length! > 1) {
    let defaultAddress = detailedUser?.billingAddresses?.filter(
      (address) => address.isDefault === true
    );
    // eslint-disable-next-line array-callback-return
    content = defaultAddress?.map((address, idx) => {
      <div key={idx} className={styles.wrapper}>
        <Row className={styles.detailRows}>
          <span>TITLE</span>
          {address?.title}
        </Row>
        <Row className={styles.detailRows}>
          <span>NAME</span>
          {`${address?.firstName} ${address?.lastName}`}
        </Row>
        <Row className={styles.detailRows}>
          <span>NUMBER</span>
          {address?.gsmNumber}
        </Row>
        <Row className={styles.detailRows}>
          <span>IDENTITY</span>
          {address?.identityNumber}
        </Row>
        <Row className={styles.detailRows}>
          <span>ADDRESS</span>
          {address?.addressLine1 !== null &&
            address?.addressLine1 !== undefined &&
            address?.addressLine1 !== "" &&
            address?.addressLine1}
          {' '}
          {address?.addressLine2 !== null &&
            address?.addressLine2 !== undefined &&
            address?.addressLine2 !== "" &&
            address?.addressLine2}
          {/* {`${address?.addressLine1} ${address?.addressLine2}`} */}
        </Row>

        <Row className={styles.detailRows}>
          <span>CITY / COUNTRY</span>
          {`${address?.city} / ${address?.country}`}
        </Row>
        <Row className={styles.detailRows}>
          <span>ZIP CODE</span>
          {address?.zipCode}
        </Row>
        <Row className={styles.detailRows}>
          <span>CORPORATE</span>
          {address?.isCorporate ? "YES" : "NO"}
        </Row>
        <Row className={styles.detailRows}>
          <span>VAT NUMBER</span>
          {address?.vatNumber}
        </Row>
        <Row className={styles.detailRows}>
          <span>COMPANY</span>
          {address?.companyName}
        </Row>
        <Row className={styles.detailRows}>
          <span>TAX OFFICE</span>
          {address?.taxOffice}
        </Row>
      </div>;
    });
  } else if (detailedUser?.billingAddresses?.length! === 1) {
    content = (
      <div className={styles.wrapper}>
        {" "}
        <Row className={styles.detailRows}>
          <span>TITLE</span>
          {detailedUser?.billingAddresses[0]?.title}
        </Row>
        <Row className={styles.detailRows}>
          <span>NAME</span>
          {`${detailedUser?.billingAddresses[0].firstName} ${detailedUser?.billingAddresses[0]?.lastName}`}
        </Row>
        <Row className={styles.detailRows}>
          <span>NUMBER</span>
          {detailedUser?.billingAddresses[0]?.gsmNumber}
        </Row>
        <Row className={styles.detailRows}>
          <span>IDENTITY</span>
          {detailedUser?.billingAddresses[0]?.identityNumber}
        </Row>
        <Row className={styles.detailRows}>
          <span>ADDRESS</span>
          {detailedUser?.billingAddresses[0]?.addressLine1 !== null &&
            detailedUser?.billingAddresses[0]?.addressLine1 !== undefined &&
            detailedUser?.billingAddresses[0]?.addressLine1 !== "" &&
            detailedUser?.billingAddresses[0]?.addressLine1}
          {' '}
          {detailedUser?.billingAddresses[0]?.addressLine2 !== null &&
            detailedUser?.billingAddresses[0]?.addressLine2 !== undefined &&
            detailedUser?.billingAddresses[0]?.addressLine2 !== "" &&
            detailedUser?.billingAddresses[0]?.addressLine2}
          {/* {`${detailedUser?.billingAddresses[0]?.addressLine1} ${detailedUser?.billingAddresses[0]?.addressLine2}`} */}
        </Row>
        <Row className={styles.detailRows}>
          <span>CITY / COUNTRY</span>
          {`${detailedUser?.billingAddresses[0]?.city} / ${detailedUser?.billingAddresses[0]?.country}`}
        </Row>
        <Row className={styles.detailRows}>
          <span>ZIP CODE</span>
          {detailedUser?.billingAddresses[0]?.zipCode}
        </Row>
        <Row className={styles.detailRows}>
          <span>CORPORATE</span>
          {detailedUser?.billingAddresses[0]?.isCorporate ? "YES" : "NO"}{" "}
        </Row>
        <Row className={styles.detailRows}>
          <span>VAT NUMBER</span>
          {detailedUser?.billingAddresses[0]?.vatNumber}
        </Row>
        <Row className={styles.detailRows}>
          <span>COMPANY</span>
          {detailedUser?.billingAddresses[0]?.companyName}
        </Row>
        <Row className={styles.detailRows}>
          <span>TAX OFFICE</span>
          {detailedUser?.billingAddresses[0]?.taxOffice}
        </Row>
      </div>
    );
  }
  return (
    <Col lg={11} md={24} sm={24} xs={24}>
      {loading ? (
        <LoadingCard loading={loading} />
      ) : (
        <Card
          style={{
            width: "100%",
            border: "1px",
            borderRadius: "10px",
            boxShadow: "0px 4px 32px 1px #00000029",
          }}
        >
          <Row className={styles.detailRows}>FATURA BİLGİLERİ</Row>
          {content}
        </Card>
      )}
    </Col>
  );
};

export default UserDetailedAddressCard;
