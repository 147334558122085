import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { Button, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'

interface Props {
    selected: number
    setShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>
    country: string
}
interface ICategory {
    id: number,
    categoryName: string,
    country: string,
    amazonCategoryId: number
}
const countries = [
    { label: "USA", value: "usa" },
    { label: "Canada", value: "canada" },
    { label: "France", value: "france" },
    { label: "Britain", value: "britain" },
    { label: "Spain", value: "spain" },
    { label: "Italy", value: "italy" },
    { label: "Australia", value: "autralia" },
    { label: "Mexico", value: "mexico" },

]
const UpdateBSRCategoryForm: React.FC<Props> = ({ selected, setShowUpdateModal, country }) => {

    const [form] = Form.useForm();
    const [category, setCategory] = useState<ICategory>();

    const handleSubmit = async (values: any) => {
        // console.log(values)
        try {
            await adminService.updateAmazonCategory(selected, values)
            await adminService.getAmazonCategories(country);
            setShowUpdateModal(false)
        } catch (error) {
            alertNotification("error", "Bir hata oluştu!")
        }
    }

    const getCategory = async () => {
        try {
            const res = await adminService.getAmazonCategoryById(selected)
            setCategory(res.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        getCategory()
    }, [selected])

    return (
        <div style={{ width: "100%" }}>

            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18, offset: 1 }} onFinish={handleSubmit} initialValues={{ country: category?.country, categoryName: category?.categoryName }}>

                <Form.Item label="Ülke" name="country">
                    <Select
                        options={countries} placeholder="Ülke seçiniz" />
                </Form.Item>
                <Form.Item label="Kategori" name="categoryName">
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 20 }}>
                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

            </Form>
        </div>
    )
}

export default UpdateBSRCategoryForm