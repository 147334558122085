import React from "react";
import { Button, Modal, Space, Table } from "antd";
import type { TableProps } from "antd";
import { API_ADMIN_URL } from "../../config";
import UpdateProductForm from "./UpdateProductForm";
import CreateVariantProductForm from "./CreateVariationForm";
import ProductVariantTable from "./ProductVariantTable";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

interface Props {
  data: any[];
  getProducts: () => Promise<void>
}

const ProductTable: React.FC<Props> = ({ data,getProducts }) => {
  const [openVarCreateModal, setOpenVarCreateModal] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [openVariants, setOpenVariants] = React.useState(false);

  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Resim",
      dataIndex: "images",
      key: "images",
      render: (images) => (
        <img
          src={`${API_ADMIN_URL}/${images[0]}`}
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Başlık",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Supplier Id",
      dataIndex: "supplierId",
      key: "supplierId",
    },
    {
      title: "Kategori Id",
      dataIndex: "supplierCategoryId",
      key: "supplierCategoryId",
    },
    {
      title: "Attributes",
      key: "attributes",
      dataIndex: "attributes",
    },

    {
      title: "Düzenle",
      key: "edit",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setSelectedProduct(record);
              setOpenUpdateModal(true);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
    {
      title: "Varyasyon Ekle",
      key: "edit",
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            onClick={() => {
              setSelectedProduct(record);
              setOpenVarCreateModal(true);
            }}
          >
            Varyasyon Ekle
          </Button>
        </Space>
      ),
    },
    {
      title: "Varyasyon Ekle",
      key: "edit",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            danger
            onClick={() => {
              console.log("product sil!");
            }}
          >
            Sil
          </Button>
        </Space>
      ),
    },
    {
      title: "Varyasyonlar",
      key: "variation",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            style={{ backgroundColor: "green" }}

            onClick={() => {
              setSelectedProduct(record)
              setOpenVariants(true)
            }}
          >
            Varyasyonlar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={data} pagination={false} />

      <Modal
        width="70%"
        title="Ürün Güncelle"
        footer={null}
        open={openUpdateModal}
        onOk={() => {
          setSelectedProduct(null);
          setOpenUpdateModal(false);
        }}
        onCancel={() => {
          setSelectedProduct(null);
          setOpenUpdateModal(false);
        }}
      >
        <UpdateProductForm product={selectedProduct}  getProducts={getProducts} setShowModal={setOpenUpdateModal}/>
      </Modal>
      <Modal
        width="70%"
        title="Varyasyon Oluştur"
        footer={null}
        open={openVarCreateModal}
        onOk={() => {
          setSelectedProduct(null);
          setOpenVarCreateModal(false);
        }}
        onCancel={() => {
          setSelectedProduct(null);
          setOpenVarCreateModal(false);
        }}
      >
           <CreateVariantProductForm getProducts={getProducts} selectedProduct={selectedProduct} setOpenModal={setOpenVarCreateModal}/>

      </Modal>
      {
        selectedProduct && (
          <Modal
          width="80%"
          title="Varyasyonlar"
          footer={null}
          open={openVariants}
          onOk={() => {
            setSelectedProduct(null);
            setOpenVariants(false);
          }}
          onCancel={() => {
            setSelectedProduct(null);
            setOpenVariants(false);
          }}
         >
           <ProductVariantTable data={selectedProduct?.supplier_product_variants} getProducts={getProducts}/>
         </Modal>
        )
      }
     
      
    </>
  );
};

export default ProductTable;
