import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Select } from "antd";
import adminService from "../../services/adminService";
import alertNotification from "../../utils/alertNotification";
import TextArea from "antd/es/input/TextArea";

interface Props {
  getProducts: () => Promise<void>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateProductForm: React.FC<Props> = ({ getProducts, setOpenModal }) => {
  const [form] = Form.useForm();
  const [cats, setCats] = React.useState<any[]>([]);
  const [suppliers, setSuppliers] = React.useState<any[]>([]);

  const handleSubmit = async (values: any) => {
    values.images = values.images && values.images.includes(',') ? values.images.split(",") : [values.images];
    values.attributes = values.attributes && values.attributes.includes(',') ?  values.attributes.split(",") : [values.attributes];
    try {
      await adminService.createProduct(values);
      setOpenModal(false);
      getProducts();
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    } finally {
      form.resetFields();
    }
  };

  const getCats = async () => {
    try {
      const res = await adminService.getMarketplaceProductCategories();
      setCats(res.data);
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }
  };

  const getSuppliers = async () => {
    try {
      const res = await adminService.getSuppliers();
      setSuppliers(res.data);
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }
  };


  useEffect(() => {
    getCats();
    getSuppliers();
  }, []);

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18, offset: 1 }}
        onFinish={handleSubmit}
      >
        <Form.Item name={"title"} label="Başlık">
          <Input />
        </Form.Item>
        <Form.Item label="Resimler:" name={"images"}>
          <Input placeholder="Lütfen resim yollarının arasına virgül koyun" />
        </Form.Item>
        <Form.Item label="Attributes:" name={"attributes"}>
          <Input placeholder="Lütfen attribute'lerin arasına virgül koyun" />
        </Form.Item>
        <Form.Item label="Tarif No:" name={"tarifNumber"}>
          <Input />
        </Form.Item>
        <Form.Item label="Kategori" name="supplierCategoryId">
          <Select>
            {cats?.map((cat) => (
              <Select.Option value={cat.id}>{cat.category}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Tedarikçi" name="supplierId">
          <Select>
            {suppliers?.map((sup) => (
              <Select.Option value={sup.id}>{sup.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Tasarım Var?" name={"needsDesign"}>
          <Checkbox />
        </Form.Item>
        <Form.Item label="Açıklama" name="description">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Onayla
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateProductForm;
