import { useParams } from 'react-router-dom';
import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { Button, DatePicker, Form, Input } from 'antd'
import React from 'react'
interface Props {
    id: number
    selected: number
    setShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>
    getCategoryById: () => Promise<void>
}
const UpdateBSRRankForm: React.FC<Props> = ({ id, selected, setShowUpdateModal, getCategoryById }) => {
    const [form] = Form.useForm();
    const params = useParams();
    const handleSubmit = async (values: any) => {
        try {
            // console.log(selected, values)
            await adminService.updateAmazonCategoryRank(selected, values)
            getCategoryById()
            alertNotification("success", "Güncelleme başarılı!")
            form.resetFields();
            setShowUpdateModal(false)

        } catch (error) {
            alertNotification("error", "Bir hata oluştu!")
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <h3 style={{ textAlign: "center" }}>Bu Kategoriye Ait BSR Ranki Güncelle</h3>
            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18, offset: 1 }} onFinish={handleSubmit}>


                <Form.Item label="Satış Miktarı" name="newSale">
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 18 }}>
                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

            </Form>
        </div>
    )
}

export default UpdateBSRRankForm