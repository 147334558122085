import React from 'react'
import type { ColumnsType } from 'antd/es/table';
import { Table, Pagination, Space, Button } from 'antd';
import type { PaginationProps } from "antd";
import { Question } from '@src/interfaces/adminInterfaces';
import { Link } from 'react-router-dom';
import adminService from '../../../services/adminService';
import alertNotification from '../../../utils/alertNotification';


interface TableProps {
  datas: Question[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
  getQuestions: () => void
}


const FaqTable: React.FC<TableProps> = ({ setCurrent, current, totalPageCount, datas, getQuestions }) => {
  // console.log({totalPageCount})

  const deleteFaq = async (id: number) => {

    try {

      await adminService.deleteFaq(id)
      getQuestions()


    } catch (error) {
      alertNotification("error", "Bir hata oluştu!")
    }

  }
  const columns: ColumnsType<Question> = [

    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
    },
    {
      title: "Category Id",
      dataIndex: "faqCategoryId",
      key: "faqCategoryId",
    },
    {
      title: "Update",
      render: (_, record) => (
        <Space size="middle">
          {<Link to={`/questions/${record.id}`}>Edit</Link>}
        </Space>
      ),
      key: "UpdateDelete",
    },

    {
      title: "Delete",
      render: (_, record) => (
        <Space size="middle">
          {<Button style={{ backgroundColor: "red", color: "white" }} onClick={() => deleteFaq(record.id)}>Delete</Button>}
        </Space>
      ),
      key: "delete",
    },

  ];
  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };
  return (
    <div>
      <Table dataSource={datas} pagination={false} columns={columns} />

      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        defaultCurrent={1}
        total={20}
        style={{ marginTop: "1rem" }}
      />
    </div>
  )
}

export default FaqTable