import { Button } from "antd"
import styles from './PaymentStatusFilter.module.css'

interface PaymentStatusFilterProps {
    getInvoices: (searchTerm: string, paymentStatus: string) => void
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>
    setPaymentStatus: React.Dispatch<React.SetStateAction<string>>
    setCurrent: React.Dispatch<React.SetStateAction<number>>
}

const PaymentStatusFilter: React.FC<PaymentStatusFilterProps> = ({
    getInvoices,
    setCurrent,
    setSearchTerm,
    setPaymentStatus
}) => {

    return (
        <>
            <Button
                onClick={() => {
                    setCurrent(1)
                    setSearchTerm('')
                    setPaymentStatus('UNPAID')
                    getInvoices('', 'UNPAID')
                }}
                danger
                ghost
                className={styles.unpaidInvoices}
            >
                UNPAID
            </Button>
            <Button
                onClick={() => {
                    setCurrent(1)
                    setSearchTerm('')
                    setPaymentStatus('')
                    getInvoices('', '')
                }}
                type='primary'
                className={styles.allInvoices}
                ghost
            >
                ALL
            </Button>
        </>
    )
}

export default PaymentStatusFilter