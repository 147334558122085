
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { useParams } from 'react-router-dom';
import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';
import { Question, FaqCategory } from '@src/interfaces/adminInterfaces';

const { Option } = Select
const EditQuestion = () => {

  const [question, setQuestion] = useState<Question>()
  const [categories, setCategories] = useState<FaqCategory[]>([{ id: 1, name: "hello" }]);
  const [form] = Form.useForm();
  const { id } = useParams();

  //get categories
  const getFaqCategories = async () => {
    try {
      const res = await adminService.getFaqCategories();
      setCategories(res.data)

    } catch (error) {
      alertNotification("error", "Bir hata oluştu")
    }
  }


  //submit
  const handleSubmit = async (values: Question) => {
    try {

      adminService.editQuestion(values.id, { question: values.question, answer: values.answer })
      alertNotification("success", "Başarı ile güncellendi");

    } catch (error) {
      alertNotification("error", "Bir hata oluştu!")
    }
  }
  //get question by Id  
  const getQuestion = async () => {
    try {

      const res = await adminService.getQuestionById(id!)
      // console.log(res.data)
      //   let temp = {answer:res.data.answer,question: res.data.question,categoryId:res.data.faqCategoryId,id:question.id}
      setQuestion(res.data)
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!")
    }
  }
  useEffect(() => {

    getQuestion();
    getFaqCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ marginBottom: '20px' }}>Sıkça Sorulan Soruları Ekle</h2>
      {
        question?.id &&
        <Form style={{ width: "80%" }} onFinish={handleSubmit} form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{ question: question.question, answer: question.answer, categoryId: question.faqCategoryId, id: question.id }}>

          <Form.Item label="Soru" name="question" >
            <Input />
          </Form.Item>
          <Form.Item label="id" name="id" style={{ display: "none" }} >
            <Input style={{ display: "none" }} />
          </Form.Item>

          <Form.Item label="Cevap" name="answer">
            <Input.TextArea rows={10} />
          </Form.Item>

          <Form.Item label="Kategori" name="categoryId">
            <Select placeholder="Kategori Seçiniz">
              {categories?.map((category) => {
                return <Option value={category.id} key={category.id}>{category.name}</Option>
              })}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 20, span: 4 }} >
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    </div>

  )
}

export default EditQuestion