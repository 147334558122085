import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import adminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import UserDetailCard from "../components/cards/userDetailCard/UserDetailCard";
import { UserDetail as DataType, IWallet } from "../interfaces/adminInterfaces";
import DetailedUserAddressesTable from "../components/tables/detailedUserTables/DetailedUserAddressesTable";
import DetailedUserTransactionsTable from "../components/tables/detailedUserTables/DetailedUserTransactionsTable";
import DetailedUserInvoicesTable from "../components/tables/detailedUserTables/DetailedUserInvoicesTable";
import DetailedUserPackagesTable from "../components/tables/detailedUserTables/DetailedUserPackagesTable";
import DetailedUserCoursesTable from "../components/tables/detailedUserTables/DetailedUserCoursesTable";
import UserWalletCard from "../components/cards/userDetailWalletCard/UserWalletCard";
import { Col, Row } from "antd";
import WalletTransactionsTable from "../components/tables/pageTables/WalletTransactionsTable";
import WalletLogsTable from "../components/tables/pageTables/WalletLogsTable";

const dummyData: IWallet[] = [
  {
    walletId: 1,
    fullName: "Murtaza Çalışkan",
    email: "murtaza@test.com",
    amount: 100,
    date: "11-12-2024",
    note: "Note yazmış seller",
    adminNote: null,
    status: "success",
  },
  {
    walletId: 1,
    fullName: "Kamuran Balcı",
    email: "kamuran@test.com",
    amount: 500,
    date: new Date(),
    note: "Note yazmış kamuran",
    adminNote: "Note yazmış admin",
    status: "pending",
  },
  {
    walletId: 1,
    fullName: "Murtaza Çalışkan",
    email: "murtaza@test.com",
    amount: 100,
    date: new Date(),
    note: "Note yazmış seller",
    adminNote: "",
    status: "rejected",
  },
];
const UserDetail: React.FC = () => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [detailedUser, setDetailedUser] = useState<DataType>();
  const [current, setCurrent] = useState(1);

  const params = useParams();

  const getUserDetail = (id?: string) => {
    setLoading(true);
    adminServices
      .getUserDetails(id)
      .then((res) => {
        setDetailedUser(res.data);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancelSubscription = (id: number) => {
    setLoading(true);
    adminServices
      .cancelSubscription(id)
      .then((res) => {
        alertNotification("success", "Subscription CANCELED successfully");
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEndDateOfRefundedSubscriptions = (packagePLanId: number) => {
    setLoading(true);
    adminServices
      .updateEndDateOfRefundedSubscription(packagePLanId)
      .then((res) => {
        getUserDetail(params.id);
        alertNotification(
          "success",
          "Subscription END DATE updated successfully"
        );
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserDetail(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UserDetailCard
        getUserDetail={getUserDetail}
        detailedUser={detailedUser}
        loading={loading}
      />
      <DetailedUserAddressesTable
        loading={loading}
        datas={detailedUser?.billingAddresses!}
      />
      {/* <DetailedUserTransactionsTable
        loading={loading}
        datas={detailedUser?.transactions!}
      /> */}
      <DetailedUserInvoicesTable
        loading={loading}
        datas={detailedUser?.invoices!}
      />
      <DetailedUserPackagesTable
        loading={loading}
        datas={detailedUser?.userPackages!}
        cancelSubscription={cancelSubscription}
        updateEndDateOfRefundedSubscriptions={
          updateEndDateOfRefundedSubscriptions
        }
      />
      <DetailedUserCoursesTable
        loading={loading}
        datas={detailedUser?.userCourses!}
      />
      <Row
        justify="space-between"
        gutter={[16, 16]}
        style={{ marginBottom: "18px" }}
      >
        {/* {detailedUser?.wallet && ( */}
        <Col span={6}>
          <UserWalletCard
            balance={
              detailedUser?.wallet && Number(detailedUser?.wallet.balance!)
                ? Number(Number(detailedUser?.wallet.balance!).toFixed(2))
                : 0
            }
            userId={detailedUser?.id!}
            getUserDetail={getUserDetail}
          />
        </Col>

        {detailedUser?.walletDepositRequests && (
          <Col span={18}>
            <p>Deposit Requests</p>
            <WalletTransactionsTable
              datas={detailedUser?.walletDepositRequests!}
              loading={loading}
              current={current}
              setCurrent={setCurrent}
              totalPageCount={10}
            />
          </Col>
        )}
      </Row>
      {detailedUser?.wallet && detailedUser.wallet.wallet_logs && (
        <WalletLogsTable data={detailedUser?.wallet.wallet_logs!} />
      )}
    </>
  );
};

export default UserDetail;
