import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AuthContextInterface,
  Iuser,
  LoginDataInterface,
} from "../interfaces/authInterfaces";
import AuthServices from "../services/authService";
import alertNotification from "../utils/alertNotification";

const contextDefaultValues: AuthContextInterface = {};

const AuthContext = createContext<AuthContextInterface>(contextDefaultValues);

export const AuthProvider: React.FC<AuthContextInterface> = (props) => {
  const [user, setUser] = useState<Iuser>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const setUserContext = (redirectPage: any) => {
    AuthServices.checkAuth()
      .then((res) => {
        setUser(res.data);
        navigate(redirectPage ? redirectPage : "/");
      })
      .catch((err) => {
        setError(true);
        alertNotification("error", err.response.data.message);
        navigate("/login");
      });
  };

  // login user
  const login = (userData: LoginDataInterface, redirectPage: any) => {
    setIsLoading(true);
    AuthServices.login(userData)
      .then((res) => {
        setUserContext(redirectPage);
        alertNotification("success", "Logged in successfully");
      })
      .catch((err) => {
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //check if user has auth
  const checkAuthStatus = () => {
    setIsLoading(true);
    AuthServices.checkAuth()
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        // setError(true)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // logout user
  const logout = () => {
    AuthServices.logoutUser()
      .then((res) => {
        setUser({});
        alertNotification("success", "Logged out successfully");
        navigate("/login");
      })
      .catch((err) => {
        setError(true);
        alertNotification("error", err.response.data.message);
      });
  };

  useEffect(() => {
    checkAuthStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        isLoading,
        login,
        logout,
        checkAuthStatus,
        setUserContext,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
