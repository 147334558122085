import React, { useEffect, useState } from "react";
import FaqTable from "../components/tables/pageTables/FaqTable";
import { Form, Input, Button, Row } from "antd";
import { Question } from "../interfaces/adminInterfaces";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import styles from "../components/filter/Filter.module.css";
import ProducerTable from "../components/tables/pageTables/ProducerTable";

const Producers = () => {
  const [current, setCurrent] = useState(1);
  const [questions, setQuestions] = useState<Question[]>([
    {
      id: 1,
      question: "Sordum soruyu!",
      answer: "Anladın sen!",
      faqCategoryId: 1,
    },
  ]);
  const [pageCount, setPageCount] = useState<number | any>();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);

  const [platform, setPlatform] = useState("trendyol");
  const [producers, setProducers] = useState<any>([]);

  const getProducers = async (currentPlatform:string) => {
    try {
      const res = await adminService.getProducers(current, keyword,currentPlatform);
      console.log(res.data);
      let producerArr:any[]=[];
      res.data.data.map((item:any)=>{
        let tempObj={
          email:item.user.email,
          gsm:item.user.gsmNumber,
          producerName:(platform==="trendyol")?item.producerName:"aliexpress",
          producerGsm:(platform==="trendyol")?item.phoneNumber:""
        }
        producerArr.push(tempObj);
      })
      setProducers(producerArr);
      // setQuestions(res.data.faq);
       setPageCount(res.data.totalPageCount);
       console.log("producerArr",producerArr)
    } catch (error) {
      console.log(error)
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const onFinish = (values: { keyword: string }) => {
    setKeyword(values.keyword);
  };

  useEffect(() => {
    getProducers(platform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, keyword]);
  return (
    <div style={{ width: "100%" }}>
      <Form
        className={styles.filter}
        onFinish={onFinish}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <div className={styles.inputWrapper}>
            <Form.Item style={{ flex: "1" }} name="keyword">
              <Input
                className={styles.filterInput}
                placeholder={"Aramak istediğiniz anahtar kelimeyi giriniz"}
              />
            </Form.Item>
            <Button
              className={styles.submitButton}
              type="primary"
              danger
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
    
      <Button
        style={{  
          marginBottom: "20px",
          backgroundColor:platform==="trendyol"?"skyblue":"white",
        }}    
        onClick={() => {
          setPlatform("trendyol");
          getProducers("trendyol");
        }
        }
      >
        Trendyol
      </Button>
      <Button
        style={{
          marginBottom: "20px",
          backgroundColor:platform==="aliexpress"?"skyblue":"white",
        }}
        onClick={() => {
          setPlatform("aliexpress");
          getProducers("aliexpress");
        }
        }
      >
        Aliexpress
      </Button>


      <ProducerTable
        setCurrent={setCurrent}
        current={current}
        datas={producers}
        loading={loading}
        totalPageCount={pageCount}
      />
    </div>
  );
};

export default Producers;
