import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Row, Col } from 'antd';
import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';
import { FaqCategory, Question } from '@src/interfaces/adminInterfaces';

const {Option} = Select;
const CreateQuestion = () => {
    const [form] = Form.useForm();
    const [formalt] = Form.useForm();
  const [categories,setCategories] = useState<FaqCategory[]>([{id:1,name:"hello"}]);

    const handleQuestionSubmit = async (values:Question)=>{
        try {
            //create işlemi yap 
           await adminService.createFaq(values)

            form.resetFields();
            alertNotification("success","Kayıt başarılı!")

        } catch (error) {
            alertNotification("error","Bir hata oluştu!")
        }
    }

    const getFaqCategories = async ()=>{
      try {
        const res = await adminService.getFaqCategories();
        setCategories(res.data)
        
      } catch (error) {
        alertNotification("error","Bir hata oluştu")
      }
    }

    const handleCatSubmit = async (values:FaqCategory)=>{
      try {
        await adminService.createFaqCategory(values)
        formalt.resetFields();
        getFaqCategories()
      } catch (error) {
        alertNotification("error","Bir hata oluştu")
      }

    }
    useEffect(()=>{
      getFaqCategories()
    },[])
  return (
    <>
    <h1 style={{textAlign:"center",marginBottom:"30px"}}>Sıkça Sorulan Soruları Ekle</h1>
    <Row justify='space-between' >
        <Col span={12}>
    <h1 style={{textAlign:"center"}}>Yeni bir soru eklemek için formu doldurunuz</h1>
          <Form  style={{width:"100%"}} onFinish={handleQuestionSubmit} form={form} labelCol={{span:4}} wrapperCol={{span:20}}>
      
            <Form.Item label="Soru" name="question">
              <Input/>
            </Form.Item>

            <Form.Item label="Cevap" name="answer">
              <Input.TextArea rows={10}/>
            </Form.Item>
            <Form.Item
          label="Kategori"
          name="categoryId"
          
        >
          <Select placeholder="Kategori Seçiniz">
            {categories?.map((category)=>{
              return <Option value={category.id} key={category.id}>{category.name}</Option>
            })}
          </Select>
        </Form.Item>
      
            <Form.Item wrapperCol={{offset:20,span:4}} >
              <Button style={{width:"100%"}} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>

          </Form>
        </Col>
        <Col span={12}>
          <h1 style={{textAlign:"center"}}>Yeni bir kategori eklemek için formu doldurunuz</h1>
        <Form  style={{width:"100%"}} onFinish={handleCatSubmit} form={formalt} labelCol={{span:4}} wrapperCol={{span:20}}>

            <Form.Item label="Kategori" name="catName">
              <Input/>
            </Form.Item>
      
            <Form.Item wrapperCol={{offset:20,span:4}} >
              <Button style={{width:"100%"}} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            </Form>
        </Col>
    </Row>
    </>
  )
}

export default CreateQuestion