import { Card, Skeleton, Table, Tag } from "antd";

import { BillingAddress } from "../../../interfaces/adminInterfaces";

import styles from "../pageTables/Tables.module.css";

interface TableProps {
  datas: BillingAddress[];
  loading: boolean;
}

const DetailedUserAddressesTable: React.FC<TableProps> = ({
  datas,
  loading,
}) => {
  const data: BillingAddress[] = datas;
  const addressColumns = [
    {
      title: "TITLE",
      dataIndex: ["title"],
      key: "title",
      width: "9%",
    },
    {
      title: "ADDRESS",
      render: (record: any) => {
        return `${record?.addressLine1} ${record?.addressLine1}`;
      },
      key: "addressLine",
      width: "39%",
    },
    {
      title: "CITY",
      dataIndex: ["city"],
      key: "city",
      width: "6%",
    },
    {
      title: "COUNTRY",
      dataIndex: ["country"],
      key: "country",
      width: "8%",
    },
    {
      title: "ZIP",
      dataIndex: ["zipCode"],
      key: "zipCode",
      width: "5%",
    },
    {
      title: "NUMBER",
      dataIndex: ["gsmNumber"],
      key: "gsmNumber",
      width: "9%",
    },
    {
      title: "IDENTITY",
      dataIndex: ["identityNumber"],
      key: "identityNumber",
      width: "8%",
    },
    {
      title: "VAT",
      dataIndex: ["vatNumber"],
      key: "vatNumber",
      width: "8%",
    },

    {
      title: "CORPORATE",
      render: (record: any) => {
        return record?.isCorporate === true ? "YES" : "NO";
      },
      key: "isCorporate",
      width: "8%",
    },
  ];
  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Card style={{ marginTop: "1.5rem" }}>
        {" "}
        <Tag color={"red"}> ADDRESSES</Tag>
      </Card>
      <Table
        className={styles.table}
        columns={addressColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default DetailedUserAddressesTable;
