import { useForm } from 'antd/lib/form/Form';
import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from "./BirFaturaModal.module.css"
import { citiesTurkey, vatCities } from './cityData';

export interface ModalProps {
    isModalOpen: boolean;
    handleOk: any;
    handleCancel: any
}
export interface PaymentMethod {
    Id: number;
    Value: string
}
export interface Package {
    id: number;
    duration: number;
    price: string;
    currency: string;
    stock_code: string;
    baseprice: string;
    packageid: number;
    packagename: string
}
export interface BillingInfo {
    billingName: string,
    billingAddress: string,
    billingCity: string,
    billingMobilePhone: string,
    isCorporate: boolean,
    taxOffice: string | null,
    taxNo: null | string,
    identityNumber: string
}
export interface PaymentOptions {
    label: string;
    value: number
}

const BirFaturaModal: React.FC<ModalProps> = ({ isModalOpen, handleOk, handleCancel }) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
    const [paymentOptions, setPaymentOptions] = useState<PaymentOptions[]>();
    const [email, setEmail] = useState("");
    const [billingInfo, setBillingInfo] = useState<BillingInfo>();
    const [packages, setPackages] = useState<Package[]>();
    const [packageOptions, setpackageOptions] = useState()

    const [form] = Form.useForm();

    const getPaymentMethods = async () => {
        try {

            const res = await adminService.getPaymentMethhods();

            const options = res.PaymentMethods.map((item: { Value: any; Id: any; }) => {
                return { label: item.Value, value: item.Id }
            })
            setPaymentMethods(res.PaymentMethods)
            setPaymentOptions(options)

        } catch (error) {
            alertNotification("error", "Ödeme Yöntemleri Getirilemedi")
        }
    }
    const getBillingInfo = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        try {
            const res = await adminService.getUsersBillingInfo(email);
            if (res.data) {

                setBillingInfo(res.data)
            } else {
                alertNotification("error", "Kullanıcıya ait fatura bilgisi bulunmamaktadır")
            }

        } catch (error) {
            alertNotification("error", "Bilgiler getirilemedi")
        }
    }
    const getPackages = async () => {
        try {
            const res = await adminService.getBirFaturaPackages();

            let temp = res.data.map((item: {
                baseprice: any; packagename: any; id: any;
            }) => {
                return { label: `${item.packagename} (${item.baseprice})`, value: item.id }
            })

            setpackageOptions(temp)
            setPackages(res.data)
        } catch (error) {
            alertNotification("error", "Paketler getirilemedi")
        }
    }

    const handleSubmit = async (values: any) => {

        let paymentInfo
        let packageInfo
        if (paymentMethods) {

            paymentInfo = paymentOptions?.find((item: { value: number }) => {
                return item.value === values.paymentTypeId
            })

        }
        if (packages) {
            packageInfo = packages.find((item: { id: number }) => {
                return item.id === values.packagePlanId
            })
        }
        try {
            let temp = {
                email: email,
                billingDate: values.billingDate,
                billingInfo: {
                    billingName: billingInfo ? billingInfo?.billingName : values.isCorporate ? values.companyName : `${values.firstName} ${values.lastName}`,
                    billingAddress: billingInfo ? billingInfo?.billingAddress : values.address,
                    billingCity: billingInfo ? billingInfo?.billingCity : values.city,
                    billingMobilePhone: billingInfo ? billingInfo?.billingMobilePhone : values.phone,
                    isCorporate: billingInfo ? billingInfo?.isCorporate : values.isCorporate,
                    identityNumber: billingInfo ? billingInfo?.identityNumber : values.identityNumber,
                    taxOffice: billingInfo ? billingInfo.taxOffice : values.taxOffice,
                    taxNo: billingInfo ? billingInfo.taxNo : values.taxNumber
                },
                paymentTypeId: paymentInfo?.value,
                paymentMethod: paymentInfo?.label,
                basePrice: packageInfo?.baseprice,
                paidPrice: packageInfo?.price,
                stockCode: packageInfo?.stock_code,
                currency: packageInfo?.currency,
                packagePlanId: packageInfo?.packageid,
                duration: packageInfo?.duration,
                packageName: packageInfo?.packagename,
                source: values.source

            }
            // console.log(temp)
            await adminService.createBirFaturaInvoice(temp)

        } catch (error) {
            alertNotification("error",)
        }
    }

    useEffect(() => {
        getPaymentMethods()
        getPackages()
    }, [])

    return (
        <Modal width="70%" bodyStyle={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }} title="Fatura Oluştur/Düzelt" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <h3>Fatura bilgilerini email girişi yaparak getirebilirsiniz. Kişinin bilgileri gelmediyse email girdiğiniz şekilde kalmalı. Faturaya email bilgisi buradan kayıt edilecektir</h3>
            <form className={styles.emailForm} onSubmit={getBillingInfo} >
                <input placeholder='Email giriniz' className={styles.emailInput} type="text" name="email" id="email" onChange={(e) => { setEmail(e.target.value) }} />
                <button type='submit'>Fatura Bilgilerini Getir</button>
            </form>

            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18, offset: 1 }} onFinish={handleSubmit}>
                {!billingInfo &&
                    <>
                        <Form.Item label="Ad" name="firstName">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Soyad" name="lastName">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Adres" name="address">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Şehir" name="city">
                            <Select options={citiesTurkey} placeholder="Şehir seçiniz" showSearch />
                        </Form.Item>
                        <Form.Item label="Telefon" name="phone">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Kurumsal Fatura?" name="isCorporate" valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                        <Form.Item label="Kimlik No" name="identityNumber">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Şirket İsmi" name="companyName">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Vergi Dairesi" name="taxOffice">
                            <Select options={vatCities} showSearch placeholder="Vergi Dairesi Seçiniz.." filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            } />
                        </Form.Item>
                        <Form.Item label="Vergi Numarası" name="taxNo">
                            <Input />
                        </Form.Item>
                    </>
                }
                <Form.Item label="Tarih" name="billingDate">
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Ödeme Yöntemi" name="paymentTypeId">
                    <Select

                        options={paymentOptions}
                        defaultValue="Banka EFT-Havale"
                    />
                </Form.Item>
                <Form.Item name="packagePlanId" label="Paket">
                    <Select

                        options={packageOptions}

                    />
                </Form.Item>

                <Form.Item label="Kaynak" name="source">
                    <Select

                        options={[
                            { label: "Subs-Iyzico", value: "Subs-Iyzico" },
                            { label: "Service-Provider-Iyzico", value: "Service-Provider-Iyzico" },
                            { label: "Service-Provider-Manuel", value: "Service-Provider-Manuel" },
                        ]}

                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                        Oluştur
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default BirFaturaModal