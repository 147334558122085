import PackagesTable from "../components/tables/pageTables/PackagesTable";
import { useEffect, useState } from "react"
import AdminService from '../services/adminService'
import alertNotification from "../utils/alertNotification";
import { PackagePlans as DataType } from '../interfaces/adminInterfaces'

const Packages: React.FC = () => {
    const [plans, setPlans] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);

    const getPackagePlans = () => {
        setLoading(true)
        AdminService.getPackagePlans()
            .then((res) => {
                setPlans(res.data)
            })
            .catch((err) => alertNotification('error', err.response.data.message))
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        getPackagePlans()
    }, [])
    return <>
        <h2 style={{ color: 'red', textAlign: 'center' }}>1 Ocak 2023 tarihinden itibaren kullanılan bütün planlar, Rexven Topluluk paketine bağlıdır. Planlar, Rexven Topluluk paketinin yüzdelik indirimlerine karşılık gelen fiyatlardır. En güncel paketler, id'si 21 ve üstü olan paketlerdir. Eski paketler artık satılmamaktadır. Paketlere ek olarak Kurumsal Check Up product ı bulunmaktadır.</h2>
        <PackagesTable
            datas={plans}
            loading={loading}
        />
    </>
}
export default Packages