import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { Button, DatePicker, Form, Input } from 'antd'
import React from 'react'

const CreateBSRRankFomr = () => {

    const [form] = Form.useForm();

    const handleSubmit = async (values:any)=>{
        try {
            adminService.createRank(values)
        } catch (error) {
            alertNotification("error","Silme işlemi başarısız")
        }
    }
  return (
    <div style={{width:"50%"}}>
        <h3 style={{textAlign:"center"}}>Yeni BSR Rank Oluştur</h3>
         <Form form={form} labelCol={{ span: 6 }} wrapperCol={{span:18,offset:1}} onFinish={handleSubmit}>
            
            <Form.Item label="BSR" name="bsrRank">
                <Input/>
            </Form.Item> 
            <Form.Item label="Satış Miktarı" name="sales">
                <Input/>
            </Form.Item> 

            <Form.Item label="Kategori Id" name="amzCategoryId">
                <Input/>
            </Form.Item>
           
            <Form.Item wrapperCol={{offset:20}}>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit">Submit</Button>
            </Form.Item>

        </Form>
    </div>
  )
}

export default CreateBSRRankFomr