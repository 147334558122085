import React, { useEffect, useRef, useState } from 'react';
import { Form, Upload, Input, Button, SelectProps, Select, Checkbox, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';
import { Editor } from '@tinymce/tinymce-react';
import { IService, User } from '@src/interfaces/adminInterfaces';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import TextArea from 'antd/es/input/TextArea';


interface FormValues {
  name: string;
  description: string;
  image: File | null;
  userId: number | null;
  // services: any
  services: { serviceId: number | null, serviceFee: number | null, description: string | null }[]
  isPremium: boolean | null
}


const CreateProviders: React.FC = () => {
  let timeout: ReturnType<typeof setTimeout> | null;
  let currentValue: string;
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    description: '',
    image: null,
    userId: null,
    services: [],
    isPremium: null
  });

  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);


  const getServices = async (approval: 'requested' | 'declined' | 'accepted' | 'all', page: number) => {
    try {
      const servicesInfo = await adminService.getServices(page, approval)
      setServices(servicesInfo.data?.data)
    } catch (err: any) {
      alertNotification('error', err.message)
    }

  }


  const handleFormSubmit = () => {

    const formData = new FormData();
    formData.append('image', formValues.image as File);
    formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    formData.append('userId', String(formValues.userId))
    formData.append('services', JSON.stringify(formValues.services))
    formData.append('isPremium', String(formValues.isPremium))


    // console.log('Form Values:', formValues);

    adminService.createServiceProviders(formData)
      .then((res) => {
        alertNotification('success', 'Başarıyla Eklendi');
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        alertNotification('error', err.response.data.message);
      });
  };

  const fetch = (value: string, callback: Function) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    const fake = () => {
      adminService.getUsers(1, 50, value)
        .then((response) => {
          if (currentValue === value) {
            const { data } = response
            const result = data.map((item: User) => (
              {
                value: item.id,
                text: `${item.firstName} ${item.lastName} (${item.email})`
              }
            ))

            callback(result)
          }
        })
    };
    if (value) {
      timeout = setTimeout(fake, 300);
    } else {
      callback([]);
    }
  };

  const [data, setData] = useState<SelectProps['options']>([]);
  const [value, setValue] = useState<string>();

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
    setFormValues({ ...formValues, userId: Number(newValue) })
  };


  useEffect(() => {
    getServices('accepted', 1)
  }, [])

  return (
    <div style={{ display: 'flex', width: "100%", flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ marginBottom: '20px' }}>Hizmet Sağlayıcı Ekleme Sayfası</h2>


      <Form onFinish={handleFormSubmit} form={form} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
        <Form.Item label="User" name="user">
          <Select
            showSearch
            value={value}
            placeholder={'Email, Ad veya Soyad'}
            style={{
              width: '100%'
            }}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={(data || []).map((d) => ({
              value: d.value,
              label: d.text,
            }))}
          />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input
            value={formValues.name}
            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          />
        </Form.Item>

        <Form.Item label='Is Premium'>
          <Checkbox
            onChange={(e) => setFormValues({ ...formValues, isPremium: e.target.checked })}
          />
        </Form.Item>

        <Form.Item label='Category'>
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={(selectedIds: CheckboxValueType[]) => {
              // console.log({ selectedIds })
              setSelectedServices(selectedIds as number[]);
              const existingSelectedServices = formValues.services.filter(service => selectedIds.includes(service.serviceId as number));
              setFormValues({
                ...formValues,
                services: existingSelectedServices
              });
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {
                services.map((el: IService) => (
                  <div key={el.id} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Checkbox value={el.id}>{el.serviceName} :</Checkbox>
                    {
                      selectedServices.includes(el.id) &&
                      <>
                        <Input
                          placeholder="Hizmet bedeli "
                          style={{ margin: 8, width: '20%' }}
                          value={formValues.services.find(service => service.serviceId === el.id)?.serviceFee || ''}
                          onChange={(e) => {
                            const fee = e.target.value;
                            setFormValues(prevState => {
                              const updatedServices = [...prevState.services];
                              const index = updatedServices.findIndex(service => service.serviceId === el.id);
                              if (index !== -1) {
                                updatedServices[index].serviceFee = Number(fee);
                              } else {
                                updatedServices.push({ serviceId: el.id, serviceFee: Number(fee), description: '' });
                              }
                              return { ...prevState, services: updatedServices };
                            });
                          }}
                        />
                        <TextArea
                          placeholder="Açıklama "
                          style={{ margin: 8, width: '75%' }}
                          // value={formValues.services.find(service => service.serviceId === el.id)?.serviceFee || ''}
                          onChange={(e) => {
                            const desc = e.target.value;
                            setFormValues(prevState => {
                              const updatedServices = [...prevState.services];
                              const index = updatedServices.findIndex(service => service.serviceId === el.id);
                              if (index !== -1) {
                                updatedServices[index].description = desc;
                              } else {

                                updatedServices.push({ serviceId: el.id, serviceFee: null, description: desc });
                              }
                              return { ...prevState, services: updatedServices };
                            });
                          }}
                        />
                      </>
                    }
                  </div>
                ))
              }
            </div>

          </Checkbox.Group>
        </Form.Item>
        <Form.Item label='Description' >
          <TextArea
            value={formValues.description}
            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
            placeholder="Hizmet sağlayıcı açıklaması giriniz.."
            autoSize={{ minRows: 20 }}
          />

        </Form.Item>


        {/* <Editor
          value={formValues.description}
          onEditorChange={(newValue, editor) => {
            setFormValues({ ...formValues, description: newValue })

          }}

          initialValue="<p>Hizmet Sağlayıcı Açıklaması giriniz....</p>"

          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            image_advtab: true
          }}
        /> */}
        {/* </Form.Item> */}
        <Form.Item style={{ marginTop: '10px' }} label="Image" name="image">
          <Upload
            beforeUpload={(file) => {
              setFormValues({ ...formValues, image: file });
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
          <p style={{ marginBottom: '20px', color: 'red', fontStyle: 'italic' }}>
            ** Yüklediğiniz fotoğrafların dosya adlarını ilgili hizmet sağlayıcı ile aynı isimde oluşturmanızı rica ederiz...
          </p>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateProviders;
