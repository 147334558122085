import { ExclusiveProduct } from '../../../interfaces/adminInterfaces';
import alertNotification from '../../../utils/alertNotification';
import type { ColumnsType } from 'antd/es/table';
import { Pagination, PaginationProps, Table, Space, Button } from 'antd'
import React from 'react'
import adminService from '../../../services/adminService';
import { Link } from 'react-router-dom';

interface TableProps {
  datas: ExclusiveProduct[];

  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
  getExclusiveProducts: () => void
}

const ExclusiveProductTable: React.FC<TableProps> = ({ setCurrent, current, totalPageCount, datas, getExclusiveProducts }) => {


  const deleteExcProduct = async (id: number) => {

    try {

      await adminService.deleteExcProduct(id)
      getExclusiveProducts()


    } catch (error) {
      alertNotification("error", "Bir hata oluştu!")
    }

  }

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },

    {
      title: "Ürün Maliyeti",
      dataIndex: "productCost",
      key: "productCost",
    },
    {
      title: "Kargo Maliyeti",
      dataIndex: "shippingCost",
      key: "shippingCost",
    },
    {
      title: "Tedarik Süresi",
      dataIndex: "leadTime",
      key: "leadTime",
    },
    {
      title: "Max. Sipariş Miktarı",
      dataIndex: "maxOrderQuantity",
      key: "maxOrderQuantity",
    },

    {
      title: "Düzenle",
      render: (_, record) => (
        <Space size="middle">
          {<Link to={`/edit-exclusive-product/${record.id}`}>Düzenle</Link>}
        </Space>
      ),
      key: "edit",
    },
    {
      title: "Sil",
      render: (_, record) => (
        <Space size="middle">
          {<Button disabled={record.isActive === "Pasif"} style={{ backgroundColor: "red", color: "white" }} onClick={() => deleteExcProduct(record?.id)}>Sil</Button>}
        </Space>
      ),
      key: "delete",
    },
  ];
  // console.log(datas)

  return (

    <div>
      <Table dataSource={datas} pagination={false} columns={columns} />

      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        defaultCurrent={1}
        total={20}
        style={{ marginTop: "1rem" }}
      />
    </div>

  )
}

export default ExclusiveProductTable