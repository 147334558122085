import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";

import { Invoices as DataType } from "../interfaces/adminInterfaces";
import UnPaidTable from "../components/tables/pageTables/UnPaidTable";

const UnPaids: React.FC = () => {
  const [current, setCurrent] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [invoices, setInvoices] = useState<DataType[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('')

  //fetch all users
  const getInvoices = (filterValue: string | undefined) => {
    setLoading(true);
    AdminServices.getUnPaids(current, 20, filterValue)
      .then((res: any) => {
        // console.log("234",res.data.data)
        let TempArray: any[] = []
        res.data.data.map((item: any) => {
          TempArray.push({
            email: item.owner.email,
            gsmNumner: item.owner.gsmNumber,
            billingDate: item.billing_date,
            payment_status: item.payment_status,
            package: item.packagePlan.package.name + " " + (item.packagePlan.duration === 1 ? '(Monthly)' : '(Yearly)'),
          })
        })

        setInvoices(TempArray);
        setTotalPageCount(res.data.totalPageCount);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getInvoices(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);


  return (
    <>

      <UnPaidTable
        datas={invoices}
        loading={loading}
        setCurrent={setCurrent}
        current={current}
        totalPageCount={totalPageCount}
      />
    </>
  );
};

export default UnPaids;
