import React, { useRef, useState } from "react";
import { Form, Upload, Input, Button, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
const { Option } = Select;
interface FormValues {
  name: string;
  duration: string;
  price: string;
}

interface Payment {
  amount: number;
  currency: string;
  paymentChannel: string;
  description: string;
  paymentDate: Date;
}

const PlanCreate: React.FC = () => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    duration: "1",
    price: "0",
  });
  const [disable, setDisable] = useState(false);
  const handleSelectChange = (value: string) => {
    setFormValues({ ...formValues, duration: value });
  };
  const handleFormSubmit = () => {
    setDisable(true);
    adminService
      .createPlan(formValues)
      .then((res) => {
        alertNotification("success", "Başarıyla Eklendi");
        form.resetFields();
      })
      .catch((err) => {
        // console.log(err);
        alertNotification("error", err.status.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ marginBottom: "20px" }}>
        Hizmet Sağlayıcı Abonelik Ekleme Sayfası
      </h2>

      <Form
        style={{ width: "80%" }}
        onFinish={handleFormSubmit}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item label="Plan Name" name="name">
          <Input
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Plan Type" name="duration">
          <Select value={formValues.duration} onChange={handleSelectChange}>
            <Option value="1">Aylık</Option>
            <Option value="12">Yıllık</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Plan Price" name="price">
          <Input
            value={formValues.price}
            onChange={(e) =>
              setFormValues({ ...formValues, price: e.target.value })
            }
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
          <Button
            disabled={disable}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PlanCreate;
