import React, { useState, useEffect } from "react";
import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { Providers as DataType } from "../interfaces/adminInterfaces";
import ProvidersSubsTable from "../components/tables/pageTables/ProvidersSubsTable";
import { Button } from "antd";
import PlanTable from "../components/tables/pageTables/PlanTable";

const Plans: React.FC = () => {
  // const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [providers, setProviders] = useState<DataType[]>([]);

  const getPlans = () => {
    setLoading(true);
    // AdminServices.getPlanss()
    //   .then((res) => {
    //     setProviders(res.data);
    //   })
    //   .catch((err) => {
    //     alertNotification("error", err);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div style={{ width: "100%", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <h2>Hizmet Sağlayıcılar Abonelikleri</h2>
      </div>
      <a href="/plans-create">
        <Button
          style={{
            marginBottom: "20px",
          }}
        >
          Plan Ekle
        </Button>
      </a>
      <PlanTable />
    </div>
  );
};

export default Plans;
