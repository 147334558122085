import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, AutoComplete, Skeleton } from "antd";
import { useParams } from "react-router-dom";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { Question, FaqCategory } from "@src/interfaces/adminInterfaces";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const EditAIData = () => {
  const [aiData, setAiData] = useState<any>();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [allAiCats, setAllAiCats] = useState<any[]>([]);
  const [selectedCat, setSelectedCat] = useState<any>();
  const onSelect = (data: string) => {
    let selectedCat = allAiCats.filter(
      (item: any) => item.categoryName === data
    ).length>0?allAiCats.filter(
      (item: any) => item.categoryName === data
    )[0].id:0;
  
    setSelectedCat(selectedCat);
    console.log("onSelect", data, selectedCat);
  };

  const getPanelValue = (text: string) => {
    //uppercase category names
    let allCatsCategoryNames: string[] = [];
    allAiCats.map((item: any) => {
      if (item.categoryName) {
        allCatsCategoryNames.push(item.categoryName);
      }
    });
    let values: any[] = [];
    allCatsCategoryNames.map((item: string) => {
      if (item && item.toUpperCase().includes(text.toUpperCase())) {
        values.push({ value: item });
      }
    });
    values.push({ value: "Top" });
    return !text ? [] : values;
  };
  //submit
  const handleSubmit = async (values: any) => {
    try {
      adminService.updateAIData(Number(id), {
        info: values.info,
        aiCategoryChainId: selectedCat,
      });
      alertNotification("success", "Başarı ile güncellendi");
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };
  const getAllAiCats = async () => {
    try {
      const res = await adminService.getAICats("all");
      setAllAiCats(res.data);
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const getAIData = async (id: string) => {
    try {
      const res = await adminService.getAIData(Number(id));
      // console.log(res.data)
      //   let temp = {answer:res.data.answer,question: res.data.question,categoryId:res.data.faqCategoryId,id:question.id}

      setAiData(res.data);
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };
  useEffect(() => {
    getAIData(id || "1");
    getAllAiCats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h2 style={{ marginBottom: "20px" }}>Sıkça Sorulan Soruları Ekle</h2>
      {(aiData?.id&&allAiCats.length>0) && (
        <Form
          style={{ width: "80%" }}
          onFinish={handleSubmit}
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{
            info: aiData.info,
            categoryId:allAiCats.filter((item:any)=>item.id===Number(aiData.aiCategoryChainId))[0].categoryName,
            id: aiData.id,
          }}
        >
         <Form.Item label="Parent Category" name="categoryId">
              <AutoComplete
                options={options}
                style={{ width: 400 }}
                onSelect={onSelect}
                onSearch={(text) => setOptions(getPanelValue(text))}
                placeholder="Category Name"
              />
            </Form.Item>
            <Form.Item label="Info" name="info">
              <TextArea style={{ height: "500px" }} />
            </Form.Item>


          <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditAIData;
