import { Skeleton, Table, Pagination } from "antd";
import type { PaginationProps } from "antd";

import { Transactions } from "../../../interfaces/adminInterfaces";

import columnsList from "../ColumnsLists";
import styles from "./Tables.module.css";

interface TableProps {
  datas: Transactions[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
  refundTransaction: (id: number) => void;
}

const TransactionsTable: React.FC<TableProps> = ({
  datas,
  loading,
  setCurrent,
  current,
  totalPageCount,
  refundTransaction,
}) => {
  const data: Transactions[] = datas;

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Table
        className={styles.table}
        columns={columnsList.transactionsColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 2500 }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              const id = +(event.target as HTMLElement).id;
              // eslint-disable-next-line no-restricted-globals
              if (id === record.invoiceId && confirm("Sure to REFUND?")) {
                refundTransaction(record.id);
              }
            },
          };
        }}
      />
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={totalPageCount}
      />
    </>
  );
};

export default TransactionsTable;
