import Table, { ColumnsType } from 'antd/lib/table'
import { Button, Pagination, Skeleton, Tag } from 'antd'
import { API_IMAGE_URL } from '../../../config'
import { useNavigate } from 'react-router-dom'

interface ServicesTableProps {
    services: {
        description: string,
        id: number,
        imgPath: string,
        permission: boolean,
        serviceName: string,
        status: 'active' | 'passive',
        categories: string
    }[],
    loading: boolean,
    current: number
    setCurrent: React.Dispatch<React.SetStateAction<number>>
    pageCount: number
    deleteService: (id: number) => void
}

const ServicesTable: React.FC<ServicesTableProps> = ({ services, loading, current, setCurrent, pageCount, deleteService }) => {

    const navigate = useNavigate()
    const columns: ColumnsType<any> = [
        {
            title: "Service Name",
            dataIndex: "serviceName",
            key: "serviceName",

        },
        {
            title: "Image Path",
            dataIndex: "imgPath",
            key: "imgPath",
            render: (text) => {
                if (text) return <img
                    src={`${API_IMAGE_URL}/${text}`}
                    height={100}
                    width={100}
                    alt='imagePath'
                />
                return <p style={{ color: 'red' }}>No image</p>

            }
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text) => {
                if (text) {
                    return <div dangerouslySetInnerHTML={{ __html: text }} />
                } else {
                    return <p style={{ color: 'red' }}>No description</p>
                }
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status"
        },
        {
            title: "Approval",
            key: "pproval",
            dataIndex: "approval",
            render: (text) => {
                if (text === 'accepted') { return <Tag color="#87d068">{text}</Tag> }
                else if (text === 'declined') return <Tag color="#f50">{text}</Tag>
                else {
                    return <Tag color="blue">{text}</Tag>
                }
            }
        },
        {
            title: "Order",
            key: "order",
            dataIndex: "order",
            render: (text) => {
                if (text) {
                    return <p>{text}</p>
                } else {
                    return <p style={{ color: 'red' }}>No order</p>
                }
            }
        },
        {
            title: "Categories",
            key: "spCategories",
            dataIndex: "spCategories",
            render: (text: { id: number, categoryName: string }[]) => {
                if (text && text.length) {
                    const categoryNames = text?.map((s) => s.categoryName)
                    if (categoryNames.length) return <span>{categoryNames.toString()}</span>
                }
                else return <span style={{ color: 'red' }}>No categories</span>
            }
        },
        {
            title: "Update/Analyze",
            key: "updateAnalyze",
            dataIndex: "updateAnalyze",
            render: (text, object) => {
                if (object.approval === 'accepted' || object.approval === 'declined') {
                    return <Button
                        style={{ background: '#87d068', color: 'white' }}
                        onClick={() => navigate(`/services/${object.id}`)}
                    >Update
                    </Button>
                } else if (object.approval === 'requested') {
                    return <Button
                        style={{ background: 'red', color: 'white' }}
                        onClick={() => navigate(`/services/${object.id}`)}
                    >Analyze
                    </Button>
                }
            }
        },
        {
            title: "Delete",
            key: 'delete',
            dataIndex: 'delete',
            render: (text, object) => {
                return <Button
                    style={{ background: 'red', color: 'white' }}
                    onClick={() => deleteService(object.id)}
                >
                    Delete</Button>
            }
        }
    ]

    const onChange = (value: any) => {
        setCurrent(value)
    }

    return (
        <div>
            {
                loading ? (
                    <>
                        <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
                        <Skeleton key={"3"} active />{" "}
                    </>
                ) :
                    <>
                        <Table
                            columns={columns}
                            dataSource={services}
                            pagination={false}
                        />
                        <Pagination
                            showSizeChanger={false}
                            current={current}
                            onChange={onChange}
                            pageSize={20}
                            total={pageCount * 20}
                        />
                    </>
            }

        </div>
    )
}

export default ServicesTable