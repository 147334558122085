import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Checkbox, Form, Image, Input, Upload } from 'antd'

import adminService from '../services/adminService'
import alertNotification from '../utils/alertNotification'
import { Editor } from '@tinymce/tinymce-react'
import { API_IMAGE_URL } from '../config'
import { UploadOutlined } from '@ant-design/icons';


interface FormValues {
    id?: any
    title?: string
    content?: string
    category?: any
    blogAuthorId?: any
    readTime?: any
    metaDescription?: string
    metaKeywords?: string
    metaTitle?: string
    image?: File | string
    imgTitle?: string
    imgAlt?: string
    imgPath?: string
    description?: string

}

const EditBlog: React.FC = () => {
    const params = useParams()

    const [form] = Form.useForm()
    const [formValues, setFormValues] = useState<FormValues>({
        id: "",
        title: "",
        content: "",
        category: [],
        blogAuthorId: "",
        readTime: "",
        metaDescription: "",
        metaKeywords: "",
        metaTitle: "",
        image: "",
        imgTitle: "",
        imgAlt: "",
        imgPath: "",
        description: ""
    })

    const [categories, setCategories] = useState<string[]>([])



    const getBlogPost = async (lang: string, id: number) => {
        try {
            const res = await adminService.getBlogPostById(lang, id)
            setFormValues(res.data)

        } catch (e) {
            alertNotification('error', 'Blog yazısı getirilirken bir hata oluştu.')
        }
    }

    const getCategories = async () => {
        const res = await adminService.getBlogCategories('tr')
        setCategories(res.data)
    }


    const imageUploadHandler = async (blobInfo?: any): Promise<any> =>
        new Promise(async (resolve, reject) => {
            try {
                const formData = new FormData();
                formData.append('image', blobInfo.blob(), blobInfo.filename());

                const res = await adminService.uploadImageToBlog(formData)
                resolve(res.data)
            } catch (error: any) {
                reject('Error uploading image: ' + error.message);
            }
        })

    const handleFormSubmit = async () => {
        try {
            const formData = new FormData()

            formData.append('title', formValues.title!)
            formData.append('content', formValues.content!)
            formData.append('category', JSON.stringify(formValues.category!))
            formData.append('readTime', formValues.readTime!)
            formData.append('image', formValues.image!)
            formData.append('description', formValues.description!)

            if (formValues.metaDescription) formData.append('metaDescription', formValues.metaDescription!)
            if (formValues.metaKeywords) formData.append('metaKeywords', formValues.metaKeywords!)
            if (formValues.metaTitle) formData.append('metaTitle', formValues.metaTitle!)
            if (formValues.imgTitle) formData.append('imgTitle', String(formValues.imgTitle))
            if (formValues.imgAlt) formData.append('imgAlt', String(formValues.imgAlt))


            await adminService.updateBlogPost(params.lang || 'tr', formValues.id, formData);
            alertNotification('success', "Blog yazısı başarıyla güncellendi.")
            getBlogPost(params.lang!, +params.id!)

        } catch (err: any) {
            alertNotification('error', err?.response?.data?.message || 'Blog yazısı güncellenirken bir hata oluştu.')
        }
    }



    useEffect(() => {
        getBlogPost(params.lang!, +params.id!)
        getCategories()

    }, [])


    return (
        <>
            <Form onFinish={handleFormSubmit} form={form}>


                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                    <Image src={`${API_IMAGE_URL}/${formValues.imgPath}`} width={200} />

                    <Form.Item>
                        <Upload
                            beforeUpload={(file) => {
                                setFormValues({ ...formValues, image: file })
                                return false
                            }}
                        >
                            <Button icon={<UploadOutlined />} style={{ marginTop: '5px' }}>
                                Update Image
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Title' style={{}}>
                        <Input value={formValues.imgTitle} onChange={(e) => setFormValues({ ...formValues, imgTitle: e.target.value })} />

                    </Form.Item>
                    <Form.Item label='Alt Desc'>
                        <Input value={formValues.imgAlt} onChange={(e) => setFormValues({ ...formValues, imgAlt: e.target.value })} />

                    </Form.Item>
                </div>

                <Form.Item label='Category'>
                    <Checkbox.Group
                        value={formValues.category}
                        onChange={(values) => setFormValues({ ...formValues, category: [...values] })}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                categories.map((el: any) => {
                                    return (
                                        <Checkbox key={el.id} value={el.categoryName.trim()}>{el.categoryName}</Checkbox>
                                    )
                                })
                            }
                        </div>

                    </Checkbox.Group>
                </Form.Item>
                <Form.Item label='Title'>
                    <Input value={formValues.title} onChange={(e) => setFormValues({ ...formValues, title: e.target.value })} />
                </Form.Item>
                <Form.Item label='Description'>
                    <Input value={formValues.description} onChange={(e) => setFormValues({ ...formValues, description: e.target.value })} />
                </Form.Item>
                <Form.Item label='Meta Description'>
                    <Input placeholder='Meta Description giriniz...' value={formValues.metaDescription} onChange={(e) => setFormValues({ ...formValues, metaDescription: e.target.value })} />
                </Form.Item>
                <Form.Item label='Meta Keywords'>
                    <Input placeholder='Meta Keywords giriniz...' value={formValues.metaKeywords} onChange={(e) => setFormValues({ ...formValues, metaKeywords: e.target.value })} />
                </Form.Item>
                <Form.Item label='Meta Title'>
                    <Input placeholder='Meta Title giriniz...' value={formValues.metaTitle} onChange={(e) => setFormValues({ ...formValues, metaTitle: e.target.value })} />
                </Form.Item>
                <Editor
                    value={formValues.content}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            "advlist", "autolink", "lists", "link", "charmap", "print", "preview",
                            "searchreplace", "visualblocks", "code", "fullscreen",
                            "insertdatetime", "table", "paste", "code", "help", "wordcount",
                            "anchor", "image"
                        ],
                        image_title: true,
                        convert_urls: false,
                        toolbar: 'undo redo | formatselect | image | bullist numlist outdent indent | help | blocks fontfamily fontsize | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify lineheight | removeformat | link | anchor',
                        font_family_formats: "Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Rubik=rubik, sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                        content_style: 'body { font-family: Rubik; }',
                        images_upload_handler: imageUploadHandler
                    }}

                    onEditorChange={(value) => setFormValues({ ...formValues, content: value })}
                ></Editor>

                <Form.Item style={{ width: "150px", margin: "10px 0" }} label="Read Time">
                    <Input

                        onChange={(e) => setFormValues({ ...formValues, readTime: e.target.value })}
                        value={formValues.readTime}
                        placeholder='Read time..' />
                </Form.Item>
                <div>
                    <Button
                        style={{ margin: "10px 0" }}
                        type='primary'
                        htmlType='submit'
                    > Güncelle</Button>
                </div>


            </Form>
        </>
    )
}

export default EditBlog;
