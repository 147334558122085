import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import alertNotification from "../../../utils/alertNotification";
import AdminService from "../../../services/adminService";

const PlanTable = () => {
  const columns = [
    { title: "Plan Name", dataIndex: "planName", key: "planName" },
    { title: "Reference", dataIndex: "reference", key: "reference" },
    { title: "Link", dataIndex: "link", key: "link" },
    { title: "Payment Type", dataIndex: "paymentType", key: "paymentType" },
    { title: "Price", dataIndex: "price", key: "price" },
  ];
  const dataInit = [
    {
      key: 1,
      planName: "-",
      reference: "-",
      link: "-",
      paymentType: "-",
      price: "-",
      subs: [],
    },
  ];
  const [plans, setPlans] = useState<any[]>(dataInit);

  const getPlans = () => {
    AdminService.getPlans()
      .then((res: any) => {
        // console.log(res.data);
        setPlans(res.data);
      })
      .catch((err: any) => {
        alertNotification("error", err);
      });
  };

  useEffect(() => {
    getPlans();
  }, []);
  const renderExpandedContent = (record: any) => {
    const subColumns = [
      { title: "Email", dataIndex: "email", key: "email" },
      { title: "GSM", dataIndex: "gsmNumber", key: "gsmNumber" },
    ];
    return (
      <Table columns={subColumns} dataSource={record.subs} pagination={false} />
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={plans}
      expandable={{
        expandedRowRender: renderExpandedContent,
        rowExpandable: (record) => record.subs.length > 0,
      }}
    />
  );
};

export default PlanTable;
