import React, { useEffect, useState, useContext } from "react";
import { Layout, Menu, Button } from "antd";
import type { MenuProps } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import AuthContext from "../context/AuthContext";

import pages from "./PageList";
import rexvenLogo from "../components/assets/rexven-logo.svg";
import "./MainLayout.css";

const { Header, Sider, Content, Footer } = Layout;

interface MainLayoutPropsInterface {
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutPropsInterface> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState<MenuProps["items"]>();

  const { user, logout } = useContext(AuthContext);

  // Adjust menu by user role
  const handleFilterMenu = (pages: any) => {
    let filterdedMenuItems = pages.filter(
      (el: { rolecode: (string | undefined)[] }) => {
        return el.rolecode?.includes(user?.roleCode)
      }
    );
    setItems(filterdedMenuItems);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleFilterMenu(pages), []);

  //select which content will be rendered
  let content: JSX.Element = (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        onCollapse={(collapsed) => {
          setCollapsed(collapsed);
        }}
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <img src={rexvenLogo} alt="rexven-logo" className="logo" />
        {
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            items={items}
          />
        }
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          {user?.role ? (
            <Button onClick={logout} danger>
              LogOut
            </Button>
          ) : (
            ""
          )}
        </Header>

        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          {children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          REXVEN ADMIN PANEL ©2022 Created by REXVEN TEKNOLOJI A.S
        </Footer>
      </Layout>
    </Layout>
  );

  return content;
};

export default MainLayout;
