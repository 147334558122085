import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Row, Col } from 'antd';
import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import styles from "./TestAiQuestionAnswer.module.css"


const {Option} = Select;
const TestAiQuestionAnswer = () => {
    const [form] = Form.useForm();

    const [answers,setAnswers] = useState<string>("...")


    const handleQuestionSubmit = async (values:{question:string})=>{
        try {
            //create işlemi yap 
           console.log(values)
            setAnswers("loading")
            let result:any=await adminService.createAiQuestion(values);
            console.log(result)
            setAnswers(result.data.message)       

        } catch (error) {
            alertNotification("error","Bir hata oluştu!")
            setAnswers("Bir hata peydah oldu!")
        }
    }

  return (
    <>
    <h1 style={{textAlign:"center",marginBottom:"30px"}}>AI Doküman Testi</h1>
    <Row justify='space-between' >
        <Col span={12}>
    <h1 style={{textAlign:"center"}}>Buradan sorunuzu girebilirsiniz</h1>
          <Form  style={{width:"100%"}} onFinish={handleQuestionSubmit} form={form} labelCol={{span:4}} wrapperCol={{span:20}}>
    
            <Form.Item label="Soru" name="question">
              <Input.TextArea rows={10}/>
            </Form.Item>
        
      
            <Form.Item wrapperCol={{offset:20,span:4}} >
              <Button style={{width:"100%"}} type="primary" htmlType="submit">
                Gönder
              </Button>
            </Form.Item>

          </Form>
        </Col>
        <Col span={12}>
          <h1 style={{textAlign:"center"}}>AI Cevap</h1>

        <p style={{marginLeft:"40px"}}>
            {answers=== "loading" ?
             <div className={styles.loadingcontainer}>
             <div className={styles.loading}></div>
         </div>
            : answers}
        </p>
      
        </Col>
    </Row>
    </>
  )
}

export default TestAiQuestionAnswer