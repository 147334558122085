import React, { useContext } from "react";
import { Avatar, Button, Card, Col, Row, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { UserDetail as DataType } from "../../../../interfaces/adminInterfaces";
import styles from "../UserDetailCard.module.css";
import LoadingCard from "../../LoadingCard";
import AuthContext from "../../../../context/AuthContext";
interface UserDetailPage {
  detailedUser?: DataType;
  loading: boolean;
  setModalOpen: Function;
  generateUserPassword: Function;
  generatedPassword: string;
  passwordLoading: boolean
  verifyUser: Function;
  verifyGsmNumber: Function;
}

const { Meta } = Card;

const UserDetailedInfoCard: React.FC<UserDetailPage> = ({
  detailedUser,
  loading,
  setModalOpen,
  generateUserPassword,
  generatedPassword,
  passwordLoading,
  verifyUser,
  verifyGsmNumber
}) => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <Col lg={11} md={24} sm={24} xs={24} style={{ marginRight: "30px" }}>
        {loading ? (
          <LoadingCard loading={loading} />
        ) : (
          <Card
            style={{
              width: "100%",
              border: "1px",
              borderRadius: "10px",
              boxShadow: "0px 4px 32px 1px #00000029",
            }}
            actions={[
              user?.roleCode === "pt04n1dT" ? (
                <Button
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  danger
                >
                  UPDATE USER ROLE
                </Button>
              ) : (
                ""
              ),
              user?.roleCode !== 'hgytB89' &&
              <>
                <Button
                  onClick={() => generateUserPassword()}
                  danger
                >
                  GENERATE USER PASSWORD
                </Button>
                
                <br />
                <span
                  style={{
                    // marginLeft: "0.3vw"
                  }}
                >
                  {passwordLoading ? 'Üretiliyor...' : generatedPassword ? 'GENERATED PASSWORD: ' + generatedPassword : ''}
                </span>
                
              </>,

              user?.roleCode !== 'hgytB89' && !Boolean(detailedUser?.isVerified) &&  <Button
                onClick={() => verifyUser(user?.id)}
                danger
                // style={{marginLeft: '1vw'}}
                >
                  VERIFY USER
                </Button>

              ,

              user?.roleCode !== 'hgytB89' && !Boolean(detailedUser?.isGsmVerified) &&  <Button
                onClick={() => verifyGsmNumber(user?.id)}
                danger
                // style={{marginLeft: '1vw'}}
                >
                  VERIFY GSM NUMBER
                </Button>

              
            ]}
          >
            <Meta
              avatar={
                detailedUser?.profileImage ? (
                  <Avatar src={detailedUser.profileImage} />
                ) : (
                  <Avatar
                    size={64}
                    style={{ backgroundColor: "#87d068" }}
                    icon={<UserOutlined />}
                  />
                )
              }
              title={`${detailedUser?.firstName} ${detailedUser?.lastName}`}
              description={detailedUser?.email}
            />
            <div className={styles.wrapper}>
              <Row className={styles.detailRows}>
                <span> PASSWORD CONFIRM : </span>
                {detailedUser?.passwordConfirm ? "EVET" : "HAYIR"}
              </Row>
              <Row className={styles.detailRows}>
                <span> SELLER : </span>
                {detailedUser?.isSeller ? "EVET" : "HAYIR"}
              </Row>
              <Row className={styles.detailRows}>
                <span> IN COMMUNITY : </span>
                {detailedUser?.inCommunity ? "EVET" : "HAYIR"}
              </Row>
              <Row className={styles.detailRows}>
                <span>VERIFIED : </span>{" "}
                {detailedUser?.isVerified ? "EVET" : "HAYIR"}
              </Row>
              <Row className={styles.detailRows}>
                <span>GSM VERIFIED : </span>{" "}
                {detailedUser?.isGsmVerified ? "EVET" : "HAYIR"}
              </Row>
              <Row className={styles.detailRows}>
                <span> STATUS : </span>
                {detailedUser?.status ? detailedUser?.status : "-"}
              </Row>
              <Row className={styles.detailRows}>
                <span>DISCORD ID : </span>
                {detailedUser?.discordUserId ? detailedUser?.discordUserId : "-"}
              </Row>
              <Row className={styles.detailRows}>
                <span> STRIPE REFERENCE : </span>
                {detailedUser?.stripeReference
                  ? detailedUser?.stripeReference
                  : "-"}
              </Row>
              <Row className={styles.detailRows}>
                <span> IYZICO REFERENCE : </span>
                {detailedUser?.iyzicoReference
                  ? detailedUser?.iyzicoReference
                  : "-"}
              </Row>
              <Row className={styles.detailRows}>
                <span> PASSWORD CHANGED: </span>
                {detailedUser?.passwordChangedAt?.slice(0, 10)}
              </Row>
              <Row className={styles.detailRows}>
                <span> CREATED : </span>
                {new Date(detailedUser?.createdAt!)?.toLocaleString("tr-TR")}
              </Row>
              <Row className={styles.detailRows}>
                <span> ROLE : </span>
                <Tag
                  color={detailedUser?.role.name === "user" ? "green" : "magenta"}
                >
                  {detailedUser?.role?.name?.toUpperCase()}
                </Tag>
              </Row>
            </div>
          </Card>
        )}
      </Col>
    </>
  );
};

export default UserDetailedInfoCard;
