import { useEffect, useState } from 'react';
import AnnouncementsTable from '../components/tables/pageTables/AnnouncementsTable';
import AdminServices from "../services/adminService";
import { IAnnouncements as DataType } from '../interfaces/adminInterfaces';
import alertNotification from '../utils/alertNotification';
import { Button } from "antd";


export default function Announcements() {
    const [loading, setLoading] = useState<boolean>(false);
    const [announcements, setAnnouncements] = useState<DataType[]>([]);
    // const [error, setError] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [totalAnnouncementCount, setTotalAnnouncementCount] = useState(1);



    // get all announcements
    const getAnnouncements = () => {
        setLoading(true);
        AdminServices.getAnnouncements(page)
            .then((res) => {
                setAnnouncements(res.data)
                setTotalAnnouncementCount(res.length)
            })
            .catch((err) => {
                // setError(true);
                alertNotification("error", err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        getAnnouncements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <div>
            <a href='/create-announcement' target='_blank'>
                <Button style={{ marginBottom: "30px", backgroundColor: "Highlight" }} >
                    Yeni Duyuru Ekle
                </Button>
            </a>

            <AnnouncementsTable
                data={announcements}
                loading={loading}
                page={page}
                setPage={setPage}
                totalAnnouncementCount={totalAnnouncementCount}
            />
        </div>
    )
};

