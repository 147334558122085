import { Select, Form, Input, Checkbox, Upload, Button } from 'antd'
import { SetStateAction, useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { UploadOutlined } from '@ant-design/icons';

interface IFormValues {
    serviceName: string
    description: string
    order: number
    categoryIds: CheckboxValueType[]
    image: File | null
}

interface IAddServiceFormProps {
    showServiceAddition: boolean
    getServices: (approval: 'requested' | 'declined' | 'accepted' | 'all', page: number) => void
    setShowServiceAddition: React.Dispatch<React.SetStateAction<boolean>>
}
const selectOptions = Array.from(Array(10), (_, index) => index + 1).map((number) => { return { value: number, label: number } })

const AddServiceForm: React.FC<IAddServiceFormProps> = ({ getServices, setShowServiceAddition }) => {

    const [categories, setCategories] = useState<{ id: number, categoryName: string }[]>([])
    const [formValues, setFormValues] = useState<IFormValues>({
        serviceName: '',
        description: '',
        order: 1,
        categoryIds: [],
        image: null
    })

    const onFinish = (values: any) => {
        if (!formValues.description || !formValues.description.length) return alertNotification('error', 'Description zorunludur')
        const formData = new FormData()
        formData.append('serviceName', formValues.serviceName)
        formData.append('description', formValues.description)
        formData.append('order', String(formValues.order))
        formData.append('categoryIds', formValues.categoryIds.toString())
        formData.append('image', formValues.image as File)

        adminService.addSpService(formData)
            .then(res => {
                alertNotification('success', 'Hizmet başarıyla eklendi.')
                setFormValues({
                    serviceName: '',
                    description: '',
                    order: 1,
                    categoryIds: [],
                    image: null
                })
                getServices('all', 1)
                setShowServiceAddition(false)
            })
            .catch(err => alertNotification('error', 'anan'))
    }

    const getCategories = () => {
        adminService.getSpCategories()
            .then((res) => {
                setCategories(res.data)
            })
            .catch(err => alertNotification('error', err.message))
    }

    useEffect(() => {
        getCategories()
    }, [])

    return (
        <div>
            <Form
                onFinish={onFinish}
                wrapperCol={{ span: 6 }}
                labelCol={{ span: 2 }}
            >
                <Form.Item
                    label='Service Name'
                    name='serviceName'
                    // labelCol={{ span: 1.5 }}
                    rules={[{ required: true, message: 'Service name zorunlu alandır.' }]}
                >
                    <Input
                        value={formValues.serviceName}
                        onChange={(e) => setFormValues({ ...formValues, serviceName: e.target.value })}
                    />
                </Form.Item>
                <Form.Item
                    label='Order'
                    name='order'
                    rules={[{ required: true, message: 'Order zorunlu alandır.' }]}
                >
                    <Select
                        // defaultValue={formValues.order}
                        onChange={(value) => setFormValues({ ...formValues, order: value })}
                        options={selectOptions}
                        style={{ width: '12vw' }}
                        value={formValues.order}
                    />
                </Form.Item>
                <Form.Item
                    label='Categories'
                    name='categories'
                    rules={[{ required: true, message: 'Categories zorunlu alandır.' }]}
                >
                    <Checkbox.Group
                        options={categories.map((category) => { return { label: category.categoryName, value: category.id } })}
                        onChange={(checkedValues) => setFormValues({ ...formValues, categoryIds: checkedValues })}
                    />
                </Form.Item>
                <Form.Item
                    label="Image"
                    name="image"
                    rules={[{ required: true, message: 'Image zorunlu alandır.' }]}
                >
                    <Upload
                        accept='.png, .jpg, .jpeg, .webp, .svg'
                        beforeUpload={(file) => {
                            setFormValues({ ...formValues, image: file });
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                    </Upload>
                </Form.Item>

                <Editor
                    value={formValues.description}
                    onEditorChange={(newValue, editor) => {
                        setFormValues({ ...formValues, description: newValue })
                    }}
                    initialValue="<p>Hizmet Açıklaması giriniz....</p>"
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                />
                <Form.Item
                    style={{ marginTop: '1vw' }}
                >
                    <Button
                        htmlType='submit'
                        type='primary'

                    > Submit </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

export default AddServiceForm