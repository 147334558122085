import { Providers } from "../../../interfaces/adminInterfaces";
import React from "react";
import { Button, Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import adminService from "../../../services/adminService";
import { Link } from "react-router-dom";
import alertNotification from "../../../utils/alertNotification";
import { API_IMAGE_URL } from "../../../config";

interface ProvidersTableProps {
  providers: Providers[];
  getProviders: () => void;
}

const ProvidersTable: React.FC<ProvidersTableProps> = ({
  providers,
  getProviders,
}) => {
  const handleClick = (id: number | string) => {
    // console.log('id', id)
    adminService
      .deleteServiceProviderSub(id)
      .then((res) => {
        alertNotification(
          "success",
          `${id} - Hizmet Sağlayıcı başarıyla silindi`
        );
        // console.log('res', res)
      })
      .catch((err) => {
        alertNotification("error", `${id} - Hizmet Sağlayıcı silinemedi!`);
        // console.log("err", err);
      })
      .finally(() => {
        getProviders();
      });
  };

  const columns: ColumnsType<Providers> = [
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Abonelik Tipi",
      dataIndex: "subsType",
      key: "subsType",
    },
    {
      title: "Miktar",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Sub Start Date",
      dataIndex: "subStart",
      key: "subStart",
    },
    {
      title: "Sub End Date",
      dataIndex: "subEnd",
      key: "subEnd",
    },
    {
      title: "Description",
      key: "Description",
      render: (_, record) => (
        <Space size="middle">
          {<p>{`${record.description}`}</p>}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
    },
    {
      title: "Update",
      render: (_, record) => (
        <Space size="middle">
          {<Link to={`/service-providers-sub/${record.id}`}>Edit</Link>}
        </Space>
      ),
      key: "UpdateDelete",
    },
    {
      title: "Detail",
      render: (_, record) => (
        <Space size="middle">
          {
            <Link to={`/service-providers-sub/detail/${record.id}`}>
              Detail
            </Link>
          }
        </Space>
      ),
      key: "UpdateDelete",
    },
    {
      title: "Delete",
      render: (_, record) => (
        <Space size="middle">
          {
            <Button
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => handleClick(record.id)}
            >
              Delete
            </Button>
          }
        </Space>
      ),
      key: "delete",
    },
  ];
  return (
    <div>
      <Table dataSource={providers} pagination={false} columns={columns} />
    </div>
  );
};

export default ProvidersTable;
