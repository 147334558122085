import React from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import adminService from "../../services/adminService";
import alertNotification from "../../utils/alertNotification";

interface Props {
  getProducts: () => Promise<void>
  selectedProduct: any
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}
const CreateVariantProductForm: React.FC<Props> = ({ getProducts,selectedProduct,setOpenModal }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    values.supplierProductId = selectedProduct.id;
    values.supplierId = selectedProduct.supplierId;
    values.supplierCategoryId = selectedProduct.supplierCategoryId;
    values.imageList = values.imageList && values.imageList.includes(',') ? values.imageList.split(",") : values.imageList ? [values.imageList] : [];
    try {
      await adminService.createVariationProduct(values);
      setOpenModal(false);
      getProducts()


    } catch (error:any) {
      alertNotification("error", error.response.data.message);
    }
    finally{
        form.resetFields();
    }

  };
  
  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18, offset: 1 }}
        onFinish={handleSubmit}
      >
        <Form.Item name={"name"} label="Başlık">
          <Input />
        </Form.Item>
        <Form.Item name={"price"} label="Fiyat">
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Resimler:"
          name={"imageList"}
        >
          <Input placeholder="Lütfen resim yollarının arasına virgül koyun" />
        </Form.Item>
        <Form.Item
          label="Ağırlık Miktarı"
          name={"weightValue"}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Ağırlık Cinsi" name="weightUnit">
            <Select>
                <Select.Option value="grams">Gram</Select.Option>
                <Select.Option value="pounds">Pound</Select.Option>
                <Select.Option value="pououncesnds">Ounce</Select.Option>
            </Select>    
        </Form.Item>
        <Form.Item
          label="Fulfillment Fee"
          name={"fulfillmentFee"}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="From Postal Code:"
          name={"fromPostalCode"}
        >
          <Input  />
        </Form.Item>

        <Form.Item label="Kargo" name="carrier">
            <Select>
                <Select.Option value="shipentegra">Shipentegra</Select.Option>
                <Select.Option value="shipstation">Shipstation</Select.Option>
            </Select>    
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Onayla
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateVariantProductForm;
