
import alertNotification from '../utils/alertNotification';
import BirFaturaFilter from '../components/filter/BirFaturaFilter';
import adminService from '../services/adminService';
import { Button, Pagination, PaginationProps, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react'
import BirFaturaModal from '../components/modal/BirFaturaModal';
import BirFaturaUpdateModal, { InvoiceModal } from '../components/modal/BirFaturaUpdateModal';



const BirFatura = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [current, setCurrent] = useState(1);
  const [invoice, setInvoice] = useState<InvoiceModal | null>(null);
  const [invoices, setInvoices] = useState([]);
  const [datas, setDatas] = useState([]);
  const [payload, setPayload] = useState({ startDate: "", endDate: "", email: "" })

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const handleUpdateOk = () => {
    setIsUpdateModalOpen(false);
  };
  const showUpdateModal = (email: string) => {
    setSelectedEmail(email)

    let temp = invoices?.find((item: { email: string; }) => {
      return item.email === email
    })

    setInvoice(temp!)
    setIsUpdateModalOpen(true)

  }

  const handleUpdateCancel = () => {
    setIsUpdateModalOpen(false);
  };
  const deleteInvoice = async (id: number) => {
    try {

      await adminService.deleteBirFaturaInvoice(id)
    } catch (error) {
      alertNotification("error", "Fatura silinemedi")
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ad-Soyad",
      dataIndex: "billingName",
      key: "billingName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },


    {
      title: "Fatura Tarihi",
      dataIndex: "billingDate",
      key: "billingDate",
    },
    {
      title: "Tutar",
      dataIndex: "paidPrice",
      key: "paidPrice",
    },
    {
      title: "Kaynak",
      dataIndex: "source",
      key: "source",
    },

    {
      title: "Düzenle",
      render: (_, record) => (
        <Space size="middle">
          {<Button onClick={() => showUpdateModal(record.email)}>Düzenle</Button>}
        </Space>
      ),
      key: "edit",
    },
    {
      title: "Sil",
      render: (_, record) => (
        <Space size="middle">
          {<Button style={{ backgroundColor: "red", color: "white" }} onClick={() => deleteInvoice(record.id)}>Sil</Button>}
        </Space>
      ),
      key: "delete",
    },
  ];

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  const getInvoices = async () => {
    try {
      const res = await adminService.getBirFaturaInvoices({ ...payload, page: current });

      let temp = res.data.data;
      const tableData = temp.map((item: {
        email: any;
        paidPrice: any;
        source: any;
        billingDate: any; id: any; billingInfo: { billingName: any; };
      }) => {
        return { id: item.id, billingName: item.billingInfo.billingName, billingDate: item.billingDate, paidPrice: item.paidPrice, source: item.source, email: item.email }
      })
      setInvoices(temp);
      setDatas(tableData)
    } catch (error) {
      alertNotification("error", "Faturalar getirilken bir hata oluştu")
    }
  }
  useEffect(() => {
    getInvoices()
  }, [payload, current])

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <BirFaturaFilter setPayload={setPayload} />
        <Button style={{ border: "1px solid green" }} onClick={showModal}>Yeni Fatura Oluştur</Button>
      </div>
      <Table dataSource={datas} pagination={false} columns={columns} />
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        defaultCurrent={1}
        total={20}
        style={{ marginTop: "1rem" }}
      />
      <BirFaturaModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
      <BirFaturaUpdateModal getInvoices={getInvoices} isModalOpen={isUpdateModalOpen} invoice={invoice} handleOk={handleUpdateOk} handleCancel={handleUpdateCancel} selectedEmail={selectedEmail} />
    </div>
  )
}

export default BirFatura