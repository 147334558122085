import { Button, Input, Select } from 'antd'
import adminService from '../services/adminService'
import alertNotification from '../utils/alertNotification'
import React, { useEffect, useState } from 'react'
import BlogPostsTable from '../components/tables/pageTables/BlogPostsTable'
import { BlogPosts } from '../interfaces/adminInterfaces'
import LoadingCard from '../components/cards/LoadingCard'
import { Option } from 'antd/es/mentions'

export default function BlogPost() {
    const [loading, setLoading] = useState<boolean>(false)
    const [blogPosts, setBlogPosts] = useState<BlogPosts[]>([]);
    const [keyword, setKeyword] = useState<string>('')
    const [lang, setLang] = useState<'en' | 'tr'>('tr')

    const getBlogPosts = async (): Promise<void> => {
        try {
            setLoading(true)
            const res = await adminService.getBlogPosts(lang!, keyword)

            res.data.map((el: BlogPosts) => {
                el.blogCategories = el.category.join(", ")
            })
            setBlogPosts(res.data)
            setLoading(false)
        } catch (e) {
            alertNotification("error", "Yazarlar getirilemedi.")
            // console.log(e)
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setKeyword(value)
    }


    // console.log(blogPosts)

    useEffect(() => {
        getBlogPosts()
    }, [lang])

    return (
        <>
            <Select
                labelInValue
                style={{ width: 150 }}
                placeholder="Select Language"
                onChange={({ value }) => setLang(value)}

            >
                <Option value="en"  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="/flags/en-rounded.png" alt="en" width="20" style={{ margin: '0 5px' }} />
                        <span style={{ fontWeight: 'bold' }}>EN</span>

                    </div>
                </Option>
                <Option value="tr" >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="/flags/tr-rounded.png" alt="tr" width="20" style={{ margin: '0 5px' }} />
                        <span style={{ fontWeight: 'bold' }}>TR</span>

                    </div>
                </Option>

            </Select>


            <div style={{ width: "100%", justifyContent: "center" }}>
                <div style={{
                    margin: "5px 0",
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"

                }}>
                    <Input
                        style={{ width: "90%" }}
                        placeholder='Başlık, İçerik, Yazar ara..'
                        onChange={handleInputChange}
                        name="keyword"
                        value={keyword}
                    />
                    <Button type="primary" onClick={getBlogPosts}>Ara</Button>
                </div>

                <a href="/create-blog-post">
                    <Button
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        Blog yaz
                    </Button>
                </a>
                {
                    loading ? <LoadingCard loading={loading} /> : <BlogPostsTable lang={lang} blogPosts={blogPosts} getBlogPosts={getBlogPosts} />
                }

            </div>

        </>
    )
}
