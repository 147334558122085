import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row } from "antd";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import AICatTable from "../components/tables/pageTables/AICatTable";

const AICats = () => {
  const [current, setCurrent] = useState(1);
  const [aiCats, setAiCats] = useState<any[]>([
    {
      id: 1,
      question: "Sordum soruyu!",
      answer: "Anladın sen!",
      faqCategoryId: 1,
    },
  ]);
  const [pageCount, setPageCount] = useState<number | any>();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  
  const [form] = Form.useForm();

  const getAICats = async (level:string) => {
    try {
      const res = await adminService.getAICats(level);
      let cats=res.data

      let aiCatsArray:any[]=[]
      cats.map((item: any) => {

        aiCatsArray.push({
          id: item.id,
          categoryName: item.categoryName,
          parentCategory: item.parentId===0?"Top":(cats.filter((cat:any)=>cat.id===item.parentId).length>0?cats.filter((cat:any)=>cat.id===item.parentId)[0].categoryName:"Bulunamadı"),
          level: item.level,
          dataId:item.aiData.length>0?item.aiData[0]?.id:0
        });
      })
      console.log("aiCatsArray",aiCatsArray.length)
      setAiCats(aiCatsArray);
      setPageCount(res.data.totalPageCount);
    } catch (error) {
      console.log(error)
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const onFinish = (values: { keyword: string }) => {
    setKeyword(values.keyword);
  };

  useEffect(() => {
    getAICats("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, keyword]);
  return (
    <div style={{ width: "100%" }}>

      <a href="/create-ai-cat">
        <Button
          style={{
            marginBottom: "20px",
          }}
        >
          Yeni Kategori Ekle
        </Button>
      </a>

      <a href="/create-ai-data">
        <Button
          style={{
            marginBottom: "20px",
          }}
        >
          Yeni Data Ekle
        </Button>
      </a>

      <AICatTable
        setCurrent={setCurrent}
        current={current}
        datas={aiCats}
        loading={loading}
        totalPageCount={pageCount}
        getAICats={getAICats}
      />
    </div>
  );
};

export default AICats;
