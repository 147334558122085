import React from "react";
import { Table, Pagination, Button, Modal, Form, Input, Select } from "antd";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "./Tables.module.css";
import { IWallet } from "src/interfaces/adminInterfaces";
import { Link, useLocation } from "react-router-dom";
import alertNotification from "src/utils/alertNotification";
import adminService from "src/services/adminService";

interface TableProps {
  datas: IWallet[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
}

const WalletTransactionsTable: React.FC<TableProps> = ({
  datas,
  loading,
  setCurrent,
  current,
  totalPageCount,
}) => {
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [reqId, setReqId] = React.useState();

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };
  const walletColumns: ColumnsType<IWallet> = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      render: (record) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },

      key: "date",
    },
    {
      title: "Seller Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Status",
      render: (record) => {
        return record?.status === "pending" &&
          location.pathname === "/wallet-transactions" ? (
          <Link
            to={`/users/${record.userId}`}
            target="_blank"
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            DÜZENLE
          </Link>
        ) : record.status === "pending" &&
          location.pathname !== "/wallet-transactions" ? (
          <Button
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setReqId(record.id);
              setOpen(true);
            }}
          >
            DÜZENLE
          </Button>
        ) : (
          <div style={{ backgroundColor: "green", color: "white" }}>
            GÖRÜLDÜ
          </div>
        );
      },
      // dataIndex: "status",
      key: "status",
    },
    {
      title: "Admin Note",
      render: (record) => {
        return record.adminNote !== null ? <p>{record.adminNote}</p> : <p>-</p>;
      },
      // dataIndex: "adminNote",
      key: "adminNote",
    },
  ];
  const [form] = Form.useForm();
  const handleSubmit = async (values: any) => {
    try {
      await adminService.updateStatusOfWalletRequest(reqId!, values);
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }
  };
  console.log(datas);

  return (
    <>
      <Table
        className={styles.table}
        columns={walletColumns}
        dataSource={datas}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 960 }}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => {
        //       navigate(`/users/${record.id}`);
        //     },
        //   };
        // }}
      />

      <Modal
        open={open}
        style={{ top: 20 }}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        title="Admin Note"
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18, offset: 1 }}
          onFinish={handleSubmit}
        >
          <Form.Item label="Not:" name="adminNote">
            <Input />
          </Form.Item>
          <Form.Item label="Status:" name="status">
            <Select placeholder="Status Seçiniz">
              <Select.Option value="pending">PENDING</Select.Option>
              <Select.Option value="processed">PROCESSED</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 20 }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={totalPageCount}
      />
    </>
  );
};

export default WalletTransactionsTable;
