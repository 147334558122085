import { Button, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';

import { DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { Contents } from '@src/interfaces/adminInterfaces';

import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import styles from './displayContentUpdate.module.css';

interface DisplayContentUpdateInterface {
    content: Contents
    updateContentName: (id: number, contentName: string) => void
    updateContentNote: (id: number, note: string) => void
    totalContentNumber: number
    getSectionsAndContents: () => void
}

const DisplayContentUpdate: React.FC<DisplayContentUpdateInterface> = ({ content, updateContentNote, updateContentName, totalContentNumber, getSectionsAndContents }) => {

    const [displayContent, setDisplayContent] = useState<boolean>(false)
    const [displayContentName, setDisplayContentName] = useState<boolean>(false)
    const [note, setNote] = useState<string>('')
    const [contentName, setContentName] = useState<string>('')
    const [isTextVisible, setIsTextVisible] = useState<boolean>(false)
    const [displayContentOrder, setDisplayContentOrder] = useState<boolean>(false);
    const [contentOrder, setContentOrder] = useState<number>(content.contentOrder)

    const slipButton = () => {
        if (isTextVisible) {
            // console.log("delete", content.id)
            adminService.deleteContent(content.id).then((result) => {
                alertNotification('success', 'Basarıyla Silindi')
            }).catch((err) => {
                alertNotification('error', err.status.message)
            });
        }
        setIsTextVisible(!isTextVisible);
    }

    const updateContentOrder = () => {
        adminService.updateContentOrder({ contentId: content.id, contentOrder })
            .then((res) => {
                getSectionsAndContents()
                setDisplayContentOrder(false);
                setContentOrder(content.contentOrder)
            })
            .catch((err) => alertNotification('error', err.status.message))
    }

    const updateContentDisplay = () => {
        setDisplayContent(!displayContent)
    }

    const updateContentOrderDisplay = () => {
        setDisplayContentOrder(!displayContentOrder);
    }

    const options = Array.from(Array(totalContentNumber), (_, index) => index + 1).map((number) => { return { value: number, label: number } })

    return (
        <>
            <p onClick={() => setDisplayContentName(!displayContentName)}>CONTENT NAME: {content.contentName} <EditOutlined /></p>
            <div
                className={styles.updateCollapse}
            >
                {

                    displayContentName ?
                        (
                            <div>
                                <TextArea
                                    name="update"
                                    maxLength={500}
                                    defaultValue={content.contentName}
                                    value={contentName}
                                    onChange={(e) => setContentName(e.target.value)}
                                />
                                <Button
                                    onClick={() => updateContentName(content.id, contentName.trim())}
                                >
                                    UPDATE
                                </Button>
                            </div>
                        ) :
                        null
                }
            </div>
            <p>CONTENT NOTES: {content.notes}</p>
            <Button
                onClick={() => updateContentDisplay()}
            >

                {
                    displayContent ?
                        <span>
                            CLOSE NOTE
                            <UpOutlined />
                        </span> :
                        <span>
                            UPDATE NOTE
                            <DownOutlined />
                        </span>
                }

            </Button>
            {isTextVisible ? <span className={styles.deleteText}>Are you sure you want to delete?</span> : null}
            <Button className={isTextVisible ? styles.deleteIsOn : styles.deleteButton} onClick={slipButton} type="primary" danger>DELETE</Button>
            <span style={{ marginLeft: '0.5vw' }}>CONTENT ORDER: {content.contentOrder}</span>
            <Button style={{ marginLeft: '0.5vw' }} onClick={updateContentOrderDisplay}>UPDATE ORDER</Button>
            {
                displayContentOrder ?
                    <>
                        <Select
                            options={options}
                            style={{ marginLeft: '0.5vw' }}
                            defaultValue={content.contentOrder}
                            value={contentOrder}
                            onChange={(value) => setContentOrder(value)}
                        />
                        <Button onClick={updateContentOrder}>Update</Button>
                    </>
                    :
                    <>
                    </>
            }
            <div
                className={styles.updateCollapse}
            >
                {

                    displayContent ?
                        (
                            <div>
                                <TextArea
                                    name="update"
                                    maxLength={500}
                                    defaultValue={content.notes}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                                <Button
                                    onClick={() => updateContentNote(content.id, note.trim())}
                                >
                                    UPDATE
                                </Button>
                            </div>
                        ) :
                        null
                }
            </div>
        </>
    )
}

export default DisplayContentUpdate