import React, { useEffect, useState } from "react";
import WalletTransactionsTable from "../components/tables/pageTables/WalletTransactionsTable";
import { IWallet } from "../interfaces/adminInterfaces";
import alertNotification from "src/utils/alertNotification";
import adminService from "src/services/adminService";

const dummyData: IWallet[] = [
  {
    walletId: 1,
    fullName: "Murtaza Çalışkan",
    email: "murtaza@test.com",
    amount: 100,
    date: "11-12-2024",
    note: "Note yazmış seller",
    adminNote: null,
    status: "success",
  },
  {
    walletId: 1,
    fullName: "Kamuran Balcı",
    email: "kamuran@test.com",
    amount: 500,
    date: new Date(),
    note: "Note yazmış kamuran",
    adminNote: "Note yazmış admin",
    status: "pending",
  },
  {
    walletId: 1,
    fullName: "Murtaza Çalışkan",
    email: "murtaza@test.com",
    amount: 100,
    date: new Date(),
    note: "Note yazmış seller",
    adminNote: "",
    status: "rejected",
  },
];
const WalletTransactions = () => {
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(40);
  const [data, setData] = useState([]);

  const fetchWalletTransactions = async () => {
    setLoading(true);
    try {
      const res = await adminService.getWalletRequests(current);
      setData(res.data);
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchWalletTransactions();
  }, [current]);
  return (
    <div>
      <WalletTransactionsTable
        current={current}
        setCurrent={setCurrent}
        loading={loading}
        datas={data}
        totalPageCount={totalPageCount}
      />
    </div>
  );
};

export default WalletTransactions;
