import { useContext } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "../pageTables/Tables.module.css";
import AuthContext from "../../../context/AuthContext";

interface DataType {
  id: string;
  packagesTopPreAyIyzProd: string;
  packagesTopPreAyStrSubs: string;
  packagesTopPreAyStrProd: string;
  packagesTopPreYılIyzProd: string;
  packagesTopPreYılStrSubs: string;
  packagesTopPreYılStrProd: string;
}
interface PackagesPremiumLinksTableInterface {
  generateId: Function;
  handleRender: Function;
}
const PackagesPremiumLinksTable: React.FC<
  PackagesPremiumLinksTableInterface
> = ({ generateId, handleRender }) => {
  const { user } = useContext(AuthContext);
  const userId = user?.id;

  const data: DataType[] = [
    {
      id: "id",
      // Topluluk Premium Paket Linkleri
      packagesTopPreAyIyzProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=prod&productId=${generateId(
        3
      )}-3-${generateId(3)}`,
      packagesTopPreAyStrSubs: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=subs&productId=${generateId(
        3
      )}-7-${generateId(3)}`,
      packagesTopPreAyStrProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=prod&productId=${generateId(
        3
      )}-7-${generateId(3)}`,
      packagesTopPreYılIyzProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=prod&productId=${generateId(
        3
      )}-4-${generateId(3)}`,
      packagesTopPreYılStrSubs: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=subs&productId=${generateId(
        3
      )}-8-${generateId(3)}`,
      packagesTopPreYılStrProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=prod&productId=${generateId(
        3
      )}-8-${generateId(3)}`,
    },
  ];

  const packagesPremiumLinksColumns: ColumnsType<DataType> = [
    {
      title: "TOPLULUK PREMIUM",
      key: "toplulukPremium",
      children: [
        {
          title: "AYLIK",
          key: "toplulukPremiumAylık",
          children: [
            {
              title: "STRIPE",
              key: "toplulukPremiumAylıkStripe",
              children: [
                {
                  title: "SUBS",
                  render: (text) => handleRender(text.packagesTopPreAyStrSubs),
                  key: "toplulukPremiumAylıkStripeSubs",
                },
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopPreAyStrProd),
                  key: "toplulukPremiumAylıkStripeProd",
                },
              ],
            },
            {
              title: "IYZICO",
              key: "toplulukPremiumAylıkIyzico",
              children: [
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopPreAyIyzProd),
                  key: "toplulukPremiumAylıkIyzicoProd",
                },
              ],
            },
          ],
        },
        {
          title: "YILLIK",
          key: "toplulukPremiumYıllık",
          children: [
            {
              title: "STRIPE",
              key: "toplulukPremiumYıllıkStripe",
              children: [
                {
                  title: "SUBS",
                  render: (text) => handleRender(text.packagesTopPreYılStrSubs),
                  key: "toplulukPremiumYıllıkStripeSubs",
                },
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopPreYılStrProd),
                  key: "toplulukPremiumYıllıkStripeProd",
                },
              ],
            },
            {
              title: "IYZICO",
              key: "toplulukPremiumYıllıkIyzico",
              children: [
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopPreYılIyzProd),
                  key: "toplulukPremiumYıllıkIyzicoProd",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Table
        className={styles.table}
        columns={packagesPremiumLinksColumns}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.id}
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default PackagesPremiumLinksTable;
