import { IUpdateCategoryFormValues } from "../../pages/DeloitteAmazonBsr";
import { Button, Form, FormInstance, Input } from "antd";

export interface IUpdateCategoryFormProps {
    formReference: FormInstance;
    submitForm: (values:IUpdateCategoryFormValues) => void;
}
export default function UpdateCategoryForm({formReference, submitForm}: IUpdateCategoryFormProps) {

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
        <Form 
        form={formReference}
        onFinish={submitForm}
        style={{ width: '80%'}}
        >
            
            <Form.Item
                label="Category Name"
                name="categoryName"
                rules={[{ required: true, message: 'Please input category!' }]}
                >
                    <Input />
            </Form.Item> 
            <Form.Item
                label="Category Id"
                name="categoryId"
                rules={[{ required: true, message: 'Please input category id!' }]}
                >
                <Input type="number"/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">Submit</Button>
            </Form.Item>

        </Form>
    </div>
  )
}
