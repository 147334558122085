import React, { useState, useEffect } from "react";
import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { Providers as DataType } from "../interfaces/adminInterfaces";
import ProvidersTable from "../components/tables/pageTables/ProvidersTable";
import { Button } from "antd";

const ServiceProviders: React.FC = () => {
  // const [error, setError] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false);
  const [providers, setProviders] = useState<DataType[]>([]);
  const [selected, setSelected] = useState<number>(1)
  const [filter, setFilter] = useState<string>('status=&requestStatus=')
  const [pageCount, setPageCount] = useState<number | any>();

  const getProviders = (query: string | '') => {
    setLoading(true);
    AdminServices.getProviders(query)
      .then((res) => {
        setProviders(res.data);
      })
      .catch((err) => {
        alertNotification("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const filter = e.currentTarget.getAttribute('data-value') || ''
    const query = ['all', 'active', 'passive'].includes(filter) ? `status=${filter}&requestStatus=` : `status=&requestStatus=${filter}`
    console.log(query)
    setFilter(query)
  }



  useEffect(() => {
    getProviders(filter);
  }, [current, filter]);

  return (
    <div style={{ width: "100%", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <h2>Hizmet Sağlayıcılar</h2>
      </div>
      <div style={{
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between"
      }}>
        <div>
          <Button data-value={'all'} type={selected === 1 ? 'primary' : undefined} onClick={(e) => {
            setSelected(1)
            handleClick(e)
          }}>All</Button>
          <Button data-value='active' type={selected === 2 ? 'primary' : undefined} onClick={(e) => {
            setSelected(2)
            handleClick(e)
          }}>Active</Button>
          <Button data-value='passive' type={selected === 3 ? 'primary' : undefined} onClick={(e) => {
            setSelected(3)
            handleClick(e)
          }}>Passive</Button>
          <Button data-value='approved' type={selected === 4 ? 'primary' : undefined} onClick={(e) => {
            setSelected(4)
            handleClick(e)
          }}>Approved</Button>
          <Button data-value='requested' type={selected === 5 ? 'primary' : undefined} onClick={(e) => {
            setSelected(5)
            handleClick(e)
          }}>Requested</Button>
          <Button data-value='declined' type={selected === 6 ? 'primary' : undefined} onClick={(e) => {
            setSelected(6)
            handleClick(e)
          }}>Declined</Button>

        </div>
        <div>
          <a href="/create-provider">
            <Button
              style={{
                marginBottom: "20px",
              }}
            >
              Hizmet Sağlayıcı Ekle
            </Button>
          </a>
        </div>
      </div>


      <ProvidersTable
        providers={providers}
        getProviders={getProviders}
        current={current}
        setCurrent={setCurrent}
        totalPageCount={pageCount}
      />
    </div >
  );
};

export default ServiceProviders;
