import { Button, Collapse, Modal } from 'antd';
import React, { ButtonHTMLAttributes, ReactHTMLElement, useState } from 'react';

import { Sections } from '../../interfaces/adminInterfaces';
import AddContentForm from './addContentForm';
import styles from './courseDetailsCollapse.module.css';
import DisplayContentUpdate from './displayContentUpdate';

const { Panel } = Collapse;

interface CourseDetailsCollapseInterface {
    sections: Sections[];
    updateContentNote: (id: number, note: string) => void
    updateContentName: (id: number, contentName: string) => void
    courseLocation: string
    getSectionsAndContents: () => void

}

const CourseDetailsCollapse: React.FC<CourseDetailsCollapseInterface> = ({ sections, updateContentNote, updateContentName, courseLocation, getSectionsAndContents }) => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [openedFormId, setOpenedFormId] = useState(0);
    const showAddContentForm = (e: any) => {

        let buttonId = Number(e.target.id)
        if (e.target.localName === "span") {
            buttonId = Number(e.target.parentElement.id)
        }
        // console.log("wth", buttonId);
        setOpenedFormId(buttonId);
        setIsFormOpen(!isFormOpen);
    };



    return (
        <Collapse style={{ width: '100%' }} >

            {
                sections.length && sections.map((section, index) => {
                    return (
                        <>
                            <Button id={`${section.id}`} className={styles.addContentButton} onClick={showAddContentForm} type="primary">
                                {isFormOpen && openedFormId === section.id ? "x" : "+"}
                            </Button>
                            <Panel header={section.sectionName} key={index}>
                                {isFormOpen && openedFormId === section.id ? <AddContentForm
                                    section={section}
                                    setOpenedFormId={setOpenedFormId}
                                    courseLocation={courseLocation}
                                /> : <>
                                    {
                                        section.contents.length && section.contents.map((content, index) => {
                                            return <div key={index} className={styles.contentDiv}>
                                                <DisplayContentUpdate
                                                    content={content}
                                                    updateContentName={updateContentName}
                                                    updateContentNote={updateContentNote}
                                                    totalContentNumber={section.contents.length}
                                                    getSectionsAndContents={getSectionsAndContents}
                                                />
                                            </div>
                                        })
                                    }
                                </>
                                }
                            </Panel>


                        </>

                    )
                }
                )
            }

        </Collapse>
    )
}
export default CourseDetailsCollapse