import { Tag, Tooltip } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";

import alertNotification from "../utils/alertNotification";
import PackagesStarterLinksTable from "../components/tables/productLinksTable/PackagesStarterLinksTable";
import PackagesPremiumLinksTable from "../components/tables/productLinksTable/PackagesPremiumLinksTables";
import CoursesLinksTable from "../components/tables/productLinksTable/CoursesLinksTables";
import CorporateLinksTable from "../components/tables/productLinksTable/CorparateLinksTables";

const ProductLinks: React.FC = () => {
  const handleRender = (txt: string) => {
    return (
      <>
        <Tag color="#87d068">
          {" "}
          <Tooltip color="volcano" title={txt}>
            <a href={txt} target="_blank" rel="noReferrer">
              {txt?.slice(0, 15)}
            </a>
          </Tooltip>
        </Tag>{" "}
        <CopyToClipboard text={txt}>
          <CopyOutlined
            onClick={() => alertNotification("success", "copied")}
          />
        </CopyToClipboard>
      </>
    );
  };

  // create a 3 char alphaNumeric to use in product id
  function generateId(length: number) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    <>
      <PackagesStarterLinksTable
        generateId={generateId}
        handleRender={handleRender}
      />
      <PackagesPremiumLinksTable
        generateId={generateId}
        handleRender={handleRender}
      />
      <CoursesLinksTable generateId={generateId} handleRender={handleRender} />
      <CorporateLinksTable
        generateId={generateId}
        handleRender={handleRender}
      />
    </>
  );
};
export default ProductLinks;
