import alertNotification from "../../utils/alertNotification";
import { Button, Upload } from "antd"

interface Props {
    actionLink: string
    toolName: string
}

const AeCsvUploadForm: React.FC<Props> = ({ actionLink, toolName }) => {

    return (
        <>

            <Upload
                action={`${process.env.REACT_APP_API_ADMIN_URL_DEV}${actionLink}`}
                accept='.csv'
                withCredentials={true}
                onChange={
                    (info: any) => {
                        if (info.file.status !== 'uploading') {
                            // console.log(info.file, info.fileList);
                        }
                        if (info.file.status === 'done') {
                            alertNotification("success", "Veri girişi başarılı!")
                        } else if (info.file.status === 'error') {
                            alertNotification('error', 'Veri girişinde bir hata oluştu!')
                        }
                    }
                }
            >
                <Button>
                    Click to Upload {toolName} CSV
                </Button>
            </Upload>
        </>
    )
}

export default AeCsvUploadForm