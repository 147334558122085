import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";

import Filter from "../components/filter/Filter";
import InvoicesTable from "../components/tables/pageTables/InvoicesTable";

import { Invoices as DataType } from "../interfaces/adminInterfaces";
import PaymentStatusFilter from "../components/filter/PaymentStatusFilter";

const Invoices: React.FC = () => {
  const [current, setCurrent] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [invoices, setInvoices] = useState<DataType[]>([]);
  const [paymentStatus, setPaymentStatus] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')

  //fetch all users
  const getInvoices = (filterValue: string | undefined, status: string) => {
    setLoading(true);
    AdminServices.getInvoices(current, 20, filterValue, status)
      .then((res) => {
        setInvoices(res.data);
        setTotalPageCount(res.length);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getInvoices(searchTerm, paymentStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);


  return (
    <>
      <Filter
        placeholder={"E-mail, Ad veya Soyad"}
        setCurrent={setCurrent}
        getPageDatas={getInvoices}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        setPaymentStatus={setPaymentStatus}
      />
      <PaymentStatusFilter
        getInvoices={getInvoices}
        setSearchTerm={setSearchTerm}
        setPaymentStatus={setPaymentStatus}
        setCurrent={setCurrent}
      />
      <InvoicesTable
        datas={invoices}
        loading={loading}
        setCurrent={setCurrent}
        current={current}
        totalPageCount={totalPageCount}
      />
    </>
  );
};

export default Invoices;
