import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";

import AuthContext from "../context/AuthContext";
import Loading from "../components/loader/Loading";
import PageNotFound from "../pages/PageNotFound";

const AdminRoutes: React.FC = (props) => {
  const { user, isLoading } = useContext(AuthContext!);

  let location = useLocation();

  if (isLoading) {
    return <Loading />;
  }
  if (!user) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }

  // children.auth = ["admin", "crm"]
  // if (children.auth.inclues(user.role)) {
  // return (
  //   <MainLayout>
  //     <PageNotFound />
  //   </MainLayout>
  // );
  //}

  if (
    user?.roleCode !== "mıbB043" &&
    user?.roleCode !== "msvwerv02" &&
    user?.roleCode !== "po6coorox" &&
    user?.roleCode !== "pt04n1dT"
  ) {
    return (
      <MainLayout>
        <PageNotFound />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};
export default AdminRoutes;
