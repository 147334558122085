import React, { useEffect, useState } from "react";
import adminService from "../services/adminService";
import { Form, Input, Button, Row, Checkbox, DatePicker, Select } from "antd";
import styles from "../components/filter/Filter.module.css";
import alertNotification from "../utils/alertNotification";
import GiftMembersTable from "../components/tables/pageTables/GiftMembersTable";
const { Option } = Select;

export interface GiftPayload {
  email: string;
  courseIds?: number[];
  isLifeTime?: boolean;
  startDate?: Date;
  endDate?: Date;
  addDiscordRole?: boolean;
  isRexven?: boolean;
  description?: string | null;
  source?: string | null;
  amount?: number | null;
  isGift?: boolean;
}

const GiftMembers = () => {
  const [current, setCurrent] = useState(1);
  const [email, setEmail] = useState("");
  const [datas, setDatas] = useState<any | null>();
  const [courses, setCourses] = useState<{ label: string; value: number }[]>(
    []
  );
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [formFilter] = Form.useForm();
  const [form] = Form.useForm();

  const getGifteds = async () => {
    try {
      const res = await adminService.getGiftMembers(current, email);

      formFilter.resetFields();
      const temp = res?.data.map((item) => {
        return {
          firstName: item.user.firstName,
          lastName: item.user.lastName,
          id: item.id,
          userId: item.userId,
          startDate: item.startDate,
          endDate: item.endDate,
          isLifeTime: item.isLifeTime === true ? "Evet" : "Hayır",
          email: item.user.email,
          isActive: item.isActive === true ? "Aktif" : "Pasif",
        };
      });
      setDatas(temp);
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const onFinish = (values: { email: string }) => {
    setEmail(values.email);
  };

  const cancelGift = async (id: number) => {
    try {
      await adminService.cancelGift(id);
      await getGifteds();
      alertNotification("success", "Güncelleme başarılı!");
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const createGift = async (values: GiftPayload) => {
    try {
      // console.log({ values });
      if (values.description === "" || values.description === undefined) {
        alertNotification("error", "Açıklama alanı boş bırakılamaz!");
        return;
      }
      await adminService.createGift(values);
      alertNotification('success', 'Hediye abonelik başarıyla tanımlandı.')
      await getGifteds();
    } catch (error: any) {
      alertNotification("error", error?.response?.data?.message!);
    }
  };

  const getServiceProviderSubs = async () => {
    adminService.getServiceProviderSubs().then((res) => {
      let serviceOptions: any = [];
      res.data.map((item: any) => {
        serviceOptions.push({ label: item.company, value: item.id });
      });
      setOptions(serviceOptions);
    });
  };
  const getCourses = (filterValue: string | undefined) => {
    adminService
      .getCourses(current, 10, filterValue)
      .then((res) => {
        const temp = res.data.db.map((item) => {
          return { label: item.courseName, value: item.id };
        });

        setCourses(temp);
      })
      .catch((err) => {
        alertNotification("error", "Bir hata oluştu");
      });
  };
  useEffect(() => {
    getGifteds();
    getCourses("");
    getServiceProviderSubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, email]);

  return (
    <div style={{ width: "100%" }}>
      {/* Create Gift */}
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
        Hediye Abonelik Oluşturmak için Formu Doldurunuz
      </h1>
      <Form
        onFinish={createGift}
        form={form}
        style={{
          backgroundColor: "white",
          padding: "2rem",
          width: "80%",
          margin: "auto",
        }}
        layout="horizontal"
        autoComplete="off"
      >
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>

        <Form.Item label="Kurslar" name="courseIds">
          <Checkbox.Group options={courses} />
        </Form.Item>
        <Row justify="start">
          <Form.Item
            label="Başlangıç tarihi"
            name="startDate"
            style={{ marginRight: "1rem" }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Bitiş tarihi"
            name="endDate"
            style={{ marginRight: "1rem" }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            initialValue={false}
            name="isLifeTime"
            style={{ marginRight: "1rem" }}
          >
            <Checkbox>Lifetime?</Checkbox>
          </Form.Item>
        </Row>
        <Row justify="start">
          <Form.Item
            valuePropName="checked"
            initialValue={false}
            name="addDiscordRole"
            style={{ marginRight: "1rem" }}
          >
            <Checkbox>Discord'a Ekle</Checkbox>
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            initialValue={false}
            name="isRexven"
            style={{ marginRight: "1rem" }}
          >
            <Checkbox>Rexven Üyeliği</Checkbox>
          </Form.Item>
        </Row>
        <Row justify="start">
          <Form.Item
            valuePropName="checked"
            initialValue={false}
            name="isGift"
            style={{ marginRight: "1rem" }}
          >
            <Checkbox>Hediye(Parasız)</Checkbox>
          </Form.Item>
          <Form.Item name="amount" label="Tutar">
            <Input />
          </Form.Item>

          <Form.Item style={{ marginLeft: 40 }} name="source" label="Kaynak">
            <Select style={{ minWidth: "250px" }}>
              {options.map((item: any) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginLeft: 40 }} name="packageName" label="Paket Adı">
            <Select style={{ minWidth: "250px" }}>
              
                <Option value="Elit">Elit</Option>
                <Option value="Rexven Premium">Rexven Premium</Option>
                <Option value="Rexven Plus">Rexven Plus</Option>
              
            </Select>
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            style={{ width: "100%" }}
            name="description"
            label="Açıklama"
          >
            <Input />
          </Form.Item>
        </Row>
        <Button
          className={styles.submitButton}
          type="primary"
          danger
          htmlType="submit"
        >
          Onayla
        </Button>
      </Form>
      {/* SERACH BAR    */}
      <Form
        className={styles.filter}
        onFinish={onFinish}
        form={formFilter}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <div className={styles.inputWrapper}>
            <Form.Item style={{ flex: "1" }} name="email">
              <Input
                className={styles.filterInput}
                placeholder={"Aramak istediğiniz kullanıcının emailini giriniz"}
              />
            </Form.Item>
            <Button
              className={styles.submitButton}
              type="primary"
              danger
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
      <GiftMembersTable
        datas={datas!}
        current={current}
        setCurrent={setCurrent}
        cancelGifted={cancelGift}
      />
    </div>
  );
};

export default GiftMembers;
