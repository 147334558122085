import { Skeleton, Table, Pagination } from "antd";
import type { PaginationProps } from "antd";

import { ErrorLog } from "../../../interfaces/adminInterfaces";

import columnsList from "../ColumnsLists";
import styles from "./Tables.module.css";

interface TableProps {
  datas: ErrorLog[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
}

const ErrorLogsTable: React.FC<TableProps> = ({
  datas,
  loading,
  setCurrent,
  current,
  totalPageCount,
}) => {
  const data: ErrorLog[] = datas;

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Table
        className={styles.table}
        columns={columnsList.errorLogsColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 2000 }}
      />
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={totalPageCount}
      />
    </>
  );
};

export default ErrorLogsTable;
