import { useContext } from "react";
import { Card, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "../pageTables/Tables.module.css";
import AuthContext from "../../../context/AuthContext";

interface DataType {
  id: string;
  packagesTopBasAyIyzSubs: string;
  packagesTopBasAyIyzProd: string;
  packagesTopBasAyStrSubs: string;
  packagesTopBasAyStrProd: string;
  packagesTopBasYılIyzSubs: string;
  packagesTopBasYılIyzProd: string;
  packagesTopBasYılStrSubs: string;
  packagesTopBasYılStrProd: string;
}

interface PackagesStarterLinksTableInterface {
  generateId: Function;
  handleRender: Function;
}
const PackagesStarterLinksTable: React.FC<
  PackagesStarterLinksTableInterface
> = ({ generateId, handleRender }) => {
  const { user } = useContext(AuthContext);
  const userId = user?.id;

  const data: DataType[] = [
    {
      id: "id",
      //Topluluk Başlangıç Paket Linkleri
      packagesTopBasAyIyzSubs: `https://community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=subs&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      packagesTopBasAyIyzProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=prod&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      packagesTopBasAyStrSubs: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=subs&productId=${generateId(
        3
      )}-5-${generateId(3)}`,
      packagesTopBasAyStrProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=prod&productId=${generateId(
        3
      )}-5-${generateId(3)}`,
      packagesTopBasYılIyzSubs: `https://community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=subs&productId=${generateId(
        3
      )}-2-${generateId(3)}`,
      packagesTopBasYılIyzProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=prod&productId=${generateId(
        3
      )}-2-${generateId(3)}`,
      packagesTopBasYılStrSubs: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=subs&productId=${generateId(
        3
      )}-6-${generateId(3)}`,
      packagesTopBasYılStrProd: `https://community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=prod&productId=${generateId(
        3
      )}-6-${generateId(3)}`,
    },
  ];
  const packagesStarterLinksColumns: ColumnsType<DataType> = [
    {
      title: "TOPLULUK BAŞLANGIÇ",
      key: "toplulukBaşlangıç",
      children: [
        {
          title: "AYLIK",
          key: "toplulukBaşlangıçAylık",
          children: [
            {
              title: "STRIPE",
              key: "toplulukBaşlangıçAylıkStripe",
              children: [
                {
                  title: "SUBS",
                  render: (text) => handleRender(text.packagesTopBasAyStrSubs),
                  key: "toplulukBaşlangıçAylıkStripeSubs",
                },
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopBasAyStrProd),
                  key: "toplulukBaşlangıçAylıkStripeProd",
                },
              ],
            },
            {
              title: "IYZICO",
              key: "toplulukBaşlangıçAylıkIyzico",
              children: [
                {
                  title: "SUBS",
                  render: (text) => handleRender(text.packagesTopBasAyIyzSubs),
                  key: "toplulukBaşlangıçAylıkIyzicoSubs",
                },
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopBasAyIyzProd),
                  key: "toplulukBaşlangıçAylıkIyzicoProd",
                },
              ],
            },
          ],
        },
        {
          title: "YILLIK",
          key: "toplulukBaşlangıçYıllık",
          children: [
            {
              title: "STRIPE",
              key: "toplulukBaşlangıçYıllıkStripe",
              children: [
                {
                  title: "SUBS",
                  render: (text) => handleRender(text.packagesTopBasYılStrSubs),
                  key: "toplulukBaşlangıçYıllıkStripeSubs",
                },
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopBasYılStrProd),
                  key: "toplulukBaşlangıçYıllıkStripeProd",
                },
              ],
            },
            {
              title: "IYZICO",
              key: "toplulukBaşlangıçYıllıkIyzico",
              children: [
                {
                  title: "SUBS",
                  render: (text) => handleRender(text.packagesTopBasYılIyzSubs),
                  key: "toplulukBaşlangıçYılıkIyzicoSubs",
                },
                {
                  title: "PROD",
                  render: (text) => handleRender(text.packagesTopBasYılIyzProd),
                  key: "toplulukBaşlangıçYıllıkIyzicoProd",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Card>
        {`Your User Id is`} <Tag color="red"> {userId}</Tag>{" "}
      </Card>
      <Table
        className={styles.table}
        columns={packagesStarterLinksColumns}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.id}
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default PackagesStarterLinksTable;
