import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Dropdown, Form, Input, Menu, MenuProps, Modal, Row, Select, Space, Upload } from 'antd'
import { UploadOutlined, DownOutlined } from '@ant-design/icons';

import { Editor } from '@tinymce/tinymce-react'

import adminService from '../services/adminService'
import alertNotification from '../utils/alertNotification';
import { Option } from 'antd/es/mentions';
import FormItem from 'antd/es/form/FormItem';

interface FormValues {
    title?: string
    content?: string
    category?: any
    blogAuthorId?: any
    readTime?: any
    image?: File | null
    imageTitle?: string
    imageAlt?: string
    metaDescription?: string
    metaKeywords?: string
    metaTitle?: string
    lang?: 'en' | 'tr'
    description?: string
}

interface NewAuthor {
    fullName?: string
    jobTitle?: string
    image?: File | null
}

const CreateBlog: React.FC = () => {
    const [form] = Form.useForm()
    const [formValues, setFormValues] = useState<FormValues>({
        title: "",
        content: "",
        category: [],
        blogAuthorId: "",
        readTime: "",
        image: null,
        metaDescription: "",
        metaKeywords: "",
        metaTitle: "",
        lang: 'tr',
        description: ""
    })

    const [categories, setCategories] = useState<string[]>([])
    const [authors, setAuthors] = useState<{ value: number, label: string }[]>([])
    const [newAuthor, setNewAuthor] = useState<NewAuthor>({
        fullName: "",
        jobTitle: "",
        image: null
    })
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [language, setLanguage] = useState<'tr' | 'en'>('tr')

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        const formData = new FormData()

        formData.append('fullName', newAuthor.fullName!)
        formData.append('jobTitle', newAuthor.jobTitle!)
        formData.append('image', newAuthor.image!)

        adminService.addAuthor(formData).then(() => {
            alertNotification('success', 'Yazar başarıyla eklendi.')
            form.resetFields()
            getAuthors()
        }).catch((e) => {
            alertNotification('error', `Error: ${e}`)
        }).finally(() => setIsModalOpen(false))

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getCategories = async (lang: string) => {
        const res = await adminService.getBlogCategories(lang)
        setCategories(res.data)
    }

    const getAuthors = async () => {
        let options: { value: number, label: string }[] = []
        const res = await adminService.getAuthors()
        res.data.map((obj: { fullName: string, id: number }) => {
            options.push({ label: obj.fullName, value: obj.id })
        })
        setAuthors(options)
    }


    const imageUploadHandler = async (blobInfo?: any): Promise<any> =>
        new Promise(async (resolve, reject) => {
            try {
                const formData = new FormData();
                formData.append('image', blobInfo.blob(), blobInfo.filename());

                const res = await adminService.uploadImageToBlog(formData)
                resolve(res.data)
            } catch (error: any) {
                reject('Error uploading image: ' + error.message);
            }
        })





    const handleFormSubmit = async () => {
        try {
            const formData = new FormData()

            formData.append('title', formValues.title!)
            formData.append('content', formValues.content!)
            formData.append('category', JSON.stringify(formValues.category!))
            formData.append('readTime', formValues.readTime!)
            formData.append('image', formValues.image!)
            formData.append('blogAuthorId', formValues.blogAuthorId)
            formData.append('lang', String(formValues.lang))
            formData.append('description', formValues.description!)

            if (formValues.metaDescription) formData.append('metaDescription', formValues.metaDescription!)
            if (formValues.metaKeywords) formData.append('metaKeywords', formValues.metaKeywords!)
            if (formValues.metaTitle) formData.append('metaTitle', formValues.metaTitle!)
            if (formValues.imageTitle) formData.append('imgTitle', String(formValues.imageTitle))
            if (formValues.imageAlt) formData.append('imgAlt', String(formValues.imageAlt))

            await adminService.addBlogPost(formData)
            alertNotification('success', 'Blog yazısı başarıyla eklendi.')
        } catch (e: any) {
            alertNotification('error', e?.response?.data?.message || 'Blog yazısı eklenirken bir hata oluştu.')
        }

    }

    useEffect(() => {
        getCategories(language)
        getAuthors()
    }, [language])

    return (
        <>
            <Form onFinish={handleFormSubmit} form={form}>
                <Form.Item label='Language'>
                    <Select
                        labelInValue
                        style={{ width: 150 }}
                        placeholder="Select Language"
                        onChange={({ value }) => {
                            setLanguage(value)
                            setFormValues({ ...formValues, lang: value })
                        }}
                    >
                        <Option value="en"  >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/flags/en-rounded.png" alt="en" width="20" style={{ margin: '0 5px' }} />
                                <span style={{ fontWeight: 'bold' }}>EN</span>

                            </div>
                        </Option>
                        <Option value="tr" >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/flags/tr-rounded.png" alt="tr" width="20" style={{ margin: '0 5px' }} />
                                <span style={{ fontWeight: 'bold' }}>TR</span>

                            </div>
                        </Option>

                    </Select>

                </Form.Item>
                <div style={{ display: "flex" }}>
                    <Form.Item label="Authors">
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select Author"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={authors}
                            onChange={(value) => setFormValues({ ...formValues, blogAuthorId: value })}
                        />
                    </Form.Item>
                    <Button onClick={showModal}>Yazar Ekle +</Button>
                    <Modal title="Yazar Ekle" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <Form onFinish={handleOk} form={form}>
                            <Form.Item label='Full Name'>
                                <Input placeholder='Full Name' onChange={(e) => setNewAuthor({ ...newAuthor, fullName: e.target.value })} />
                            </Form.Item>
                            <Form.Item label='Job Title'>
                                <Input placeholder='Job Title' onChange={(e) => setNewAuthor({ ...newAuthor, jobTitle: e.target.value })} />
                            </Form.Item>
                            <Upload
                                beforeUpload={(file) => {
                                    setNewAuthor({ ...newAuthor, image: file })
                                    return false
                                }}
                            >

                                <Button icon={<UploadOutlined />}>
                                    Upload Image
                                </Button>
                            </Upload>
                        </Form>
                    </Modal>
                </div>

                <Form.Item label='Category'>
                    <Checkbox.Group
                        value={formValues.category}
                        onChange={(values) => setFormValues({ ...formValues, category: [...values] })}
                    >
                        {
                            categories.map((el: any) => {
                                let suffix: string = ''
                                if (language === 'en') suffix = 'En'
                                return (
                                    el[`categoryName${suffix}`] !== null && <Checkbox key={el.id} value={el[`categoryName${suffix}`]?.trim()}>{el[`categoryName${suffix}`]}</Checkbox>
                                )
                            })
                        }

                    </Checkbox.Group>
                </Form.Item>
                <Form.Item label='Title'>
                    <Input placeholder='Blog yazısı için başlık giriniz... ' value={formValues.title} onChange={(e) => setFormValues({ ...formValues, title: e.target.value })} />
                </Form.Item>

                <Form.Item label='Description'>
                    <Input placeholder='Blog yazısı için kısa açıklama giriniz... ' value={formValues.description} onChange={(e) => setFormValues({ ...formValues, description: e.target.value })} />
                </Form.Item>

                <Form.Item rules={[{ required: true, message: 'Please input Meta Description!' }]} label='Meta Description'>
                    <Input placeholder='Meta Description giriniz...' value={formValues.metaDescription} onChange={(e) => setFormValues({ ...formValues, metaDescription: e.target.value })} />
                </Form.Item>
                <Form.Item rules={[{ required: true, message: 'Please input Meta Keywords!' }]} label='Meta Keywords'>
                    <Input placeholder='Meta Keywords giriniz...' value={formValues.metaKeywords} onChange={(e) => setFormValues({ ...formValues, metaKeywords: e.target.value })} />
                </Form.Item>
                <Form.Item rules={[{ required: true, message: 'Please input Meta Title!' }]} label='Meta Title'>
                    <Input placeholder='Meta Title giriniz...' value={formValues.metaTitle} onChange={(e) => setFormValues({ ...formValues, metaTitle: e.target.value })} />
                </Form.Item>
                <div>
                    <p>  * Yazının ana başlığını <span style={{ color: 'red' }} >Title</span> bölümüne giriniz. </p>
                    <p> * Alt Başlıklar için <span style={{ color: 'red' }}>Heading 2</span> seçiniz.</p>
                </div>
                <Editor
                    value={formValues.content}
                    init={{
                        height: 500,
                        menubar: 'insert',
                        plugins: [
                            "advlist", "autolink", "lists", "link", "charmap", "print", "preview",
                            "searchreplace", "visualblocks", "code", "fullscreen",
                            "insertdatetime", "table", "paste", "code", "help", "wordcount",
                            "anchor", "image"
                        ],
                        image_title: true,
                        convert_urls: false,
                        toolbar: 'undo redo | formatselect | image | bullist numlist outdent indent | help | blocks fontfamily fontsize | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify lineheight | removeformat | link | anchor',
                        font_family_formats: "Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Rubik=rubik, sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                        content_style: 'body { font-family: Rubik; }',
                        images_upload_handler: imageUploadHandler
                    }}

                    onEditorChange={(value) => setFormValues({ ...formValues, content: value })}
                ></Editor>

                <Form.Item style={{ margin: '10px 0' }} label='Blog Resmi'>
                    <Upload
                        beforeUpload={(file) => {
                            setFormValues({ ...formValues, image: file })
                            return false
                        }}
                    >
                        <Button icon={<UploadOutlined />}>
                            Upload Image
                        </Button>
                    </Upload>
                </Form.Item>
                <FormItem label="Başlık">
                    <Input
                        style={{ width: '50%' }}
                        onChange={(e) => setFormValues({ ...formValues, imageTitle: e.target.value })}
                    />
                </FormItem>
                <FormItem label="Alt Başlık">
                    <Input
                        style={{ width: '50%' }}
                        onChange={(e) => setFormValues({ ...formValues, imageAlt: e.target.value })}

                    />

                </FormItem>


                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Form.Item style={{ width: "150px", margin: "10px 0" }} label="Read Time">
                        <Input

                            onChange={(e) => setFormValues({ ...formValues, readTime: e.target.value })}
                            value={formValues.readTime}
                            placeholder='min.' />
                    </Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                    > Submit</Button>
                </div>


            </Form>
        </>
    )
}

export default CreateBlog;
