import React, { useRef, useState } from "react";
import { Form, Upload, Input, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";

interface FormValues {
  company?: string;
  subStart?: any;
  subEnd?: any;
  subsType?: string;
  amount?: number;
  status?: "active" | "passive";
  currency?: string;
  paymentChannel?: string;
  description?: string;
  payments?: Payment[];
  billingInfo?: any;
  email?: string;
}

interface Payment {
  amount: number;
  currency: string;
  paymentChannel: string;
  description: string;
  paymentDate: Date;
}

const ProviderSubsCreate: React.FC = () => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<FormValues>({
    company: "",
    subStart: null,
    subEnd: null,
    subsType: "",
    amount: 0,
    status: "active",
    currency: "",
    paymentChannel: "",
    description: "",
    payments: [],
    email: "",
    billingInfo: {},
  });
  const [disable, setDisable] = useState(false);
  const [billingInfo, setBillingInfo] = useState({
    title: "Provider",
    isDefault: true,
    firstName: "",
    lastName: "",
    gsmNumber: "",
    identityNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    zipCode: "",
    isCorporate: true,
    vatNumber: "",
    companyName: "",
    taxOffice: "",
  });
  const handleFormSubmit = () => {
    let formValuesCopy: any = { ...formValues };
    let billingInfoCopy: any = billingInfo;
    billingInfoCopy.companyName = formValues.company;
    formValuesCopy.billingInfo = billingInfoCopy;

    // check user fill all fields
    for (let key in formValuesCopy) {
      if (formValuesCopy[key] === null || formValuesCopy[key] === "") {
        alertNotification(
          "error",
          "Lütfen tüm alanları doldurunuz" + formValuesCopy[key] + "--" + key
        );

        return;
      }
    }

    // check user billing fill all fields
    for (let key in billingInfo) {
      if (billingInfoCopy[key] === null || billingInfoCopy[key] === "") {
        alertNotification(
          "error",
          "Lütfen tüm alanları doldurunuz" + billingInfoCopy[key] + "--" + key
        );

        return;
      }
    }
    setDisable(true);
    adminService
      .createServiceProviderSub(formValuesCopy)
      .then((res) => {
        alertNotification("success", "Başarıyla Eklendi");
        form.resetFields();
      })
      .catch((err) => {
        // console.log(err);
        alertNotification("error", err.status.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ marginBottom: "20px" }}>
        Hizmet Sağlayıcı Abonelik Ekleme Sayfası
      </h2>

      <Form
        style={{ width: "80%" }}
        onFinish={handleFormSubmit}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item label="Provider Name" name="name">
          <Input
            value={formValues.company}
            onChange={(e) =>
              setFormValues({ ...formValues, company: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input
            value={formValues.email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Subs Start" name="subStart">
          <Input
            type="date"
            value={formValues.subStart}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                subStart: new Date(e.target.value),
              })
            }
          />
        </Form.Item>
        <Form.Item label="Subs End" name="subEnd">
          <Input
            type="date"
            value={formValues.subEnd}
            onChange={(e) =>
              setFormValues({ ...formValues, subEnd: new Date(e.target.value) })
            }
          />
        </Form.Item>
        <Form.Item label="Subs Type" name="subsType">
          <Input
            value={formValues.subsType}
            onChange={(e) =>
              setFormValues({ ...formValues, subsType: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Amount" name="amount">
          <Input
            value={formValues.amount}
            onChange={(e) =>
              setFormValues({ ...formValues, amount: Number(e.target.value) })
            }
          />
        </Form.Item>
        <Form.Item label="Currency" name="currency">
          <Input
            value={formValues.currency}
            onChange={(e) =>
              setFormValues({ ...formValues, currency: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Payment Channel" name="paymentChannel">
          <Input
            value={formValues.paymentChannel}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                paymentChannel: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input
            value={formValues.description}
            onChange={(e) =>
              setFormValues({ ...formValues, description: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Title" name="title">
          <Input
            value={billingInfo.title}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                title: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing First Name" name="firstName">
          <Input
            value={billingInfo.firstName}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                firstName: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Last Name" name="lastName">
          <Input
            value={billingInfo.lastName}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                lastName: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Gsm Number" name="gsmNumber">
          <Input
            value={billingInfo.gsmNumber}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                gsmNumber: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Identity Number" name="identityNumber">
          <Input
            value={billingInfo.identityNumber}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                identityNumber: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Address Line 1" name="addressLine1">
          <Input
            value={billingInfo.addressLine1}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                addressLine1: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Address Line 2" name="addressLine2">
          <Input
            value={billingInfo.addressLine2}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                addressLine2: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing City" name="city">
          <Input
            value={billingInfo.city}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                city: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Country" name="country">
          <Input
            value={billingInfo.country}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                country: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Zip Code" name="zipCode">
          <Input
            value={billingInfo.zipCode}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                zipCode: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Tax Number" name="taxNumber">
          <Input
            value={billingInfo.vatNumber}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                vatNumber: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label="Billing Tax Office" name="taxOffice">
          <Input
            value={billingInfo.taxOffice}
            onChange={(e) =>
              setBillingInfo({
                ...billingInfo,
                taxOffice: e.target.value,
              })
            }
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
          <Button
            disabled={disable}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProviderSubsCreate;
