import { Button, Card, Skeleton, Table, Tag, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import { UserPackage } from "../../../interfaces/adminInterfaces";

import styles from "../pageTables/Tables.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import alertNotification from "../../../utils/alertNotification";

interface TableProps {
  datas: UserPackage[];
  loading: boolean;
  cancelSubscription: Function;
  updateEndDateOfRefundedSubscriptions: Function;
}

const DetailedUserPackagesTable: React.FC<TableProps> = ({
  datas,
  loading,
  cancelSubscription,
  updateEndDateOfRefundedSubscriptions
}) => {
  const data: UserPackage[] = datas;
  const packageColumns = [
    {
      title: "TRIAL START",
      render: (record: any) => {
        return (
          record?.trialStart &&
          new Date(record?.trialStart)?.toLocaleString("tr-TR")
        );
      },
      key: "trialStart",
      width: "9%",
    },
    {
      title: "TRIAL END",
      render: (record: any) => {
        return (
          record?.trialEnd &&
          new Date(record?.trialEnd)?.toLocaleString("tr-TR")
        );
      },
      key: "trialEnd",
      width: "9%",
    },
    {
      title: "START DATE",
      render: (record: any) => {
        return (
          record?.startDate &&
          new Date(record?.startDate)?.toLocaleString("tr-TR")
        );
      },
      key: "startDate",
      width: "9%",
    },
    {
      title: "CANCEL DATE",
      render: (record: any) => {
        if (record.cancelDate) {
          return new Date(record?.cancelDate)?.toLocaleString("tr-TR");
        } else if (!record.cancelDate && record.isSubscription === true) {
          return (
            <Button id={record.id} danger>
              <span id={record.id}>CANCEL</span>
            </Button>
          );
        }
      },
      key: "cancelDate",
      width: "9%",
    },
    {
      title: "END DATE",
      render: (record: any) => {
        return (
          record?.endDate && new Date(record?.endDate)?.toLocaleString("tr-TR")
        );
      },
      key: "endDate",
      width: "9%",
    },
    {
      title: "REFUNDED",
      render: (record: any) => {
        if (!record.cancelDate) {
          <p>Paket iptal edilmemiş</p>
        } else if (record.cancelDate && record.isSubscription === true) {
          return (
            <Button className={record.id} danger>
              <span className={record.id}>REFUNDED</span>
            </Button>
          );
        }
      },
      key: "refund",
      width: "9%",
    },
    {
      title: "SUBSCRIBE",
      render: (record: any) => {
        return record.isSubscription === true ? (
          <Tag color="#87d068">SUBSC</Tag>
        ) : (
          <Tag color="#2db7f5"> PRODS</Tag>
        );
      },
      key: "isSubscription",
      width: "7%",
    },
    {
      title: "PLATFORM",
      render: (record: any) => {
        return record.platform === "iyzico" ? (
          <Tag color="#f50"> {record.platform}</Tag>
        ) : (
          record.platform
        );
      },
      key: "platform",
      width: "7%",
    },
    {
      title: "DISCORD ID",
      render: (record: any) => {
        return <Tag color="#87d068">{record.discordUserId}</Tag>;
      },
      key: "discordUserId",
      width: "11%",
    },
    {
      title: "DISCORD LINK",
      render: (record: any) => {
        if (record?.discordInviteLink)
          return (
            <>
              <Tag color="#87d068">{record.discordInviteLink}</Tag>{" "}
              <CopyToClipboard
                text={`https://discordapp.com/invite/${record.discordInviteLink}`}
              >
                <CopyOutlined
                  onClick={() => alertNotification("success", "copied")}
                />
              </CopyToClipboard>
            </>
          );
      },
      key: "discordInviteLink",
      width: "9%",
    },
    {
      title: "remarks",
      render: (record: any) => {
        return record.remarks !== "none" ? (
          <Tooltip color="volcano" title={record?.remarks}>
            <Tag color={"#f50"}>{record?.remarks?.slice(0, 25)}</Tag>{" "}
          </Tooltip>
        ) : (
          ""
        );
      },
      key: "remarks",
      width: "9%",
    },
    {
      title: "PLAN",
      render: (record: any) => {
        return record?.packagePlan?.duration === 1 ? (
          <Tag color="#f50">{record?.packagePlan?.package?.name} AYLIK</Tag>
        ) : (
          <Tag color="#87d068">{record?.packagePlan?.package?.name} YILIK</Tag>
        );
      },
      width: "11%",
    },
  ];
  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Card>
        {" "}
        <Tag color={"red"}> PACKAGES</Tag>
      </Card>
      <Table
        className={styles.table}
        columns={packageColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const id = +(event.target as HTMLElement).id;
              const className = +(event.target as HTMLElement).className;
              // eslint-disable-next-line no-restricted-globals
              if (id === record.id && confirm("Sure to CANCEL?")) {
                cancelSubscription(record.id);
              // eslint-disable-next-line no-restricted-globals
              }else if(className === record.id && confirm("Paket iptal tarihi sıfırlansın mı?")){
                updateEndDateOfRefundedSubscriptions(record.id)
              }
            },
          };
        }}
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default DetailedUserPackagesTable;
