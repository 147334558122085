import React, { useContext } from "react";

import AuthContext from "../context/AuthContext";

import Loading from "../components/loader/Loading";
import LoginForm from "../components/loginForm/LoginForm";

const Login: React.FC = () => {
  const { loginLoading } = useContext(AuthContext);

  return <>{loginLoading ? <Loading /> : <LoginForm />}</>;
};

export default Login;
