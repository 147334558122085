import { Button, Checkbox, DatePicker, Form, Input, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { BillingInfo, Package, PaymentMethod, PaymentOptions } from './BirFaturaModal';
import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import styles from "./BirFaturaModal.module.css"
import dayjs from 'dayjs';
import moment from 'moment'
interface ModalProps {
    getInvoices: any;
    isModalOpen: boolean;
    handleOk: any;
    handleCancel: any
    selectedEmail: string
    invoice: InvoiceModal | null
}

export interface InvoiceModal {
    id: number,
    email: string,
    billingDate: string,
    billingInfo: {
        taxNo: string
        taxNumber: string
        taxOffice: string
        billingCity: string
        billingName: string
        isCorporate: boolean
        billingAddress: string
        identityNumber: string
        billingMobilePhone: string
        companyName: string
    },
    paymentTypeId: number,
    paymentMethod: string,
    basePrice: string,
    paidPrice: string,
    currency: string,
    stockCode: number,
    packagePlanId: number,
    duration: number,
    packageName: number
    source: string
}
const BirFaturaUpdateModal: React.FC<ModalProps> = ({ getInvoices, isModalOpen, handleCancel, handleOk, selectedEmail, invoice }) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
    const [paymentOptions, setPaymentOptions] = useState<PaymentOptions[]>();
    const [packages, setPackages] = useState<Package[]>();
    const [packageOptions, setpackageOptions] = useState()

    const [form] = Form.useForm();

    const getPaymentMethods = async () => {
        try {

            const res = await adminService.getPaymentMethhods();

            const options = res.PaymentMethods.map((item: { Value: any; Id: any; }) => {
                return { label: item.Value, value: item.Id }
            })
            setPaymentMethods(res.PaymentMethods)
            setPaymentOptions(options)

        } catch (error) {
            alertNotification("error", "Ödeme Yöntemleri Getirilemedi")
        }
    }

    const getPackages = async () => {
        try {
            const res = await adminService.getBirFaturaPackages();

            let temp = res.data.map((item: {
                price: any; baseprice: any; packagename: any; id: any;
            }) => {
                return { key: item.id, label: `${item.packagename} (${item.price})`, value: item.id }
            })
            setpackageOptions(temp)
            setPackages(res.data)
        } catch (error) {
            alertNotification("error", "Paketler getirilemedi")
        }
    }

    const handleSubmit = async (values: any) => {

        let paymentInfo
        let packageInfo
        if (paymentMethods) {

            paymentInfo = paymentOptions?.find((item: { value: number }) => {
                return item.value === values.paymentTypeId
            })

        }
        if (packages) {
            packageInfo = packages.find((item: { id: number }) => {
                return item.id === values.packagePlanId
            })
        }


        try {
            let temp = {
                email: values.email,
                billingDate: values.billingDate,
                billingInfo: {
                    billingName: values.isCorporate ? values.companyName : `${values.firstName} ${values.lastName}`,
                    billingAddress: values.address,
                    billingCity: values.city ? values.city : " ",
                    billingMobilePhone: values.phone,
                    isCorpoarate: values.isCorporate,
                    identityNumber: values.identityNumber,
                    taxOffice: values.taxOffice ? values.taxOffice : " ",
                    taxNumber: values.taxNumber ? values.taxNumber : " ",
                    companyName: values.companyName ? values.companyName : " "
                },
                paymentTypeId: paymentInfo?.value,
                paymentMethod: paymentInfo?.label,
                basePrice: packageInfo?.baseprice,
                paidPrice: packageInfo?.price,
                stockCode: packageInfo?.stock_code,
                currency: packageInfo?.currency,
                packagePlanId: values.packagePlanId,
                duration: packageInfo?.duration,
                packageName: packageInfo?.packagename,
                source: values.source
            }

            await adminService.updateBirFaturaInvoice(values.id, temp)
            await getInvoices()
            alertNotification('success', 'Başarıyla güncellendi.')

        } catch (error) {
            alertNotification("error",)
        }
    }

    useEffect(() => {

        getPaymentMethods()
        getPackages()

    }, [selectedEmail])

    return (
        <Modal width="70%" bodyStyle={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }} title="Fatura Oluştur/Düzelt" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18, offset: 1 }} onFinish={handleSubmit}
                initialValues={{
                    firstName: invoice?.billingInfo.billingName.split(' ')[0],
                    lastName: invoice?.billingInfo.billingName.split(' ')[1],
                    address: invoice?.billingInfo.billingAddress,
                    city: invoice?.billingInfo.billingCity,
                    phone: invoice?.billingInfo.billingMobilePhone,
                    isCorporate: invoice?.billingInfo.isCorporate,
                    identityNumber: invoice?.billingInfo.identityNumber,
                    companyName: invoice?.billingInfo.companyName,
                    taxOffice: invoice?.billingInfo.taxOffice,
                    taxNumber: invoice?.billingInfo.taxNumber || invoice?.billingInfo.taxNo,
                    billingDate: moment(invoice?.billingDate, moment.ISO_8601),
                    paymentTypeId: invoice?.paymentTypeId,
                    packagePlanId: invoice?.packagePlanId,
                    source: invoice?.source,
                    id: invoice?.id.toString(),
                    email: invoice?.email
                }}
            >
                <>
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Ad" name="firstName">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Soyad" name="lastName">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Adres" name="address">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Şehir" name="city">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Telefon" name="phone">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Kurumsal Fatura?" name="isCorporate" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Kimlik No" name="identityNumber">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Şirket İsmi" name="companyName">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Vergi Dairesi" name="taxOffice">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Vergi Numarası" name="taxNumber">
                        <Input />
                    </Form.Item>
                </>

                <Form.Item label="Tarih" name="billingDate">
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Ödeme Yöntemi" name="paymentTypeId">
                    <Select

                        options={paymentOptions}
                        defaultValue="Banka EFT-Havale"
                    />
                </Form.Item>
                <Form.Item name="packagePlanId" label="Paket">
                    <Select
                        options={packageOptions}

                    />
                </Form.Item>

                <Form.Item label="Kaynak" name="source">
                    <Select

                        options={[
                            { label: "Subs-Iyzico", value: "Subs-Iyzico" },
                            { label: "Service-Provider-Iyzico", value: "Service-Provider-Iyzico" },
                            { label: "Service-Provider-Manuel", value: "Service-Provider-Manuel" },
                        ]}

                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                        Güncelle
                    </Button>
                </Form.Item>
            </Form>

            {/* <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18, offset: 1 }} onFinish={handleSubmit} initialValues={{ billingDate: dayjs(invoice?.billingDate), paymentTypeId: invoice?.paymentTypeId, packagePlanId: invoice?.packagePlanId, source: invoice?.source, id: invoice?.id }}>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>


                <Form.Item label="Tarih" name="billingDate">
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Ödeme Yöntemi" name="paymentTypeId">
                    <Select

                        options={paymentOptions}

                    />
                </Form.Item>
                <Form.Item name="packagePlanId" label="Paket">
                    <Select

                        options={packageOptions}

                    />
                </Form.Item>

                <Form.Item label="Kaynak" name="source">
                    <Select

                        options={[
                            { label: "Subs-Iyzico", value: "Subs-Iyzico" },
                            { label: "Service-Provider-Iyzico", value: "Service-Provider-Iyzico" },
                            { label: "Service-Provider-Manuel", value: "Service-Provider-Manuel" },
                        ]}

                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                        Oluştur
                    </Button>
                </Form.Item>
            </Form> */}
        </Modal>
    )
}

export default BirFaturaUpdateModal