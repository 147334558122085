import React from 'react'
import type { ColumnsType } from 'antd/es/table';
import { Table, Pagination, Space, Button } from 'antd';
import type { PaginationProps } from "antd";
import { Question } from '@src/interfaces/adminInterfaces';
import { Link } from 'react-router-dom';
import adminService from '../../../services/adminService';
import alertNotification from '../../../utils/alertNotification';


interface TableProps {
  datas: any[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
  getAICats: (level: string) => void
}


const AICatTable: React.FC<TableProps> = ({ setCurrent, current, totalPageCount, datas, getAICats }) => {
  // console.log({totalPageCount})

  const deleteAICat = async (id: number) => {

    try {

      await adminService.deleteAICat(id)
      getAICats("all")

    } catch (error) {
      alertNotification("error", "Bir hata oluştu!")
    }

  }
  const columns: ColumnsType<any> = [

    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Parent Category",
      dataIndex: "parentCategory",
      key: "parentCategory",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Data Update",
      render: (_, record) => (
        <Space size="middle">
          {record.dataId !== 0 ? <Link to={`/ai-data/${record.dataId}`}>Data Edit</Link> : "Data Yok"}
        </Space>
      ),
      key: "UpdateDelete",
    },
    {
      title: "Update",
      render: (_, record) => (
        <Space size="middle">
          {<Link to={`/ai-cats/${record.id}`}>Edit</Link>}
        </Space>
      ),
      key: "UpdateDelete",
    },

    {
      title: "Delete",
      render: (_, record) => (
        <Space size="middle">
          {<Button style={{ backgroundColor: "red", color: "white" }} onClick={() => deleteAICat(record.id)}>Delete</Button>}
        </Space>
      ),
      key: "delete",
    },

  ];
  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };
  return (
    <div>
      <Table dataSource={datas} pagination={false} columns={columns} />

      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        defaultCurrent={1}
        total={20}
        style={{ marginTop: "1rem" }}
      />
    </div>
  )
}

export default AICatTable