import { Link } from "react-router-dom";
import { Button, Image } from "antd";

import notFound from "../components/assets/error404.png";
import styles from "../styles/PageNotFound.module.css";

const PageNotFound: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Image preview={false} src={notFound} alt="notFound" />
        <p className={styles.text}>Oops!</p>
        <p className={styles.text}>Sorry , page not found! </p>
        <Button danger>
          <Link style={{ color: "red" }} to="/">
            Go Home
          </Link>
        </Button>
      </div>
    </div>
  );
};
export default PageNotFound;
