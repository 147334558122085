import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row } from "antd";
import TestAiQuestionAnswer from "../components/ai/TestAiQuestionAnswer";


const TestAI: React.FC = () => {



  return (
    <>

        <TestAiQuestionAnswer />
        
    </>
  );
};

export default TestAI;
