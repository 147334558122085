import React, { useState } from "react";
import { Button, Card, Form, Input, Select, Spin, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";


interface IFormValues {
    title: string;
    description: string;
    image: File | null;
    type: string;
}


export default function CreateAnnouncement() {
    // const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<IFormValues>({
        title: '',
        description: '',
        image: null,
        type: ''
    });

    const { Option } = Select;
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const onFinish = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('title', formValues.title);
        formData.append('description', formValues.description);
        formData.append('type', formValues.type);
        formData.append('image', formValues.image as File)

        adminService.createAnnouncement(formData)
            .then((res) => {
                alertNotification("success", "Duyuru Başarıyla Eklendi");
                form.resetFields();
            })
            .catch((err) => {
                // setError(true);
                alertNotification("error", err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <div>
            <Card
                title="CREATE ANNOUNCEMENT"
                bordered={false}
                style={{ width: "60%", borderRadius: "10px" }}
            >
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 15 }}
                    style={{ display: "flex", flexDirection: "column" }}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="type"
                        label="DUYURU TİPİ"
                        rules={[{ required: true }]}
                    >
                        <Select
                            onChange={(e) => setFormValues({ ...formValues, type: e })}
                            placeholder="DUYURU TİPİ SEÇİNİZ"
                        >
                            <Option value="rexven">REXVEN</Option>
                            <Option value="serviceProvider">HİZMET SAĞLAYICI</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="title"
                        label="BAŞLIK"
                        rules={[{ required: true }]}
                    >
                        <Input
                            type={"text"}
                            placeholder="BAŞLIK"
                            value={formValues.title}
                            onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                        />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="AÇIKLAMA"
                        rules={[{ required: true }]}
                    >
                        <TextArea
                            rows={6}
                            placeholder="AÇIKLAMA"
                            value={formValues.description}
                            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                        />
                    </Form.Item>

                    <Form.Item label="FOTOĞRAF" name="image" style={{ marginTop: '20px' }}>
                        <Upload
                            beforeUpload={(file) => {
                                setFormValues({ ...formValues, image: file });
                                return false;
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Fotoğraf Yükle</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 3, span: 5 }} style={{ marginTop: '20px' }}>
                        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                            {loading ? <Spin></Spin> : "Submit"}
                        </Button>
                    </Form.Item>

                </Form>
            </Card>
        </div>
    )
}
