import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";

import Filter from "../components/filter/Filter";
import ErrorLogsTable from "../components/tables/pageTables/ErrorLogsTable";

import { ErrorLog } from "../interfaces/adminInterfaces";

const ErrorLogs: React.FC = () => {
  const [current, setCurrent] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [errorLogs, setErrorLogs] = useState<ErrorLog[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('')

  //fetch all users
  const getErrorLogs = (filterValue: string | undefined) => {
    setLoading(true);
    AdminServices.getErrorLogs(current, 20, filterValue)
      .then((res) => {
        // console.log(res)
        setErrorLogs(res.data);
        setTotalPageCount(res.length);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getErrorLogs(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
      <Filter
        placeholder={"Location, Message ya da Stack"}
        setCurrent={setCurrent}
        getPageDatas={getErrorLogs}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <ErrorLogsTable
        datas={errorLogs}
        loading={loading}
        setCurrent={setCurrent}
        current={current}
        totalPageCount={totalPageCount}
      />
    </>
  );
};

export default ErrorLogs;
