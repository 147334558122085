import { Image } from 'antd'
import AeCsvUploadForm from '../components/aliExpressForms/AeCsvUploadForm'
import aeKeywordExcel from '../components/assets/aeKeywordExcel.png'
import aeMostSaledExcel from '../components/assets/aeMostSaledExcel.png'


const AliExpressExcel: React.FC = () => {


    return (
        <>
            <h1>Ali Express Çok Aranan Kelimeler</h1>
            <Image
                src={aeKeywordExcel}
                style={{ marginBottom: '1vw' }}
            />
            <p
                style={{ color: 'red' }}
            >Ali Express Çok Aranan Kelimeler aracında girilecek dosya uzantısı .csv olacaktır. Csv uzantılı dosyanın başlıkları resimde gözüktüğü gibi olmalıdır. Lütfen dosya girişi yaparken bu kriterlere dikkat ediniz</p>
            <AeCsvUploadForm
                actionLink='/aliexpress-keyword-excel-upload'
                toolName='En Çok Aranan Kelimeler'
            />

            <div style={{ marginTop: '2vw' }}>
                <h1>Ali Express Çok Satan Ürünler</h1>
                <Image
                    src={aeMostSaledExcel}
                    style={{ marginBottom: '1vw' }}
                />
                <p
                    style={{ color: 'red' }}
                >Ali Express Çok Satan Ürünler aracında girilecek dosyaların uzantısı .csv olacaktır. Csv uzantılı dosyanın başlıkları resimde gözüktüğü gibi olmalıdır. Lütfen dosya girişi yaparken bu kriterlere dikkat ediniz. top_1000_by_gmv isimli csv dosyası bu alandan girilecektir.</p>
                <AeCsvUploadForm
                    actionLink='/aliexpress-most-sold-excel-upload'
                    toolName='En Çok Satan Ürünler top_1000_by_gmv'
                />

            </div>


        </>
    )
}

export default AliExpressExcel