import { Button, Form, Modal, Table } from "antd";
import { IDeloitteAmazonCategoryBsrIntervals } from "../interfaces/adminInterfaces";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import AddBsrForm from "../components/deloitteBsr/addBsrForm";
import UpdateBsrForm from "../components/deloitteBsr/updateBsrForm";

export interface IDeloitteCreateBsrObject {
    bsrRank: number;
    sales: number;
}

interface IDeloitteBsrObject extends IDeloitteCreateBsrObject {
    id: number;
}

function DeloitteBsrDetail() {
    const columns = [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "BSR Rank",
          dataIndex: "bsrRank",
          key: "bsrRank",
        },
        {
          title: "Satış Sayısı",
          dataIndex: "sales",
          key: "sales",
        },
        {
          title: "Sil",
          render: (record: any) => {
            return <Button style={{ border: "1px solid red", color: "red" }}
            onClick={() => openDeleteBsrModal(record.id)}
            >Sil</Button>;
          },
          key: "sil",
    
        },
        {
          title: "Düzenle",
          render: (record: any) => {
            return <Button type='primary' onClick={() => openUpdateBsrModal(record)}>Düzenle</Button>;
          },
          key: "addressLine",
    
        },
      ];
      
    const { categoryId } = useParams<{ categoryId: string }>();
    const [bsrIntervals, setBsrIntervals] = useState<IDeloitteAmazonCategoryBsrIntervals[]>([])
    const [bsrDeleteModal, setBsrDeleteModal] = useState<boolean>(false);
    const [bsrCreateModal, setBsrCreateModal] = useState<boolean>(false);
    const [bsrUpdateModal, setBsrUpdateModal] = useState<boolean>(false);
    const [bsrIdToDelete, setBsrIdToDelete] = useState<number | null>(null);
    const [bsrIdToUpdate, setBsrIdToUpdate] = useState<number | null>(null);

    const [createBsrForm] = Form.useForm();
    const [updateBsrForm] = Form.useForm();

    const getCategoryBsrIntervals = async() => {
        try{
            const bsrIntervals = await adminService.getDeloitteAmazonCategoryBsrIntervals(Number(categoryId));
            setBsrIntervals(bsrIntervals.data);
        }catch(err: any){
            alertNotification('error', err.response?.data?.message || err.message || 'Bsrler getirilemedi');
        }
    };

    const deleteBsrIntervalRecord = async() => {
        try{
            await adminService.deleteDeloitteBsrInterval(bsrIdToDelete!);
            setBsrDeleteModal(false);
            setBsrIdToDelete(null);
            getCategoryBsrIntervals();
        }catch(err: any) {
            alertNotification('error', err.response?.data?.message || err.message || 'Bsrler getirilemedi');
        }
    };

    const createBsrIntervalRecord = async(values: IDeloitteCreateBsrObject) => {
        try{
            await adminService.createDeloitteBsrInterval(Number(categoryId), values);
            createBsrForm.resetFields();
            setBsrCreateModal(false);
            getCategoryBsrIntervals();
        }catch(err: any) {
            alertNotification('error', err.response?.data?.message || err.message || 'Bsr oluşturulamadı');
        }
    }

    const updateBsrIntervalRecord = async(values: IDeloitteCreateBsrObject) => {
        try{
            await adminService.updateDeloitteBsrInterval(bsrIdToUpdate!, values);
            updateBsrForm.resetFields();
            setBsrUpdateModal(false);
            getCategoryBsrIntervals();
        }catch(err: any) {
            alertNotification('error', err.response?.data?.message || err.message || 'Bsr güncellenemedi.');
        }
    }

    const openDeleteBsrModal = (bsrIntervalId: number) => {
        setBsrIdToDelete(bsrIntervalId);
        setBsrDeleteModal(true);
    };

    const closeDeleteBsrModal = () => {
        setBsrIdToDelete(null);
        setBsrDeleteModal(false);
    };
    
    const openCreateBsrModal = () => {
        setBsrCreateModal(true);
    };

    const openUpdateBsrModal = (bsrInterval: IDeloitteBsrObject) => {
        setBsrUpdateModal(true);
        setBsrIdToUpdate(bsrInterval.id);
        updateBsrForm.setFieldValue('bsrRank', bsrInterval.bsrRank);
        updateBsrForm.setFieldValue('sales', bsrInterval.sales);
    };

    const closeUpdateBsrModal = () => {
        setBsrIdToUpdate(null);
        setBsrUpdateModal(false);
        updateBsrForm.resetFields();
    };

    const closeCreateBsrModal = () => {
        createBsrForm.resetFields();
        setBsrCreateModal(false);
    };

    useEffect(() => {
        getCategoryBsrIntervals();
    }, []);

    if(isNaN(Number(categoryId))){
        return (
        <div>
            Lütfen geçerli bir kategori seçiniz.
        </div>
    )
    }


  return (
    <div>
        <Modal
            title='Delete Bsr Interval'
            open={bsrDeleteModal}
            onCancel={closeDeleteBsrModal}
            onOk={deleteBsrIntervalRecord}
        >
            Bu kaydı silmek istediğinizden emin misiniz?
        </Modal>
        <Modal
            title='Update Bsr Interval'
            open={bsrUpdateModal}
            onCancel={closeUpdateBsrModal}
            footer={null}
        >
            <UpdateBsrForm
            formSubmit={updateBsrIntervalRecord}
            formReference={updateBsrForm}
            />
        </Modal>
        <Modal
            title='Create Bsr Interval'
            open={bsrCreateModal}
            onCancel={closeCreateBsrModal}
            footer={null}
        >
            <AddBsrForm
                formReference={createBsrForm}
                formSubmit={createBsrIntervalRecord}
            />
        </Modal>
        <Button  style={{marginBottom: '1vh'}} onClick={openCreateBsrModal}>Yeni BSR oluştur</Button>
        <Table 
            columns={columns}
            dataSource={bsrIntervals}
        />
    </div>
  )
}

export default DeloitteBsrDetail