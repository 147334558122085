import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';
import { Button, Form, Input, InputNumber } from 'antd';
import React from 'react'

const CreateExclusiveProduct = () => {
  const [form] = Form.useForm();
  const handleFormSubmit = (values: { asin: number, leadTime: number, shippingCost: number, productCost: number, maxOrderQuantity: number }) => {

    let payload = {
      asin: values.asin,
      leadTime: Number(values.leadTime),
      shippingCost: Number(values.shippingCost),
      productCost: Number(values.productCost),
      maxOrderQuantity: Number(values.maxOrderQuantity)
    }
    // console.log(payload)
    adminService.createExclusiveProduct(payload).then((res) => {

      alertNotification('success', 'Başarıyla Eklendi')
      form.resetFields();
    }).catch((err) => {

      alertNotification('error', err.status.message)
    });
  };

  return (
    <div style={{ display: 'flex', width: "100%", flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ marginBottom: '20px' }}>Exclusive Ürün Ekleme Sayfası</h2>

      <Form style={{ width: "80%" }} onFinish={handleFormSubmit} form={form} labelCol={{ span: 4 }} wrapperCol={{ offset: 1, span: 18 }}>

        <Form.Item label="ASIN" name="asin">
          <Input />
        </Form.Item>
        <Form.Item label="BSR" name="bsr">
          <Input placeholder='Bu alan zorunlu değildir.' />
        </Form.Item>
        <Form.Item label="Ürün Maliyeti" name="productCost" >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Kargo Maliyeti" name="shippingCost" >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Tedarik Süresi(gün)" name="leadTime" >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Max. Sipariş Miktarı" name="maxOrderQuantity" >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CreateExclusiveProduct