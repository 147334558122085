import React, { useEffect, useState } from "react";
import FaqTable from "../components/tables/pageTables/FaqTable";
import { Form, Input, Button, Row } from "antd";
import { Question } from "../interfaces/adminInterfaces";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import styles from "../components/filter/Filter.module.css";

const FaqPage = () => {
  const [current, setCurrent] = useState(1);
  const [questions, setQuestions] = useState<Question[]>([
    {
      id: 1,
      question: "Sordum soruyu!",
      answer: "Anladın sen!",
      faqCategoryId: 1,
    },
  ]);
  const [pageCount, setPageCount] = useState<number | any>();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [form] = Form.useForm();

  const getQuestions = async () => {
    try {
      const res = await adminService.getAllFaq(current, keyword);

      setQuestions(res.data.faq);
      setPageCount(res.data.totalPageCount);
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const onFinish = (values: { keyword: string }) => {
    setKeyword(values.keyword);
  };

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, keyword]);
  return (
    <div style={{ width: "100%" }}>
      <Form
        className={styles.filter}
        onFinish={onFinish}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <div className={styles.inputWrapper}>
            <Form.Item style={{ flex: "1" }} name="keyword">
              <Input
                className={styles.filterInput}
                placeholder={"Aramak istediğiniz anahtar kelimeyi giriniz"}
              />
            </Form.Item>
            <Button
              className={styles.submitButton}
              type="primary"
              danger
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
      <a href="/create-question">
        <Button
          style={{
            marginBottom: "20px",
          }}
        >
          Yeni Soru Ekle
        </Button>
      </a>

      <FaqTable
        setCurrent={setCurrent}
        current={current}
        datas={questions}
        loading={loading}
        totalPageCount={pageCount}
        getQuestions={getQuestions}
      />
    </div>
  );
};

export default FaqPage;
