import adminService from '../services/adminService'
import { useEffect, useState } from 'react'
import ServicesTable from '../components/tables/serviceProvidersTables/ServicesTable'
import alertNotification from '../utils/alertNotification'
import { Button } from 'antd';
import AddServiceForm from '../components/servicesForms/AddServiceForm'


function Services() {

    const [current, setCurrent] = useState(1);
    const [pageCount, setPageCount] = useState(20);
    const [services, setServices] = useState([]);
    const [approval, setApproval] = useState<'requested' | 'declined' | 'accepted' | 'all'>('all')
    const [loading, setLoading] = useState(false);
    const [showServiceAddition, setShowServiceAddition] = useState(false)

    const getServices = async (approval: 'requested' | 'declined' | 'accepted' | 'all', page: number = 1) => {
        try {
            setLoading(true)
            const servicesInfo = await adminService.getServices(page, approval)
            setServices(servicesInfo.data?.data)
            setPageCount(servicesInfo.data?.totalPageCount)
            setLoading(false)
        } catch (err: any) {
            setLoading(false)
            alertNotification('error', err.message)
        }

    }

    const deleteService = (id: number) => {
        if (window.confirm('Are you sure to delete the service?')) {
            adminService.deleteService(id)
                .then((res) => {
                    alertNotification('success', 'Hizmet başarıyla silindi');
                    getServices('all', 1);
                })
                .catch((err) => {
                    alertNotification('error', err.request.data.message)
                })
        }
    }

    useEffect(() => {
        getServices(approval, current)
    }, [current])

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>SERVICES</h1>
            <Button
                style={{ margin: '1vw' }}
                onClick={() => setShowServiceAddition(!showServiceAddition)}
            >Add Service</Button>

            {
                Boolean(showServiceAddition) ?
                    <AddServiceForm
                        showServiceAddition={showServiceAddition}
                        getServices={getServices}
                        setShowServiceAddition={setShowServiceAddition}
                    />
                    :
                    null
            }
            <div style={{ margin: '1vw' }}>
                <Button
                    style={{ marginRight: '0.5vw' }}
                    onClick={() => getServices('all', 1)}
                >All</Button>
                <Button
                    style={{ marginRight: '0.5vw', background: "blue", color: 'white' }}
                    onClick={() => getServices('requested', 1)}
                >Requested</Button>
                <Button
                    style={{ marginRight: '0.5vw', background: "#87d068", color: 'white' }}
                    onClick={() => getServices('accepted', 1)}
                >Accepted</Button>
                <Button
                    style={{ marginRight: '0.5vw', background: "#f50", color: 'white' }}
                    onClick={() => getServices('declined', 1)}
                >Declined</Button>
            </div>

            <ServicesTable
                services={services}
                loading={loading}
                current={current}
                setCurrent={setCurrent}
                pageCount={pageCount}
                deleteService={deleteService}
            />


        </div>
    )
}

export default Services