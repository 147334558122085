import { useNavigate } from "react-router-dom";
import { Skeleton, Table, Pagination } from "antd";
import type { PaginationProps } from "antd";

import { Invoices } from "../../../interfaces/adminInterfaces";

import columnsList from "../ColumnsLists";
import styles from "./Tables.module.css";
import adminService from "../../../services/adminService";
import alertNotification from "../../../utils/alertNotification";

interface TableProps {
  datas: any[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
}

const UnPaidTable: React.FC<TableProps> = ({
  datas,
  loading,
  setCurrent,
  current,
  totalPageCount,
}) => {
  const navigate = useNavigate();

  const data: Invoices[] = datas;

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  const retryPayment = (invoiceId: number) => {
    adminService.retryPayment(invoiceId)
      .then((res) => {
        alertNotification('success', res.data.data)
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message || 'Something went wrong')
      })
  }

  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Table
        className={styles.table}
        columns={columnsList.unpaidColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 1000 }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              navigate(`/users/${record.owner.id}`);
            },
            onClick: (event) => {
              const id = +(event.target as HTMLElement).id;
              // eslint-disable-next-line no-restricted-globals
              if (id == record.id && confirm("Sure to RETRY PAYMENT?")) {
                retryPayment(record.id)
              }
            }
          };
        }}
      />
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={totalPageCount}
      />
    </>
  );
};

export default UnPaidTable;
