import { Button, Card, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";

import AdminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";

import styles from "../styles/CreateCoupon.module.css";
import { Affiliates, PackagePlans } from "../interfaces/adminInterfaces";
const { Option } = Select;

const CreateCoupon: React.FC = () => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [platform, setPlatform] = useState<string>();
  const [currency, setCurrency] = useState<boolean>(false);
  const [type, setType] = useState<string>();
  const [duration, setDuration] = useState<string>();
  const [plans, setPlans] = useState<PackagePlans[]>([])
  const [affiliates, setAffiliates] = useState<Affiliates[]>([])


  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    if (platform === 'iyzico') {
      if (values.percentOff !== plans.find((el) => el.id === values.planId)!.discount) return alertNotification("error", 'Lütfen belirtilen yüzdelikle paketin yüzdeliğini kontrol ediniz!');
    };

    setLoading(true);
    AdminService.createCoupon(values)
      .then((res) => {
        alertNotification("success", "Coupon created succesfully");
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPackagePlans = () => {
    AdminService.getPackagePlans()
      .then((res) => {
        setPlans(res.data)
      })
      .catch((err) => {
        setError(true)
        alertNotification('error', err.response.data.message)
      })
  }

  const getAffiliates = () => {
    AdminService.getAffiliates()
      .then((res) => {
        setAffiliates(res.data)
      })
      .catch((err) => {
        setError(true)
        alertNotification('error', err.response.data.message)
      })
  }

  useEffect(() => {
    getPackagePlans()
    getAffiliates()
  }, [])

  return (
    <div className={styles.wrapperDiv}>
      <h2 style={{ color: 'red' }}>Lütfen package plan'da belirtilen indirimle percent off ta belirtilen indirimin aynı olduğundan emin olunuz.</h2>
      <Card
        title="CREATE COUPON"
        bordered={false}
        style={{ width: "40%", borderRadius: "10px" }}
      >
        <Form
          className={styles.form}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item
            name="platform"
            label="PLATFORM"
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value) => {
                setPlatform(value);
              }}
              placeholder="CHOOSE A PLATFORM"
            >
              <Option value="iyzico">IYZICO</Option>
              <Option value="stripe">STRIPE</Option>
            </Select>
          </Form.Item>
          {platform && (
            <Form.Item
              name="currency"
              label="CURRENCY"
              rules={[{ required: true }]}
            >
              {platform === "stripe" ? (
                <Select
                  onChange={() => {
                    setCurrency(true);
                  }}
                  placeholder="CHOOSE CURRENCY"
                >
                  <Option value="USD">USD</Option>
                  <Option value="EUR">EUR</Option>
                </Select>
              ) : (
                <Select
                  onChange={() => {
                    setCurrency(true);
                  }}
                  placeholder="CHOOSE CURRENCY"
                >
                  <Option value="USD">USD</Option>
                  <Option value="EUR">EUR</Option>
                  <Option value="TRY">TRY</Option>
                </Select>
              )}
            </Form.Item>
          )}
          {platform && currency && type !== "amount" && (
            <>
              <Select
                className={styles.couponType}
                onChange={(value) => {
                  setType(value);
                }}
                placeholder="PERCENT"
              >
                <Option value="percent">PERCENT</Option>
                <Option value="amount">AMOUNT</Option>
              </Select>
              <Form.Item
                name="percentOff"
                label="PERCENT"
                rules={[{ required: true }]}
              >
                <Input disabled={!type} type={"number"} />
              </Form.Item>
              <Form.Item
                name="duration"
                label="DURATION"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(value) => {
                    setDuration(value);
                  }}
                  placeholder="CHOOSE DURATION"
                >
                  <Option value="once">ONCE</Option>
                  <Option value="forever">FOREVER</Option>
                </Select>
              </Form.Item>
            </>
          )}
          {platform && currency && type === "amount" && (
            <>
              <Select
                className={styles.couponType}
                onChange={(value) => {
                  setType(value);
                }}
                placeholder="AMOUNT"
              >
                <Option value="percent">PERCENT</Option>
                <Option value="amount">AMOUNT</Option>
              </Select>
              <Form.Item
                name="amountOff"
                label="AMOUNT"
                rules={[{ required: true }]}
              >
                <Input disabled={!type} type={"number"} />
              </Form.Item>
              <Form.Item
                name="duration"
                label="DURATION"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(value) => {
                    setDuration(value);
                  }}
                  placeholder="CHOOSE DURATION"
                >
                  <Option value="once">ONCE</Option>
                  <Option value="forever">FOREVER</Option>
                </Select>
              </Form.Item>
            </>
          )}
          {platform && currency && type && duration && (
            <>
              <Form.Item name="code" label="CODE" rules={[{ required: false }]}>
                <Input placeholder="OPTIONAL" maxLength={20} />
              </Form.Item>
              <Form.Item
                name="affiliateId"
                label="AFFILIATE"
                rules={[{ required: false }]}
              >
                <Select
                  placeholder="OPTIONAL"
                >
                  {affiliates.length && affiliates.map((el) => (
                    <Option key={el.id} value={el.id}>{el.company}</Option>
                  ))}

                </Select>
              </Form.Item>
              {platform === 'stripe' ? (
                <Form.Item
                  name="maxRedemptions"
                  label="MAX REDEMPTIONs"
                  rules={[{ required: false }]}
                >
                  <Input type={"number"} />
                </Form.Item>
              ) : (
                <Form.Item
                  name="planId"
                  label="PACKAGE PLAN"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="CHOOSE PACKAGE PLAN"
                  >
                    {plans.length && plans.map((el) => (
                      <Option key={el.planReference} value={el.id}>{el.package.name} {el.duration === 1 ? 'Aylık' : 'Yıllık'} %{el.discount}</Option>
                    ))}

                  </Select>
                </Form.Item>
              )}
            </>
          )}
          {/* {platform && currency && type && duration === "forever" && (
            <>
              <Form.Item
                name="durationInMonths"
                label="DURATION IN MONTH"
                rules={[{ required: false }]}
              >
                <Input min={1} type="number" placeholder="OPTIONAL" />
              </Form.Item>
              <Form.Item
                name="maxRedemptions"
                label="MAX REDEMPTION"
                rules={[{ required: false }]}
              >
                <Input placeholder="OPTIONAL" min={1} type="number" />
              </Form.Item>
            </>
          )} */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {loading ? <Spin></Spin> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CreateCoupon;
