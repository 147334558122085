import { useNavigate } from "react-router-dom";
import { Skeleton, Table, Pagination, Button, Tag, Tooltip } from "antd";
import type { PaginationProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Subscriptions } from "../../../interfaces/adminInterfaces";

import styles from "./Tables.module.css";
import alertNotification from "../../../utils/alertNotification";

interface TableProps {
  datas: Subscriptions[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
  cancelSubscription: (id: number) => void;
}

const SubscriptionsTable: React.FC<TableProps> = ({
  datas,
  loading,
  setCurrent,
  current,
  totalPageCount,
  cancelSubscription,
}) => {
  const navigate = useNavigate();

  const data: Subscriptions[] = datas;

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  const subscriptionsColumns: ColumnsType<Subscriptions> = [
    {
      title: "USER EMAIL",
      dataIndex: ["user", "email"],
      key: "email",
      width: "15%",
    },
    {
      title: "NAME",
      render: (record) => {
        return `${record.user.firstName} ${record.user.lastName}`;
      },
      key: "firstName",
      width: "10%",
    },
    {
      title: "START DATE",
      render: (record) => {
        return new Date(record?.startDate)?.toLocaleString("tr-TR");
      },
      key: "startDate",
      width: "10%",
    },
    {
      title: "CANCEL DATE",
      render: (record) => {
        if (record.cancelDate) {
          return new Date(record?.cancelDate)?.toLocaleString("tr-TR");
        } else if (!record.cancelDate && record.isSubscription === true) {
          return (
            <Button id={record.id} danger>
              <span id={record.id}>CANCEL</span>
            </Button>
          );
        }
      },
      key: "cancelDate",
      width: "10%",
    },
    {
      title: "END DATE",
      render: (record) => {
        return record.endDate
          ? new Date(record?.endDate)?.toLocaleString("tr-TR")
          : "";
      },
      key: "endDate",
      width: "10%",
    },
    {
      title: "SUBSCRIBE",
      render: (record) => {
        return record.isSubscription === true ? (
          <Tag color="#87d068">SUBSC</Tag>
        ) : (
          <Tag color="#2db7f5"> PRODS</Tag>
        );
      },
      key: "isSubscription",
      width: "7%",
    },
    {
      title: "DISCORD ID",
      render: (record) => {
        return <Tag color="#87d068">{record.discordUserId}</Tag>;
      },
      key: "discordUserId",
      width: "11%",
    },
    {
      title: "DISCORD LINK",
      render: (record) => {
        if (record?.discordInviteLink)
          return (
            <>
              <Tag color="#87d068">{record.discordInviteLink}</Tag>{" "}
              <CopyToClipboard
                text={`https://discordapp.com/invite/${record.discordInviteLink}`}
              >
                <CopyOutlined
                  onClick={() => alertNotification("success", "copied")}
                />
              </CopyToClipboard>
            </>
          );
      },
      key: "discordInviteLink",
      width: "8%",
    },
    {
      title: "remarks",
      render: (record) => {
        return record.remarks !== "none" ? (
          <Tooltip color="volcano" title={record?.remarks}>
            <Tag color={"#f50"}>{record.remarks&&record.remarks.slice(0, 25)}</Tag>{" "}
          </Tooltip>
        ) : (
          ""
        );
      },
      key: "remarks",
      width: "11%",
    },
    {
      title: "PLAN",
      render: (record) => {
        return record?.packagePlan?.duration === 1 ? (
          <Tag color="#f50">{record?.packagePlan?.package?.name} AYLIK</Tag>
        ) : (
          <Tag color="#87d068">{record?.packagePlan?.package?.name} YILIK</Tag>
        );
      },
      width: "12%",
    },
  ];

  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Table
        className={styles.table}
        columns={subscriptionsColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 2000 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const id = +(event.target as HTMLElement).id;
              // eslint-disable-next-line no-restricted-globals
              if (id === record.id && confirm("Sure to CANCEL?")) {
                cancelSubscription(record.id);
              }
            },
            onDoubleClick: (event) => {
              const id = +(event.target as HTMLElement).id;

              if (id !== record.id) {
                navigate(`/users/${record.user.id}`);
              }
            },
          };
        }}
      />
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={totalPageCount}
      />
    </>
  );
};

export default SubscriptionsTable;
