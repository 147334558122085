import { DeloitteCountry } from "../../interfaces/adminInterfaces";
import { Button, Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib";
import { useState } from "react";

export interface IDeloitteAmazonCategoryCreateFormValues {
    country: string;
    categoryName: string;
    categoryId: number | null;
}

const countries = [
    { label: "USA", value: "usa" },
    { label: "Canada", value: "canada" },
    { label: "France", value: "france" },
    { label: "Britain", value: "britain" },
    { label: "Spain", value: "spain" },
    { label: "Italy", value: "italy" },
    { label: "Mexico", value: "mexico" },
    {label: 'Japan', value: 'japan'}
];

interface IDeloitteAddCategoryProps {
    formSubmit: (value: IDeloitteAmazonCategoryCreateFormValues) => void;
    formReference: FormInstance;
}

export default function DeloitteAddCategoryForm({formSubmit, formReference}: IDeloitteAddCategoryProps) {

    const submitForm = (value: IDeloitteAmazonCategoryCreateFormValues) => {
        formSubmit(value);
    };

  return (
    <div style={{width: '100%'}}>
        <Form form={formReference} labelCol={{ span: 6 }} onFinish={submitForm}>
            
            <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: 'Please input country!' }]}
                >
                    <Select>
                        {
                            countries.map((country) => (
                                <Select.Option key={country.value} value={country.value}>{country.label}</Select.Option>
                            ))
                        }
                    </Select>
            </Form.Item> 
            <Form.Item
                label="Category"
                name="categoryName"
                rules={[{ required: true, message: 'Please input category!' }]}
                >
                <Input type="text"/>
            </Form.Item> 
            <Form.Item
                label="Category Id"
                name="categoryId"
                rules={[{ required: true, message: 'Please input category id!' }]}
                >
                <Input type="number"/>
            </Form.Item>
            <Form.Item wrapperCol={{offset:20}}>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit">Submit</Button>
            </Form.Item>

        </Form>
    </div>
  )
}