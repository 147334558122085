import { Button, Form, Input, Row,DatePicker, Space } from 'antd'
import React from 'react'
import styles from "./BirFaturaFilter.module.css";

interface BirFaturaProps{
    setPayload:React.Dispatch<React.SetStateAction<{
        startDate: string;
        endDate: string;
        email: string;
    }>>
}

const BirFaturaFilter:React.FC<BirFaturaProps> = ({setPayload}) => {

    const [form] = Form.useForm<{ name: string }>();

    const handleFilter =  (values:any)=>{

        let temp = {email:values.email,startDate:values.date[0],endDate:values.date[1],page:1}
        setPayload(temp)
       
    }
    const { RangePicker } = DatePicker;

  return (
    
         <Form
        className={styles.filter}
        onFinish={handleFilter}
        // onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
          <p>Aramak istediğiniz fatura için email girip submit butonuna basınız</p>
          <div className={styles.inputWrapper}>
            <Form.Item style={{flex:1}} name="email">
              <Input className={styles.filterInput} placeholder="Email giriniz" />
            </Form.Item>
            <Form.Item  name="date">
            <RangePicker />
            </Form.Item>
            <Button
              className={styles.submitButton}
              type="primary"
              danger
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        
      </Form>

  )
}

export default BirFaturaFilter