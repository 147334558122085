import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { Button, DatePicker, Form, Input } from 'antd'
import React from 'react'

interface Props{
    setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>
    getCategories: () => Promise<void>
}
const CreateBSRCategoryForm:React.FC<Props> = ({setOpenCreateModal,getCategories}) => {
    const [form] = Form.useForm();

    const handleSubmit = async (values:any)=>{
        try {
            await adminService.createBSRCategory(values)
            getCategories()
            
            setOpenCreateModal(false)
        } catch (error) {
           alertNotification("error","Hata oluştu")     
        }
    }
  return (
    <div style={{width:"100%"}}>
        
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{span:18,offset:1}} onFinish={handleSubmit}>
            
            <Form.Item label="Ülke" name="country">
                <Input/>
            </Form.Item> 
            <Form.Item label="Kategori" name="categoryName">
                <Input/>
            </Form.Item> 

            <Form.Item label="Kategori Id" name="amazonCategoryId">
                <Input/>
            </Form.Item>
            <Form.Item wrapperCol={{offset:22}}>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit">Submit</Button>
            </Form.Item>

        </Form>
    </div>
  )
}

export default CreateBSRCategoryForm