import React, { useState, useEffect } from "react";
import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { Providers as DataType } from "../interfaces/adminInterfaces";
import ProvidersSubsTable from "../components/tables/pageTables/ProvidersSubsTable";
import { Button } from "antd";

const ServiceProviderSub: React.FC = () => {
  // const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [providers, setProviders] = useState<DataType[]>([]);

  const getProviders = () => {
    setLoading(true);
    AdminServices.getServiceProviderSubs()
      .then((res) => {
        setProviders(res.data);
      })
      .catch((err) => {
        alertNotification("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProviders();
  }, []);

  return (
    <div style={{ width: "100%", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <h2>Hizmet Sağlayıcılar Abonelikleri</h2>
      </div>
      <a href="/create-provider-sub">
        <Button
          style={{
            marginBottom: "20px",
          }}
        >
          Hizmet Sağlayıcı Aboneliği Ekle
        </Button>
      </a>
      {providers.length !== 0 &&  <ProvidersSubsTable providers={providers} getProviders={getProviders} />}
    
    </div>
  );
};

export default ServiceProviderSub;
