import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";

import Filter from "../components/filter/Filter";
import CouponsTable from "../components/tables/pageTables/CouponsTable";
import { Button } from "antd";
import { Affiliates, Coupons as DataType } from "../interfaces/adminInterfaces";

const Coupons: React.FC = () => {
  const [current, setCurrent] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [coupons, setCoupons] = useState<DataType[]>([]);
  const [affiliates, setAffiliates] = useState<Affiliates[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // const { user } = useContext(AuthContext);

  //fetch all users
  const getCoupons = (filterValue: string | undefined) => {
    setLoading(true);
    AdminServices.getCoupons(current, 20, filterValue)
      .then((res) => {
        setCoupons(res.data);
        setTotalPageCount(res.length);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //delete Coupon
  const deleteCoupon = (id: number) => {
    setLoading(true);
    AdminServices.deleteCoupon(id)
      .then((res) => {
        // console.log(res);
        getCoupons("");
        alertNotification("success", "Coupon deleted succesfully");
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAffiliates = () => {
    AdminServices.getAffiliates()
      .then((res) => {
        setAffiliates(res.data);
      })
      .catch((err) => {
        setError(true);
        alertNotification("error", err.response.data.message);
      });
  };

  useEffect(() => {
    getCoupons(searchTerm);
    getAffiliates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
      <Filter
        placeholder={"Code"}
        setCurrent={setCurrent}
        getPageDatas={getCoupons}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
      <a href="/create-coupon">
        <Button
          style={{
            marginBottom: "20px",
          }}
        >
          Yeni Kupon Ekle
        </Button>
      </a>
      <CouponsTable
        datas={coupons}
        deleteCoupon={deleteCoupon}
        loading={loading}
        setCurrent={setCurrent}
        current={current}
        totalPageCount={totalPageCount}
        affiliates={affiliates}
      />
    </>
  );
};

export default Coupons;
