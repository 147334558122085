import React, { useEffect, useState, useRef } from "react";
import { Form, Card, Row, Col, Table, Button, Modal, Input, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import adminService from "../services/adminService";
import { useNavigate, useParams } from "react-router-dom";
import alertNotification from "../utils/alertNotification";
import { API_IMAGE_URL } from "../config";
import { Editor } from "@tinymce/tinymce-react";
const { Column } = Table;
const { TextArea } = Input;
const { Option } = Select;
interface Payment {
  id?: number;
  amount: number;
  description: string;
  billingDate: Date | null;
  serviceProviderSubId: number;
  isBilled?: boolean;
}

const ProviderSubsDetail: React.FC = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);
  const [formValues, setFormValues] = useState<Payment>({
    amount: 0,
    description: "string",
    billingDate: null,
    serviceProviderSubId: Number(params.id),
  });
  const [discordData, setDiscordData] = useState<any>([]);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [info, setInfo] = useState({
    id: 1,
    company: "",
    subStart: "",
    email: "",
    subEnd: "",
    subsType: "",
    amount: "",
    status: "",
    currency: "",
    paymentChannel: "",
    description: "",
    createdAt: "",
    updatedAt: "",
    billingAddressId: 1,
    billingAddress: {
      id: 1,
      title: "",

      firstName: "",
      lastName: "",
      gsmNumber: "",
      identityNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      zipCode: "",
      isCorporate: true,
      vatNumber: "",
      companyName: "",
      taxOffice: "",
    },
  } as any);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [plans, setPlans] = useState<any>([])
  const [selectedPlan, setSelectedPlan] = useState<any>(null)
  const [invoice, setInvoice] = useState<any>(null)
  const getProviderSub = (id: number) => {
    setLoading(true);
    adminService
      .getServiceProviderSub(id)
      .then((res) => {
        // console.log("res", res);
        //  // console.log(formValues)
        setInfo(res.data);
        setDiscordData(res.data.users);
      })
      .catch((err) => {
        // console.log("err", error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showModal = () => {
    setDisable(false)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getSubPayment = async (id: number) => {
    let payments = await adminService.getSubsPayments(id);
    setPayments(payments.data);
  };

  const sendBill = async (id: number) => {
    try {
      await adminService.createBill(Number(id));
      alertNotification("success", "Fatura başarıyla gönderildi");
      getSubPayment(Number(params.id));
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }

  };

  const handleFormSubmit = () => {
    let formValuesCopy: any = { ...formValues };
    // check user fill all fields
    for (let key in formValuesCopy) {
      if (formValuesCopy[key] === null || formValuesCopy[key] === "") {
        alertNotification(
          "error",
          "Lütfen tüm alanları doldurunuz" + formValuesCopy[key] + "--" + key
        );

        return;
      }
    }

    setDisable(true);
    adminService
      .createSubsPayment(formValuesCopy)
      .then((res) => {
        alertNotification("success", "Başarıyla Eklendi");
        form.resetFields();
        setIsModalOpen(false);
        getSubPayment(Number(params.id));
      })
      .catch((err) => {
        // console.log(err);
        alertNotification("error", err.status.message);
      });
  };
  const upgrade = () => {
    adminService.upgradeSub(Number(params.id), { planId: selectedPlan }).then(res => {
      alertNotification("success", "Abonelik başarıyla güncellendi")
      getProviderSub(Number(params.id))
    }
    ).catch(err => {
      alertNotification("error", err.response.data.message)
    })
  }
  const deletePayment = async (id: number) => {
    await adminService.deleteSubsPayment(id);
    getSubPayment(Number(params.id));
  };
  const getPlans = async () => {
    let plans = await adminService.getPlans()
    // console.log("plans",plans.data)
    setPlans(plans.data)
  }
  const getInvoices = async (id: number) => {
    let invoices = await adminService.getSpInvoices(id);
    // console.log("invoices", invoices);
    setInvoice(invoices.data);
  }
  useEffect(() => {
    getProviderSub(Number(params.id));
    getSubPayment(Number(params.id));
    getInvoices(Number(params.id));
    getPlans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2 style={{ marginBottom: "20px", marginLeft: 20 }}>
        {info.company} Hizmet Sağlayıcı Detay
      </h2>
      <Row style={{ width: "100%" }}>
        <Col span={12}>
          <Card title="Abonelik Bilgileri" style={{ margin: 20 }}>
            <p>Şirket: {info.company}</p>
            <p>Email: {info.email}</p>
            <p>
              Abonelik Başlangıç: {new Date(info.subStart).toLocaleDateString()}
            </p>
            <p>Abonelik Bitiş: {new Date(info.subEnd).toLocaleDateString()}</p>
            <p>Abonelik Türü: {info.subsType}</p>
            <p>
              Tutar: {info.amount} {info.currency}
            </p>
            <p>Durum: {info.status}</p>
            <p>Ödeme Kanalı: {info.paymentChannel}</p>
            <p>Açıklama: {info.description}</p>
            <p>
              Oluşturulma Tarihi: {new Date(info.createdAt).toLocaleString()}
            </p>
            <p>
              Güncelleme Tarihi: {new Date(info.updatedAt).toLocaleString()}
            </p>

            <br />

            <Card title="Fatura Adresi">
              <p>
                Adı: {info.billingAddress.firstName}{" "}
                {info.billingAddress.lastName}
              </p>
              <p>GSM Numarası: {info.billingAddress.gsmNumber}</p>
              <p>Adres: {info.billingAddress.addressLine1}</p>
              <p>{info.billingAddress.addressLine2}</p>
              <p>Şehir: {info.billingAddress.city}</p>
              <p>Ülke: {info.billingAddress.country}</p>
              <p>Posta Kodu: {info.billingAddress.zipCode}</p>
              <p>
                Kurumsal Müşteri:{" "}
                {info.billingAddress.isCorporate ? "Evet" : "Hayır"}
              </p>
              {info.billingAddress.isCorporate && (
                <>
                  <p>Vergi Numarası: {info.billingAddress.vatNumber}</p>
                  <p>Şirket Adı: {info.billingAddress.companyName}</p>
                  <p>Vergi Dairesi: {info.billingAddress.taxOffice}</p>
                </>
              )}
            </Card>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Discord Kullanıcıları" style={{ margin: 20 }}>
            <Table dataSource={discordData}>
              <Column title="ID" dataIndex="id" key="id" />
              <Column
                title="Discord User ID"
                dataIndex="discordUserId"
                key="discordUser"
              />
              <Column title="Email" dataIndex="email" key="email" />
            </Table>
          </Card>

          <Card title="Ödemeler" style={{ margin: 20 }}>
            <Button style={{ marginBottom: "10px" }} onClick={showModal}>
              Yeni Ödeme
            </Button>
            <Table dataSource={payments}>
              <Column title="id" dataIndex="id" key="id" />
              <Column title="Amount" dataIndex="amount" key="amount" />
              <Column
                title="Payment Date"
                dataIndex="billingDate"
                key="billingDate"
              />
              <Column
                title="Description"
                dataIndex="description"
                key="description"
              />
              <Column
                title="Aksiyon"
                key="action"
                render={(text, record: Payment) => (
                  <>
                    <Button disabled={record.isBilled} onClick={() => sendBill(record.id!)}>
                      Fatura
                    </Button>
                    <Button disabled={record.isBilled} onClick={() => deletePayment(record.id!)}>
                      Sil
                    </Button>
                  </>
                )}
              />
            </Table>
          </Card>
          <Card title="Iyzico Ödemeleri" style={{ margin: 20 }}>
            <Button style={{ marginBottom: "10px" }} onClick={upgrade}>Upgrade</Button>
            <Select style={{ minWidth: "200px" }} onChange={
              (e: any) => {
                setSelectedPlan(e)
              }
            }>
              {plans.map((plan: any, key: number) => { return (<Option key={plan.key} value={plan.reference}>{plan.planName}</Option>) })}
            </Select>
            <Table dataSource={invoice}>
              <Column title="Fiyat" dataIndex="paidPrice" key="paidPrice" />
              <Column
                title="Durum"
                dataIndex="paymentStatus"
                key="paymentStatus"
              />
              <Column title="Tarih" dataIndex="billingDate" key="billingDate" />
            </Table>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Ödeme Oluştur"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          style={{ width: "100%" }}
          onFinish={handleFormSubmit}
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Form.Item label="Amount" name="amount">
            <Input
              value={formValues.amount}
              type="number"
              onChange={(e) =>
                setFormValues({ ...formValues, amount: Number(e.target.value) })
              }
            />
          </Form.Item>
          <Form.Item label="Payment" name="billingDate">
            <Input
              type="date"
              value={formValues.billingDate?.toISOString().split("T")[0]}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  billingDate: new Date(e.target.value),
                })
              }
            />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea
              style={{ minHeight: "200px" }}
              value={formValues.description}
              onChange={(e) =>
                setFormValues({ ...formValues, description: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 4 }}>
            <Button
              disabled={disable}
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProviderSubsDetail;
