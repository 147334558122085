import { AxiosResponse } from "axios";

import httpClient from "../utils/httpClient";
import { LoginDataInterface } from "../interfaces/authInterfaces";

import { API_AUTH_URL } from "../config";

class AuthService {
  responseBody = (response: AxiosResponse) => response.data;

  // define default setup of axios CRUD operations
  requests = {
    get: (url: string) =>
      httpClient.get(url,).then(this.responseBody),
    post: (url: string, body: {}) =>
      httpClient
        .post(url, body,)
        .then(this.responseBody),
    put: (url: string, body: {}) =>
      httpClient
        .put(url, body,)
        .then(this.responseBody),
    delete: (url: string) =>
      httpClient.delete(url,).then(this.responseBody),
  };

  //login User
  public login(data: LoginDataInterface): Promise<[]> {
    return this.requests.post(`${API_AUTH_URL}/login`, data);
  }

  //check Auth. of User
  public checkAuth() {
    return this.requests.get(`${API_AUTH_URL}/check-auth`);
  }

  // logout user
  logoutUser() {
    return this.requests.get(`${API_AUTH_URL}/logout`);
  }
}

export default new AuthService();
