import { useContext } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "../pageTables/Tables.module.css";
import AuthContext from "../../../context/AuthContext";

interface DataType {
  id: string;
  Montly1: string;
  Montly2: string;
  Montly3: string;
  Montly4: string;
  Montly5: string;
  Montly6: string;

  Yearly1: string;
  Yearly2: string;
  Yearly3: string;
  Yearly4: string;
  Yearly5: string;
  Yearly6: string;
}

interface CorporateLinksTableInterface {
  generateId: Function;
  handleRender: Function;
}
const CorporateLinksTable: React.FC<CorporateLinksTableInterface> = ({
  generateId,
  handleRender,
}) => {
  const { user } = useContext(AuthContext);
  const userId = user?.id;

  const data: DataType[] = [
    {
      id: "id",
      // corporate links
      Montly1: `https://community.rexven.com/payment/?sellerId=${userId}&type=subs&platform=iyzico&productId=${generateId(
        3
      )}-9-${generateId(3)}`,
      Montly2: `https://community.rexven.com/payment/?sellerId=${userId}&type=subs&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Montly3: `https://community.rexven.com/payment/?sellerId=${userId}&type=subs&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Montly4: `https://community.rexven.com/payment/?sellerId=${userId}&type=subs&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Montly5: `https://community.rexven.com/payment/?sellerId=${userId}&type=subs&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Montly6: `https://community.rexven.com/payment/?sellerId=${userId}&type=subs&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,

      Yearly1: `https://community.rexven.com/payment/?sellerId=${userId}&type=prod&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Yearly2: `https://community.rexven.com/payment/?sellerId=${userId}&type=prod&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Yearly3: `https://community.rexven.com/payment/?sellerId=${userId}&type=prod&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Yearly4: `https://community.rexven.com/payment/?sellerId=${userId}&type=prod&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Yearly5: `https://community.rexven.com/payment/?sellerId=${userId}&type=prod&platform=iyzico&productId=${generateId(
        3
      )}-1-${generateId(3)}`,
      Yearly6: `https://community.rexven.com/payment/?sellerId=${userId}&type=prod&platform=iyzico&productId=${generateId(
        3
      )}-2-${generateId(3)}`,
    },
  ];

  const corparateLinksColumns: ColumnsType<DataType> = [
    {
      title: "KURUMSAL",
      key: "KURUMSAL",
      children: [
        {
          title: "AYLIK",
          key: "Aylık",
          children: [
            {
              title: "AYLIK1",
              key: "AYLIK1",
              render: (text) => handleRender(text.Montly1),
            },
            {
              title: "AYLIK2",
              key: "AYLIK2",
              render: (text) => handleRender(text.Montly2),
            },
            {
              title: "AYLIK3",
              key: "AYLIK3",
              render: (text) => handleRender(text.Montly3),
            },
            {
              title: "AYLIK4",
              key: "AYLIK4",
              render: (text) => handleRender(text.Montly4),
            },
            {
              title: "AYLIK5",
              key: "AYLIK5",
              render: (text) => handleRender(text.Montly5),
            },
            {
              title: "AYLIK6",
              key: "AYLIK6",
              render: (text) => handleRender(text.Montly6),
            },
          ],
        },
        {
          title: "YILLIK",
          key: "Yıllık",
          children: [
            {
              title: "YILLIK1",
              key: "YILLIK1",
              render: (text) => handleRender(text.Yearly1),
            },
            {
              title: "YILLIK2",
              key: "YILLIK2",
              render: (text) => handleRender(text.Yearly2),
            },
            {
              title: "YILLIK3",
              key: "YILLIK3",
              render: (text) => handleRender(text.Yearly3),
            },
            {
              title: "YILLIK4",
              key: "YILLIK4",
              render: (text) => handleRender(text.Yearly4),
            },
            {
              title: "YILLIK5",
              key: "YILLIK5",
              render: (text) => handleRender(text.Yearly5),
            },
            {
              title: "AYILLIK",
              key: "YILLIK6",
              render: (text) => handleRender(text.Yearly6),
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Table
        className={styles.table}
        columns={corparateLinksColumns}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.id}
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default CorporateLinksTable;
