import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Row, Col, Table } from "antd";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { ICreateStore } from "@src/interfaces/adminInterfaces";

const { Option } = Select;

const CreateStore = () => {
  const [form] = Form.useForm();
  const [stores, setStores] = useState<any[]>([]);
  const [shipentegraStores, setShipentegraStores] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [shipentegraUserValue, setShipentegraUserValue] = useState<string>("");

  const handleQuestionSubmit = async (values: ICreateStore) => {
    try {
      values["isAdmin"] = true;
      await adminService.createStore(values);
      form.resetFields();
      alertNotification("success", "Kayıt başarılı!");
      getStores();
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const getStores = async () => {
    try {
      const result = await adminService.getStores();
      const filteredStores = result.data.filter(
        (store: any) => store.storeName !== "Manual Orders"
      );
      setStores(filteredStores);
    } catch (error) {
      alertNotification("error", "Bir hata oluştu");
    }
  };

  const getShipentegraStores = async () => {
    try {
      // This would typically call an API to fetch the Shipentegra stores based on shipentegraUserValue
      const result = await adminService.getShipentegraStores(shipentegraUserValue);
     console.log(result.data)
      setShipentegraStores(result.data[0].stores);
    } catch (error) {
      alertNotification("error", "Shipentegra mağazaları getirilemedi");
    }
  };

  useEffect(() => {
    getStores();
  }, []);

  const columns = [
    {
      title: "Store Id",
      dataIndex: "storeId",
      key: "storeId",
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Marketplace Name",
      dataIndex: "marketplaceName",
      key: "marketplaceName",
    },
  ];
  const shipEntegracolumns = [
    {
      title: "Store Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Marketplace Name",
      dataIndex: "marketplace",
      key: "marketplace",
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleShipentegraUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShipentegraUserValue(e.target.value);
  };

  const handleShipentegraSearch = () => {
    getShipentegraStores();
  };

  const filteredStores = stores.filter((store) =>
    store.storeName.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
        Sıkça Sorulan Soruları Ekle
      </h1>
      <Row justify="space-between">
        <Col span={7}>
          <h1 style={{ textAlign: "center" }}>Mağaza Bilgilerini Ekle</h1>
          <Form
            style={{ width: "100%" }}
            onFinish={handleQuestionSubmit}
            form={form}
            labelCol={{ span: 15 }}
            wrapperCol={{ span: 15 }}
          >
            <Form.Item label="User Id" name="sellerId">
              <Input />
            </Form.Item>
            <Form.Item label="Shipstation Store" name="storeId">
              <Input />
            </Form.Item>
            <Form.Item label="Shipentegra Store" name="shipentegraStoreId">
              <Input />
            </Form.Item>
            <Form.Item label="Platform" name="platform">
              <Select>
                <Option value="etsy">Etsy</Option>
                <Option value="amazon">Amazon</Option>
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
              <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={7}>
          <h1 style={{ textAlign: "center" }}>Shipstation Hesabına Bağlı Mağazalar</h1>
          <Input
            placeholder="Mağaza ismi ile ara"
            value={searchText}
            onChange={handleSearch}
            style={{ marginBottom: "20px" }}
          />
          <Table dataSource={filteredStores} columns={columns} rowKey="storeId" />
        </Col>
        <Col span={7}>
          <h1 style={{ textAlign: "center" }}>Shipentegra Hesabına Bağlı Mağazalar</h1>
          <Input
            placeholder="User Id"
            value={shipentegraUserValue}
            onChange={handleShipentegraUserChange}
            style={{ marginBottom: "10px" }}
          />
          <Button type="primary" className="" onClick={handleShipentegraSearch}>
            Ara
          </Button>
          <Table
            dataSource={shipentegraStores}
            columns={shipEntegracolumns}
            rowKey="storeId"
            style={{ marginTop: "20px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreateStore;