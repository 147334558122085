import React from 'react'
import type { ColumnsType } from 'antd/es/table';
import { Table,Pagination,Space, Button } from 'antd';
import type { PaginationProps } from "antd";
import { Question } from '@src/interfaces/adminInterfaces';



interface TableProps {
    datas: any[];
    loading: boolean;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    current: number;
    totalPageCount: number;
   
  }
 

const ProducerTable:React.FC<TableProps> = ({setCurrent,current,totalPageCount,datas}) => {


  const columns : ColumnsType<Question> =[
   
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Producer Name",
      dataIndex: "producerName",
      key: "producerName",
    },
    {
      title: "Gsm",
      dataIndex: "gsm",
      key: "gsm",
    },
    {
      title: "Producer Gsm",
      dataIndex: "producerGsm",
      key: "producerGsm",
    },
   
 
  ];
    const onChange: PaginationProps["onChange"] = (value) => {
        setCurrent(value);
      };
  return (
    <div>
       <Table dataSource={datas} pagination={false} columns={columns}/>

       <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        defaultCurrent={1}
        total={totalPageCount*10}
        style={{marginTop:"1rem"}}
      />
    </div>
  )
}

export default ProducerTable