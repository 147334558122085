import { Button, Modal, Pagination } from "antd";
import ProductTable from "../components/marketplace/ProductTable";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import React, { useEffect } from "react";
import Filter from "../components/filter/Filter";
import CreateProductForm from "../components/marketplace/CreateProductForm";

const MarketplaceProducts = () => {
  const [page, setPage] = React.useState(1);
  const [keyword, setKeyword] = React.useState("");
  const [data, setData] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);

  const getProducts = async () => {
    try {
      const res = await adminService.getMarketplaceProducts(page, keyword);
      setData(res.data);
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, [keyword, page]);


  return (
    <>
    
        <Filter
          searchTerm={keyword}
          setSearchTerm={setKeyword}
          setCurrent={setPage}
          placeholder="SKU veya Title giriniz"
        />
        <Button type='primary' onClick={()=>setOpenModal(true)}>Ürün Ekle</Button>
      

      <Modal
        width="70%"
        title="Ürün Yükle"
        footer={null}
        open={openModal}
        onOk={() => {
          setOpenModal(false);
        }}
        onCancel={() => {
          setOpenModal(false);

        }}
      >
        <CreateProductForm getProducts={getProducts} setOpenModal={setOpenModal} />
      </Modal>
      
      <ProductTable data={data} getProducts={getProducts} />
      <Pagination
        current={page}
        onChange={(value) => setPage(value)}
        defaultCurrent={1}
        total={100}
        style={{ marginTop: "1rem" }}
      />
    </>
  );
};

export default MarketplaceProducts;
