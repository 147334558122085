import React, { useContext, useState } from "react";
import { Row } from "antd";

import { UserDetail as DataType } from "../../../interfaces/adminInterfaces";
import CautionModal from "../../modal/CautionModal";
import AuthContext from "../../../context/AuthContext";
import alertNotification from "../../../utils/alertNotification";
import adminService from "../../../services/adminService";
import UserDetailedAddressCard from "./userDetailedAddressCard/UserDetailedAddressCard";
import UserDetailedInfoCard from "./userDetailedInfoCard/UserDetailedInfoCard";

interface UserDetailPage {
  detailedUser?: DataType;
  loading: boolean;
  getUserDetail: Function;
}

const UserDetailCard: React.FC<UserDetailPage> = ({
  detailedUser,
  loading,
  getUserDetail,
}) => {
  const { user } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState<string>('')
  const [passwordLoading, setPasswordLoading] = useState<boolean>(false)

  const cancelModalFunction = () => {
    setModalOpen(false);
  };

  const handleUserRole = (roleId: { roleId: number }) => {
    if (user?.roleCode === "pt04n1dT") {
      adminService
        .updateUserRole(detailedUser!.id, roleId)
        .then((res) => {
          getUserDetail(detailedUser!.id);
          alertNotification("success", "User Role Updated Successfully");
        })
        .catch((err) => {
          // console.log(modalError);
          setModalError(true);
          alertNotification("error", err.response.data.message);
        })
        .finally(() => {
          setModalOpen(false);
          setModalLoading(false);
        });
    } else {
      alertNotification("error", "Unauthorized Action");
    }
  };

  const generateUserPassword = () => {
    setPasswordLoading(true)
    adminService
      .updateUserPassword(detailedUser!.id)
      .then((res) => {
        setGeneratedPassword(res.data)
      })
      .catch((err) => {
        alertNotification('error', err.message)
      })
      .finally(() => {
        setPasswordLoading(false)
      })
  }

  const verifyUser = () => {
    adminService
    .verifyUser(detailedUser!.id)
    .then((res) => {
      getUserDetail(detailedUser!.id)
    })
    .catch((err) => {
      console.log(err)
      alertNotification('error', err.response?.data?.message || err.message)
    })
  }

  const verifyGsmNumber = () => {
    adminService
    .verifyGsmNumber(detailedUser!.id)
    .then((res) => {
      getUserDetail(detailedUser!.id)
    })
    .catch((err) => alertNotification('error', err.response?.data?.message || err.message))
  }

  return (
    <Row>
      <UserDetailedInfoCard
        loading={loading}
        setModalOpen={setModalOpen}
        detailedUser={detailedUser}
        generateUserPassword={generateUserPassword}
        generatedPassword={generatedPassword}
        passwordLoading={passwordLoading}
        verifyUser={verifyUser}
        verifyGsmNumber={verifyGsmNumber}
      />
      {detailedUser?.billingAddresses.length ? (
        <UserDetailedAddressCard
          loading={loading}
          detailedUser={detailedUser}
        />
      ) : (
        ""
      )}
      <CautionModal
        component={"UserDetailCard"}
        loading={modalLoading}
        title="UPDATE USER ROLE"
        modalFunction={handleUserRole}
        cancelModalFunction={cancelModalFunction}
        modalOpen={modalOpen}
      />
    </Row>
  );
};

export default UserDetailCard;
