import adminService from "src/services/adminService";
import alertNotification from "src/utils/alertNotification";
import { Button, Form, Input, Modal, Radio, InputNumber } from "antd";
import React from "react";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  getUserDetail: (id?: string) => void;
}
const WalletEditForm: React.FC<Props> = ({
  open,
  setOpen,
  userId,
  getUserDetail,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    values.amount = values.type === "deposit" ? values.amount : -values.amount;
    try {
      await adminService.withdrawOrDepositWallet(userId, values);
      getUserDetail(String(userId));
      setOpen(false);
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    }
  };
  const { TextArea } = Input;
  return (
    <Modal
      open={open}
      style={{ top: "25%" }}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      title="Cüzdana Bakiye Ekleme/Çıkarma İşlemi"
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18, offset: 1 }}
        onFinish={handleSubmit}
      >
        <Form.Item name={"type"} label="İşlem">
          <Radio.Group>
            <Radio value="deposit"> Ekle </Radio>
            <Radio value="withdraw"> Çıkar </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Amount:" name={"amount"}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Not:" name="info">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Onayla
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WalletEditForm;
