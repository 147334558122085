import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";

import Filter from "../components/filter/Filter";

import { Courses as DataType } from "../interfaces/adminInterfaces";
import UserCoursesTable from "../components/tables/pageTables/UserCoursesTable";

const UserCourses: React.FC = () => {
    const [current, setCurrent] = useState(1);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [courses, setCourses] = useState<DataType[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('')

    //fetch all users
    const getCourses = (filterValue: string | undefined) => {
        setLoading(true);
        AdminServices.getUserCourses(current, 10, filterValue)
            .then((res) => {
                setCourses(res.data);
                setTotalPageCount(res.length);
            })
            .catch((err) => {
                // console.log(error);
                setError(true);
                alertNotification("error", err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getCourses(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    return (
        <>
            <Filter
                placeholder={"E-mail, Ad veya Soyad"}
                setCurrent={setCurrent}
                getPageDatas={getCourses}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
            <UserCoursesTable
                datas={courses}
                loading={loading}
                setCurrent={setCurrent}
                current={current}
                totalPageCount={totalPageCount}
            />
        </>
    );
};

export default UserCourses;
