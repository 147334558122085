import { useState } from "react";
import { Modal, Spin, Select, Tag } from "antd";
import { UpdateUserRole } from "../../interfaces/adminInterfaces";

interface CautionModalInterface {
  modalFunction: Function;
  cancelModalFunction: Function;
  modalOpen: boolean;
  title: string;
  loading: boolean;
  component: string;
}
const CautionModal: React.FC<CautionModalInterface> = ({
  modalFunction,
  cancelModalFunction,
  modalOpen,
  title,
  loading,
  component,
}) => {
  const [roleId, setRoleId] = useState<{ roleId: number }>();
  const { Option } = Select;

  const roles: { roleName: string; roleId: number; color: string }[] = [
    { roleName: "USER", roleId: 1, color: "lime" },
    { roleName: "CRM", roleId: 2, color: "gold" },
    { roleName: "DEVELOPER", roleId: 3, color: "orange" },
    { roleName: "ADMIN", roleId: 4, color: "volcano" },
    { roleName: "SUPERADMIN", roleId: 5, color: "red" },
  ];
  let content;

  const handleChange = (value: any) => {
    // console.log("formVAlue", value.value);
    setRoleId({ roleId: value.value });
  };

  if (component === "UserDetailCard") {
    content = (
      <>
        {loading ? (
          <Spin size="large" />
        ) : (
          <>
            {" AS  "}
            <Select
              placeholder="Please Select a Role"
              optionLabelProp={roles[0].roleName}
              labelInValue={true}
              style={{ width: 150, marginLeft: "5px" }}
              onChange={handleChange}
            >
              {roles.map((role) => {
                return (
                  <Option
                    label={role.roleName}
                    key={role.roleId}
                    value={role.roleId}
                  >
                    <Tag color={role.color}> {role.roleName}</Tag>
                  </Option>
                );
              })}
            </Select>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Modal
        title={title}
        style={{ top: 20 }}
        open={modalOpen}
        onOk={() => modalFunction(roleId)}
        onCancel={() => cancelModalFunction()}
      >
        {content}
      </Modal>
    </>
  );
};

export default CautionModal;
