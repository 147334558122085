import { notification } from 'antd';

type not = "success" | "error" | "warning"

export default function alertNotification(type: not, message = "Something went wrong") {
    notification.config({
        rtl: false,
        placement: "topRight",
        duration: 5,
    })
    notification[type]({
        message: message,
    });
};