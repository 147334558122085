import { Card, Skeleton, Table, Tag, Tooltip } from "antd";

import { UserCours } from "../../../interfaces/adminInterfaces";

import styles from "../pageTables/Tables.module.css";

interface TableProps {
  datas: UserCours[];
  loading: boolean;
}

const DetailedUserCoursesTable: React.FC<TableProps> = ({ datas, loading }) => {
  const data: UserCours[] = datas;
  const addressColumns = [
    {
      title: "COURSE NAME",
      dataIndex: ["course", "name"],
      key: "name",
      width: "25%",
    },
    {
      title: "PRICE",
      render: (record: any) => {
        return `${record.course.price} ${record.course.currency}`;
      },
      key: "price",
      width: "25%",
    },

    {
      title: "remarks",
      render: (record: any) => {
        return record.remarks !== "none" ? (
          <Tooltip color="volcano" title={record?.remarks}>
            <Tag color={"#f50"}>{record.remarks.slice(0, 25)}</Tag>{" "}
          </Tooltip>
        ) : (
          ""
        );
      },
      key: "remarks",
      width: "25%",
    },
    {
      title: "CREATED",
      render: (record: any) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },
      key: "createdAt",
      width: "25%",
    },
  ];
  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Card style={{ marginTop: "1.5rem" }}>
        {" "}
        <Tag color={"red"}> COURSES</Tag>
      </Card>
      <Table
        className={styles.table}
        columns={addressColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default DetailedUserCoursesTable;
