import { Button, Checkbox, Form, Input, Radio, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { UploadOutlined } from '@ant-design/icons';
import { IService } from '../../interfaces/adminInterfaces';
import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { API_IMAGE_URL } from '../../config';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';


interface IUpdateServiceFormProps {
    service: IService
    id: number
}


interface IFormValues {
    serviceName: string
    description: string
    order: number
    categoryIds: CheckboxValueType[]
    image: File | null
    approval: 'requested' | 'declined' | 'accepted'
    status: 'active' | 'passive'
}


const selectOptions = Array.from(Array(10), (_, index) => index + 1).map((number) => { return { value: number, label: number } })

const UpdateServiceForm: React.FC<IUpdateServiceFormProps> = ({ service, id }) => {

    const [categories, setCategories] = useState<{ id: number, categoryName: string }[]>([]);
    const [imagePath, setImagePath] = useState(service.imgPath ? `${API_IMAGE_URL}/${service.imgPath}` : null)
    const [formData, setFormData] = useState<IFormValues>({
        serviceName: service.serviceName,
        description: service.description,
        order: service.order,
        categoryIds: service.spCategories.map((cat) => cat.id),
        image: null,
        approval: service.approval,
        status: service.status
    })

    const getCategories = () => {
        adminService.getSpCategories()
            .then((res) => {
                setCategories(res.data)
            })
            .catch(err => alertNotification('error', err.message))
    }

    const onFinish = () => {
        if (!formData.serviceName || !formData.description || !formData.order || !formData.categoryIds.length || !formData.approval || !formData.status ) return alertNotification('error', 'Fill the all fields')
        if (!service.imgPath && !formData.image) return alertNotification('error', 'Please upload an image')
        const form = new FormData()
        form.append('serviceName', formData.serviceName)
        form.append('description', formData.description)
        form.append('order', formData.order.toString())
        form.append('categoryIds', formData.categoryIds.toString())
        form.append('image', formData.image as File)
        form.append('approval', formData.approval)
        form.append('status', formData.status)

        adminService.updateService(id, form)
            .then((res) => {
                setImagePath(`${API_IMAGE_URL}/${res.data.imgPath}`)
                setFormData({
                    serviceName: res.data.serviceName,
                    description: res.data.description,
                    order: res.data.order,
                    categoryIds: res.data.spCategories.map((sp) => sp.id),
                    image: null,
                    approval: res.data.approval,
                    status: res.data.status
                })
                alertNotification('success', 'Bilgiler başarıyla güncellendi')
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        getCategories()
    }, [])

    return (
        <>
            <Form
                onFinish={onFinish}
                wrapperCol={{ span: 6 }}
                labelCol={{ span: 1.5 }}
            >
                <Form.Item
                    label='Service Name'
                    name='serviceName'
                    labelCol={{ span: 1.5 }}
                // rules={[{ required: true, message: 'Service name zorunlu alandır.' }]}
                >
                    <Input
                        value={formData.serviceName}
                        onChange={(e) => setFormData({ ...formData, serviceName: e.target.value })}
                        defaultValue={service.serviceName}
                    />
                </Form.Item>
                <Form.Item
                    label='Order'
                    name='order'
                // rules={[{ required: true, message: 'Order zorunlu alandır.' }]}
                >
                    <Select
                        onChange={(value) => setFormData({ ...formData, order: value })}
                        options={selectOptions}
                        style={{ width: '12vw' }}
                        value={formData.order}
                        defaultValue={service.order}
                    />
                </Form.Item>
                <Form.Item
                    label='Categories'
                >
                    <Checkbox.Group
                        options={categories.map((category) => { return { label: category.categoryName, value: category.id } })}
                        onChange={(checkedValues) => setFormData({ ...formData, categoryIds: checkedValues })}
                        value={formData.categoryIds}
                        defaultValue={service.spCategories.map((cat) => cat.id)}
                    >
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    label='Approval'
                    name='approval'
                >
                    <Select
                        options={[
                            { value: 'requested', label: 'Requested' },
                            { value: 'accepted', label: 'Accepted' },
                            { value: 'declined', label: 'Declined' }
                        ]}
                        value={formData.approval}
                        onChange={(value) => setFormData({ ...formData, approval: value })}
                        defaultValue={formData.approval}
                    />
                </Form.Item>
                <Form.Item
                    label='Status'
                    name='status'
                >
                    <Radio.Group
                        defaultValue={'active'}
                    >
                        <Radio value='active'>Active</Radio>
                        <Radio value='passive'>Passive</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Image"
                    name="image"
                // rules={[{ required: true, message: 'Image zorunlu alandır.' }]}
                >
                    {
                        imagePath ?
                            <img
                                src={imagePath}
                                height={250}
                                width={250}
                                alt='imagePath'
                            /> :
                            <p>No image provided</p>
                    }
                    <br />
                    <Upload
                        accept='.png, .jpg, .jpeg, .webp, .svg'
                        beforeUpload={(file) => {
                            setFormData({ ...formData, image: file });
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                    </Upload>
                </Form.Item>

                <Editor
                    // value={formValues.description}
                    onEditorChange={(newValue, editor) => {
                        setFormData({ ...formData, description: newValue })
                    }}
                    initialValue="<p>Hizmet Açıklaması giriniz....</p>"
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                />
                <Form.Item
                    style={{ marginTop: '1vw' }}
                >
                    <Button
                        htmlType='submit'
                        type='primary'

                    > Submit </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default UpdateServiceForm