import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";

import AuthContext from "../../context/AuthContext";
import { LoginDataInterface } from "../../interfaces/authInterfaces";
import rexvenLogo from "../assets/rexven-logo.svg";

import styles from "./LoginForm.module.css";

const LoginForm: React.FC = () => {
  // extracting the pathname and query params from the url
  // to redirect url after successfull login
  let location = useLocation();
  let redirectPath = location.state && location.state.from.pathname;
  let redirectSearch = location.state && location.state.from.search;
  let redirectPage = redirectPath + redirectSearch;

  const { login } = useContext(AuthContext);

  const onFinish = async (values: LoginDataInterface) => {
    login?.(values, redirectPage);
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <Form
      className={styles.form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
    >
      <Form.Item>
        <img src={rexvenLogo} alt="rexven-logo" className={styles.logo} />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input a valid email!",
          },
        ]}
      >
        <Input className={styles.formInput} placeholder="e-mail" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password className={styles.formInput} placeholder="password" />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{ span: 24 }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 16 }}>
        <Button type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
