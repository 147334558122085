import React, { useState } from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Select,
  Upload,
  Card,
  Spin
} from 'antd';
import styles from "./addContentForm.module.css"
import { useEffect } from "react";
import { Sections } from '../../interfaces/adminInterfaces';
import adminService from '../../services/adminService';
import alertNotification from '../../utils/alertNotification';
import { loadavg } from 'os';



const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const normFile = (e: any) => {
  // console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};


interface addContentFormInterface {
  section: Sections;
  setOpenedFormId: (id: number) => void
  courseLocation: string;
}
interface sectionForm {
  contentName: string;
  notes: string;
  path: string;
}

const AddContentForm: React.FC<addContentFormInterface> = ({ section, setOpenedFormId, courseLocation }) => {
  const [form] = Form.useForm();
  const [sectionForm, setSectionForm] = useState<sectionForm>({
    contentName: "",
    notes: "",
    path: "",
  })
  const [loading, setLoading] = useState<boolean>(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSectionForm({ ...sectionForm, [name]: value });
  }
  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };
  const handleFormSubmit = () => {
    let location = courseLocation + "/" + section.sectionLocation
    setLoading(true);

    adminService.addContent(section.id, location, sectionForm).then((res) => {
      setLoading(false);
      // console.log('res', res);
      alertNotification('success', 'Basarıyla Eklendi')
      form.resetFields();
      setOpenedFormId(0)
    }).catch((err) => {
      // console.log(err)
      setLoading(false);
      alertNotification('error', err.status.message)
    });
  };

  useEffect(() => {
    // console.log("heyyo", section)
  }, [])
  return (
    <>

      <Card >
        <Form
          name="validate_other"
          {...formItemLayout}
          onFinish={handleFormSubmit}
          form={form}
        >

          <Form.Item
            label="Content Name"
            name="contentName"
            rules={[{ required: true, message: 'Please input Section Name!' }]}
          >
            <Input name="contentName" onChange={handleInputChange} />
          </Form.Item>

          <Form.Item
            label="Notes"
            name="notes"
          >
            <Input name="notes" onChange={handleInputChange} />
          </Form.Item>

          <Form.Item
            label="Path"
            name="path"
            rules={[{ required: true, message: 'Please input Video Name!' }]}
          >
            <Input name="path" onChange={handleInputChange} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {loading && <Spin size='large' />}
          </Form.Item>
        </Form>
      </Card>

    </>
  )
}
export default AddContentForm