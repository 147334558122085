import { Skeleton, Table, Pagination } from "antd";
import type { PaginationProps } from "antd";

import { Affiliates, Coupons } from "../../../interfaces/adminInterfaces";
import alertNotification from "../../../utils/alertNotification";

import columnsList from "../ColumnsLists";
import styles from "./Tables.module.css";

interface TableProps {
  datas: Coupons[];
  loading: boolean;
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  totalPageCount: number;
  deleteCoupon: Function;
  affiliates: Affiliates[];
}

const CouponsTable: React.FC<TableProps> = ({
  datas,
  loading,
  current,
  setCurrent,
  totalPageCount,
  deleteCoupon,
  affiliates
}) => {
  const data: Coupons[] = datas;

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  const handleDelete = (
    event: React.MouseEvent,
    id?: number,
    valid?: boolean
  ) => {
    if (+(event.target as HTMLElement).id === id && valid === false) {
      alertNotification("warning", "Coupon is already INVALID");
    } else if (
      +(event.target as HTMLElement).id === id &&
      valid === true &&
      // eslint-disable-next-line no-restricted-globals
      confirm("Sure To DELETE")
    ) {
      deleteCoupon(id);
    }
  };

  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Table
        className={styles.table}
        columns={columnsList.couponsColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 2000 }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleDelete(event, record?.id, record?.valid);
            },
          };
        }}
      />
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={totalPageCount}
      />
    </>
  );
};

export default CouponsTable;
