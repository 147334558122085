import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";

import CoursesTable from "../components/tables/pageTables/CoursesTable";

import { Courses as DataType } from "../interfaces/adminInterfaces";
import { Button } from "antd";
import { Link } from "react-router-dom";
import AddNewCourse from "../components/addNewCourse/AddNewCourse";

const Courses: React.FC = () => {
  const [current, setCurrent] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [courses, setCourses] = useState<DataType[]>([]);
  const [addCourseDisplay, setAddCourseDisplay] = useState<boolean>(false);

  //fetch all users
  const getCourses = (filterValue: string | undefined) => {
    setLoading(true);
    AdminServices.getCourses(current, 10, filterValue)
      .then((res) => {
        setCourses(res.data.db);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCourses("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
    <Button
    onClick={() => setAddCourseDisplay(!addCourseDisplay)}
    >Kurs Ekle</Button>
    {
      addCourseDisplay ? <AddNewCourse />: null
    }
      {/* <Filter
        placeholder={"E-mail, Ad veya Soyad"}
        setCurrent={setCurrent}
        getPageDatas={getCourses}
      /> */}
      <CoursesTable
        datas={courses}
        loading={loading}
        setCurrent={setCurrent}
        current={current}
        totalPageCount={totalPageCount}
      />
    </>
  );
};

export default Courses;
