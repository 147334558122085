import Table, { ColumnsType } from 'antd/lib/table'
import { EditOutlined } from '@ant-design/icons';
import { BlogPosts } from '../../../interfaces/adminInterfaces'
import React from 'react'
import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import alertNotification from '../../../utils/alertNotification';
import adminService from '../../../services/adminService';

interface BlogPostsTableProps {
    lang: 'en' | 'tr'
    blogPosts: BlogPosts[]
    getBlogPosts: () => void
}

const BlogPostsTable: React.FC<BlogPostsTableProps> = ({ blogPosts, getBlogPosts, lang }) => {
    async function deleteBlogPost(id: number) {
        try {
            await adminService.deleteBlogPost(lang, id)
            getBlogPosts()
        } catch (e) {
            alertNotification('error', 'Blog yazısı silinirken bir hata oluştu.')
        }
    }

    const columns: ColumnsType<BlogPosts> = [
        {
            title: 'Lang',
            render: () => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={`/flags/${lang}-rounded.png`} alt={lang} width="20" style={{ margin: '0 5px' }} />
                    <span style={{ fontWeight: 'bold' }}>{lang.toUpperCase()}</span>

                </div>
            )
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",

        },
        {
            title: "Category",
            dataIndex: "blogCategories",
            key: "blogCategories"
        },
        {
            title: "View Count",
            dataIndex: "viewCount",
            key: "viewCount"
        },
        {
            title: "Author Name",
            dataIndex: "authorName",
            key: "authorName"
        },
        {
            title: "Meta Description",
            dataIndex: "metaDescription",
            key: "metaDescription",
            render: (_, record) => (
                <Space size="middle">
                    {
                        <p>{record.metaDescription ? record.metaDescription.slice(0, 20) + '...' : record.metaDescription}</p>
                    }
                </Space>
            )
        },
        {
            title: "Meta Keywords",
            dataIndex: "metaKeywords",
            key: "metaKeywords",
            render: (_, record) => (
                <Space size="middle">
                    {
                        <p>{record.metaKeywords ? record.metaKeywords.slice(0, 20) + '...' : record.metaKeywords}</p>
                    }
                </Space>
            )
        },
        {
            title: "Meta Title",
            dataIndex: "metaTitle",
            key: "metaTitle",
            render: (_, record) => (
                <Space size="middle">
                    {
                        <p>{record.metaTitle ? record.metaTitle.slice(0, 20) + '...' : record.metaTitle}</p>
                    }
                </Space>
            )
        },
        {
            title: "Edit",
            render: (_, record) => (
                <Space size="middle">
                    {<Link to={`/blog-posts/${lang}/${record.id}`}>
                        <EditOutlined style={{ fontSize: "24px" }} />
                    </Link>}
                </Space>
            ),
            key: "UpdateDelete",
        },

        {
            title: "Delete",
            render: (_, record) => (
                <Space size="middle">
                    {<Button style={{ backgroundColor: "red", color: "white" }} onClick={() => deleteBlogPost(record.id)}>Delete</Button>}
                </Space>
            ),
            key: "delete",
        },

    ]


    return (
        <>
            <Table dataSource={blogPosts} columns={columns} />
        </>
    )
}

export default BlogPostsTable;

