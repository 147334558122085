import { Button, Form, Input } from "antd";
import { useState } from "react";
import { IAddCourseValues } from "../../interfaces/adminInterfaces";
import adminService from "../../services/adminService";

function AddCourse() {
  const [formValues, setFormValues] = useState<IAddCourseValues>({
    courseName: "",
    description: "",
    courseLocation: "",
  });

  const submitForm = () => {
    adminService
      .addCourse(formValues)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ marginTop: "1vw" }}>
      <p>
        Burada eklenen eğitimler otomatik olarak ücretsiz kabul edilmektedir.
        Ücretli bir eğitim eklenecekse lütfen yazılım ekibiyle iletişime
        geçiniz.
      </p>
      <p>
        Location girerken daha önce dosya dizinine eklenen kurs klasörünün
        ismini başında / koymadan ekleyiniz.
      </p>
      <p>
        Eklenecek kurs resmi için yazılım ekibiyle iletişime geçiniz. Resim
        yüklenmeden kurs girişi yapmayınız. Kurs resminin ismi, girilen
        courseName ile aynı olmalıdır ve .webp uzantılı olmalıdır.
      </p>
      <Form onFinish={submitForm}>
        <Form.Item
          label="courseName"
          name="courseName"
          rules={[{ required: true, message: "courseName boş olamaz!" }]}
        >
          <Input
            type="text"
            onChange={(e) =>
              setFormValues({ ...formValues, courseName: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label="location"
          name="location"
          rules={[{ required: true, message: "location boş olamaz!" }]}
        >
          <Input
            type="text"
            onChange={(e) =>
              setFormValues({ ...formValues, courseLocation: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label="description"
          name="description"
          rules={[{ required: true, message: "description boş olamaz!" }]}
        >
          <Input
            type="text"
            onChange={(e) =>
              setFormValues({ ...formValues, description: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddCourse;
