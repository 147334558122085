import UpdateCategoryForm from '../components/deloitteBsr/updateCategoryForm';
import DeloitteAddCategoryForm, { IDeloitteAmazonCategoryCreateFormValues } from '../components/deloitteBsr/addCategoryForm';
import { DeloitteCountry, IDeloitteAmazonCategoryResponse } from '../interfaces/adminInterfaces';
import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';
import { Button, Modal, Select, Table, Form } from 'antd';
import  { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const countries = [
    { label: "USA", value: "usa" },
    { label: "Canada", value: "canada" },
    { label: "France", value: "france" },
    { label: "Britain", value: "britain" },
    { label: "Spain", value: "spain" },
    { label: "Italy", value: "italy" },
    { label: "Mexico", value: "mexico" },
    {label: 'Japan', value: 'japan'}
];

export interface IUpdateCategoryFormValues {
    categoryId: number;
    categoryName: string;
    id: number;
}


export default function DeloitteAmazonBsr() {
    const columns = [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Kategori",
          dataIndex: "categoryName",
          key: "categoryName",
    
        },
        {
          title: "Ülke",
          dataIndex: "country",
          key: "country",
    
        },
        {
          title: "Kategori Id",
          render: (record: any) => {
            return <div>{record.categoryId ? record.categoryId : '-'}</div>
          }
        },
    
        {
          title: "Bsr Rank",
          render: (record: any) => {
            return <Link to={`/deloitte-amazon-bsr/${record.id}`}>Bsr Detay</Link>;
          },
          key: "addressLine",
    
        },
        {
          title: "Düzenle",
          render: (record: any) => {
            return <Button
            style={{ border: "1px solid blue", color: "blue" }}
            onClick={() => openUpdateCategoryModal(record)}
            >
                Düzenle
            </Button>;
          },
          key: "addressLine",
    
        },
        {
          title: "Sil",
          render: (record: any) => {
            return <Button style={{ border: "1px solid red", color: "red" }}
            onClick={() => openDeleteCategoryModal(record.id)}
            >Sil</Button>;
          },
          key: "addressLine",
    
        },
    ];

    const [country, setCountry] = useState<DeloitteCountry>('usa');
    const [categories, setCategories] = useState<IDeloitteAmazonCategoryResponse['data']>([]);
    const [newCategoryModal, setNewCategoryModal] = useState<boolean>(false);
    const [deleteCategoryModal, setDeleteCategoryModal] = useState<boolean>(false);
    const [updateCategoryModal, setUpdateCategoryModal] = useState<boolean>(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
    const [categoryIdToUpdate, setCategoryIdToUpdate] = useState<number | null>();

    const [categoryCreateForm] = Form.useForm();
    const [categoryUpdateForm] = Form.useForm();

    async function getDeloitteAmazonCategory(){
        try {
            const categoryData = await adminService.getDeloitteAmazonCategories(country);
            setCategories(categoryData.data);
        } catch(err: any) {
            alertNotification("error", err.response?.data?.message || err.message || "Kategoriler getirilemedi")
        }
    }

    async function createAmazonCategory(formValues: IDeloitteAmazonCategoryCreateFormValues){
        try{
            await adminService.createDeloitteAmazonCategry(formValues);
            setNewCategoryModal(false);
            getDeloitteAmazonCategory();
        } catch(err: any) {
            alertNotification("error", err.response?.data?.message || err.message || "Kategoriler getirilemedi")
        }
    }

    async function deleteCategory(){
        try{
            await adminService.deleteDeloitteAmazonCategory(selectedCategoryId!);
            setDeleteCategoryModal(false);
            getDeloitteAmazonCategory();
        } catch(err: any) {
            alertNotification("error", err.response?.data?.message || err.message || "Kategori silinirken bir hata oluştu")
        }
    }

    async function updateCategory(values: IUpdateCategoryFormValues){
        try{
            await adminService.updateDeloitteAmazonCategory(categoryIdToUpdate!, values);
            setUpdateCategoryModal(false);
            getDeloitteAmazonCategory();
            categoryUpdateForm.resetFields();
        } catch(err: any) {
            alertNotification("error", err.response?.data?.message || err.message || "Kategori güncellenirken bir hata oluştu")
        }
    }

    function openNewCategoryModal(){
        setNewCategoryModal(true);
        categoryCreateForm.setFieldValue('country', country);
    }
    
    function closeCategoryCreationModal(){
        setNewCategoryModal(false);
        categoryCreateForm.resetFields();
    }

    function openDeleteCategoryModal(categoryId: number){
        setDeleteCategoryModal(true);
        setSelectedCategoryId(categoryId);
    }

    function closeDeleteCategoryModal(){
        setDeleteCategoryModal(false);
        setSelectedCategoryId(null);
    }

    function openUpdateCategoryModal(record: IUpdateCategoryFormValues){
        categoryUpdateForm.resetFields()
        setUpdateCategoryModal(true);
        setCategoryIdToUpdate(record.id);
        categoryUpdateForm.setFieldValue('categoryId', record.categoryId);
        categoryUpdateForm.setFieldValue('categoryName', record.categoryName);
    }

    function closeUpdateCategoryModal(){
        categoryUpdateForm.resetFields();
        setCategoryIdToUpdate(null);
        setUpdateCategoryModal(false);
    }

    useEffect(() => {
        getDeloitteAmazonCategory();
    }, [country]);

  return (
    <div>
        <div style={{display: 'flex', justifyContent:'space-between'}}>
            <Select 
                options={countries}
                onChange={(value: DeloitteCountry) => setCountry(value)}
                placeholder='Ülke seçiniz'
                style={{marginBottom: '1vh', minWidth: '8%', textAlign: 'center'}}
                defaultValue={'usa'}
            />
            <Button
                onClick={openNewCategoryModal}
            >
                Yeni Kategori Ekle
            </Button>
        </div>
            <Modal
                open={newCategoryModal}
                onCancel={closeCategoryCreationModal}
                width='60%'
                footer={null}
                style={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto", padding: '2.5vw' }}
                title='New Category'
            >
                <DeloitteAddCategoryForm
                    formSubmit={createAmazonCategory}
                    formReference={categoryCreateForm}
                />
            </Modal>
            <Modal
                width='60%'
                open={deleteCategoryModal}
                onCancel={closeDeleteCategoryModal}
                title='Delete Category'
                onOk={deleteCategory}
            >
                Bu kategori ve diğer kayıtları silinecektir. Silmek istediğinizden emin misiniz?
            </Modal>
            <Modal
                width='50%'
                open={updateCategoryModal}
                onCancel={closeUpdateCategoryModal}
                title='Update Category'
                footer={null}
            >
                <UpdateCategoryForm
                    formReference={categoryUpdateForm}
                    submitForm={updateCategory}
                />
            </Modal>
        <Table columns={columns} dataSource={categories} rowKey={'id'}/>
    </div>
  )
}
