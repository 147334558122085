let API_AUTH_URL: string;
let API_ADMIN_URL: string;
let API_IMAGE_URL: string;
let PAYMENT_URL: string;
let ADMIN_SECRET: string;
let IMAGE_URL: string;
let SP_IMAGE_URL: string;
let API_REXVEN_URL: string;

if (process.env.REACT_APP_NODE_ENV === "production") {
  API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL_PROD
  API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL_PROD
  API_IMAGE_URL = process.env.REACT_APP_IMAGE_URL_PROD
  ADMIN_SECRET = process.env.REACT_APP_ADMIN_SECRET
  SP_IMAGE_URL = process.env.REACT_APP_AWS_S3_URL_PROD
  API_REXVEN_URL = process.env.REACT_APP_API_REXVEN_URL_PROD
}
else if (process.env.REACT_APP_NODE_ENV === "test") {
  API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL_TEST
  API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL_TEST
  API_IMAGE_URL = process.env.REACT_APP_IMAGE_URL_TEST
  ADMIN_SECRET = process.env.REACT_APP_ADMIN_SECRET
  SP_IMAGE_URL = process.env.REACT_APP_AWS_S3_URL_TEST
  API_REXVEN_URL = process.env.REACT_APP_API_REXVEN_URL_TEST
}
else {
  API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL_DEV
  API_ADMIN_URL = process.env.REACT_APP_API_ADMIN_URL_DEV
  API_IMAGE_URL = process.env.REACT_APP_IMAGE_URL_DEV
  ADMIN_SECRET = process.env.REACT_APP_ADMIN_SECRET
  IMAGE_URL = process.env.REACT_APP_IMAGE_URL_DEV
  SP_IMAGE_URL = process.env.REACT_APP_AWS_S3_URL_TEST
  API_REXVEN_URL = process.env.REACT_APP_API_REXVEN_URL_DEV
}
PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL

export { API_AUTH_URL, API_ADMIN_URL, API_IMAGE_URL, PAYMENT_URL, ADMIN_SECRET, IMAGE_URL, SP_IMAGE_URL, API_REXVEN_URL }