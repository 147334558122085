import { useNavigate } from "react-router-dom";
import { Skeleton, Table, Pagination } from "antd";
import type { PaginationProps } from "antd";

import { Courses } from "../../../interfaces/adminInterfaces";

import columnsList from "../ColumnsLists";
import styles from "./Tables.module.css";

interface TableProps {
  datas: Courses[];
  loading: boolean;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  totalPageCount: number;
}

const CoursesTable: React.FC<TableProps> = ({
  datas,
  loading,
  setCurrent,
  current,
  totalPageCount,
}) => {
  const navigate = useNavigate();

  const data: Courses[] = datas;

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  return loading ? (
    <>
      <Skeleton key={1} active />
    </>
  ) : (
    <>
      <Table
        className={styles.table}
        columns={columnsList.coursesColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 960 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/courses/${record.id}`);
            },
          };
        }}
      />
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={totalPageCount}
      />
    </>
  );
};

export default CoursesTable;
