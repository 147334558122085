import { Form, Input, Button, Row } from "antd";

import alertNotification from "../../utils/alertNotification";
import styles from "./Filter.module.css";

interface FilterProps {
  // setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  getPageDatas?: Function;
  placeholder: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchTerm: string;
  setPaymentStatus?: React.Dispatch<React.SetStateAction<string>>;
}

const Filter: React.FC<FilterProps> = ({
  placeholder,
  setCurrent,
  getPageDatas,
  setSearchTerm,
  searchTerm,
  setPaymentStatus,
}) => {
  //
  const [form] = Form.useForm<{ name: string }>();
  const term = Form.useWatch("search", form);

  //fetch new datas,then set pagination and new datas
  const handleFilter = (values: any) => {
    setPaymentStatus && setPaymentStatus("");
    setCurrent(1);
    setSearchTerm(term);
    getPageDatas && getPageDatas(term, "");
  };

  const onFinishFailed = (errorInfo: any) => {
    alertNotification("error", errorInfo);
  };

  return (
    <>
      <Form
        className={styles.filter}
        onFinish={handleFilter}
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <div className={styles.inputWrapper}>
            <Form.Item style={{ flex: "1" }} name="search">
              <Input className={styles.filterInput} placeholder={placeholder} />
            </Form.Item>
            <Button
              className={styles.submitButton}
              type="primary"
              danger
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default Filter;
