import { Sections } from "../../interfaces/adminInterfaces";
import { Button, Form, Input, Space, Card } from 'antd';
import styles from "./addCourse.module.css"
import { useEffect } from "react";


interface addCourseInterface {
  sections: Sections[];
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  addSection: () => void

}

const AddCourse: React.FC<addCourseInterface> = ({ sections, handleInputChange, addSection }) => {

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };
  useEffect(() => {
    // console.log(sections)
  }, [])
  return (
    <>

      <Card className={styles.addCard}>
        <Form

          onFinish={addSection}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item

            label="Section Name"
            name="sectionName"
            rules={[{ required: true, message: 'Please input Section Name!' }]}
          >
            <Input name="sectionName" onChange={handleInputChange} />
          </Form.Item>

          <Form.Item
            label="Section Order"
            name="sectionOrder"
            rules={[{ required: true, message: 'Please input Section Order!' }]}
          >
            <Input name="sectionOrder" onChange={handleInputChange} />
          </Form.Item>

          <Form.Item
            label="Location"
            name="sectionLocation"
            rules={[{ required: true, message: 'Please input Location!' }]}
          >
            <Input name="sectionLocation" onChange={handleInputChange} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Add Section
            </Button>
          </Form.Item>
        </Form>
      </Card>

    </>
  )
}
export default AddCourse