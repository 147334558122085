import { ColumnsType } from 'antd/lib/table'
import { Table } from 'antd'
import React from 'react'
import { IContactFormAnswers } from '../../../interfaces/adminInterfaces'
import Loading from '../../../components/loader/Loading'

interface IContactFormAnswersTableProps {
    answers: IContactFormAnswers[]
    loading: boolean
}

const ContactFormAnswersTable: React.FC<IContactFormAnswersTableProps> = ({ answers, loading }) => {

    const columns: ColumnsType<IContactFormAnswers> = [
        {
            title: 'Id',
            key: 'id',
            dataIndex: 'id'
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Phone Number',
            key: 'phoneNumber',
            dataIndex: 'phoneNumber'
        },
        {
            title: 'Subject',
            key: 'subject',
            dataIndex: 'subject'
        },
        {
            title: 'Message',
            key: 'message',
            dataIndex: 'message'
        },
    ]
    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Table
                            columns={columns}
                            dataSource={answers}
                            pagination={false}
                        />

                    </>
            }

        </>
    )
}

export default ContactFormAnswersTable