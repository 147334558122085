import ExclusiveProductTable from "../components/tables/pageTables/ExclusiveProductTable";
import adminService from "../services/adminService";
import { Row, Form, Input, Button } from "antd";
import React, { useEffect, useState } from "react";

import styles from "../components/filter/Filter.module.css";
import { ExclusiveProduct } from "../interfaces/adminInterfaces";
import alertNotification from "../utils/alertNotification";

const ExclusiveProducts = () => {
  const [asin, setAsin] = useState("");
  const [current, setCurrent] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [form] = Form.useForm();
  const [products, setProducts] = useState<ExclusiveProduct[]>();
  const getAllProducts = () => {
    adminService
      .getExcProducts(current, asin)
      .then((res) => {
        setProducts(res.data.data);
        setTotalPageCount(res.data.totalPageCount);
      })
      .catch((err) => alertNotification("error", "Ürünler bulunamadı"));
  };

  const onFinish = (values: { asin: string }) => {
    setAsin(values.asin);
  };
  useEffect(() => {
    getAllProducts();
  }, [current, asin]);

  return (
    <div style={{ width: "100%" }}>
      <Form
        className={styles.filter}
        onFinish={onFinish}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <div className={styles.inputWrapper}>
            <Form.Item style={{ flex: "1" }} name="asin">
              <Input
                className={styles.filterInput}
                placeholder={"Aramak istediğiniz ürünün ASIN kodunu giriniz"}
              />
            </Form.Item>
            <Button
              className={styles.submitButton}
              type="primary"
              danger
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
      <a href="/create-exclusive-product">
        <Button
          style={{
            marginBottom: "20px",
          }}
        >
          Yeni Ürün Ekle
        </Button>
      </a>
      <ExclusiveProductTable
        datas={products!}
        totalPageCount={totalPageCount}
        current={current}
        setCurrent={setCurrent}
        getExclusiveProducts={getAllProducts}
      />
    </div>
  );
};

export default ExclusiveProducts;
