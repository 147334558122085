import { Card, Skeleton, Table, Tag, Tooltip } from "antd";

import { Invoice } from "../../../interfaces/adminInterfaces";

import styles from "../pageTables/Tables.module.css";

interface TableProps {
  datas: Invoice[];
  loading: boolean;
}

const DetailedUserInvoicesTable: React.FC<TableProps> = ({
  datas,
  loading,
}) => {
  const data: Invoice[] = datas;
  const invoiceColumns = [
    {
      title: "TYPE",
      render: (record: any) => {
        return record.type === "course" ? (
          <Tag color="#87d068"> {record.type}</Tag>
        ) : (
          record.type
        );
      },
      key: "type",
      width: "8%",
    },
    {
      title: "BILLING DATE",
      render: (record: any) => {
        return new Date(record?.billingDate)?.toLocaleString("tr-TR");
      },
      key: "billingDate",
      width: "8%",
    },
    {
      title: "PAYMENT",
      render: (record: any) => {
        if (record.paymentStatus === "SUCCESS") {
          return <Tag color="#87d068"> {record.paymentStatus}</Tag>;
        } else if (record.paymentStatus === "WAITING") {
          return <Tag color="#2db7f5"> {record.paymentStatus}</Tag>;
        } else {
          return <Tag color="#ff4d4f"> {record.paymentStatus}</Tag>;
        }
      },
      key: "paymentStatus",
      width: "8%",
    },
    {
      title: "PRICE",
      render: (record: any) => {
        return `${record.paidPrice} ${record.currency} `;
      },
      key: "paidPrice",
      width: "7%",
    },
    {
      title: "SUBSCRIBE",
      render: (record: any) => {
        return record.isSubscription === true ? (
          <Tag color="#87d068">SUBSC</Tag>
        ) : (
          <Tag color="#2db7f5"> PRODS</Tag>
        );
      },
      key: "isSubscription",
      width: "8%",
    },
    {
      title: "INVOICE PDF",
      render: (record: any) => {
        return (
          <>
            <Tooltip color="volcano" title={record?.invoicePdf}>
              <a href={record.invoicePdf} target="_blank" rel="noreferrer">
                {record?.invoicePdf?.slice(0, 30)}...
              </a>
            </Tooltip>
          </>
        );
      },
      key: "invoicePdf",
      width: "8%",
    },
    {
      title: "PLATFORM",
      render: (record: any) => {
        return record.platform === "iyzico" ? (
          <Tag color="#f50"> {record.platform}</Tag>
        ) : (
          record.platform
        );
      },
      key: "platform",
      width: "7%",
    },
    {
      title: "CREADTED",
      render: (record: any) => {
        return new Date(record?.createdAt)?.toLocaleString("tr-TR");
      },
      key: "createdAt",
      width: "8%",
    },
    {
      title: "NOTIFIED",
      dataIndex: ["isNotified"],
      key: "isNotified",
      width: "7%",
    },
    {
      title: "PLAN",
      render: (record: any) => {
        return record?.packagePlan?.duration === 1 ? (
          <Tag color="#f50">{record?.packagePlan?.package?.name} AYLIK</Tag>
        ) : (
          <Tag color="#87d068">{record?.packagePlan?.package?.name} YILIK</Tag>
        );
      },
      width: "12%",
    },
    {
      title: "COURSE",
      dataIndex: ["course", "name"],
      key: "course",
      width: "8%",
    },
    {
      title: "MANUEL",
      render: (record: any) => {
        return record.isManuel === true ? "YES" : "NO";
      },
      key: "isManuel",
      width: "6%",
    },
    {
      title: "CANCELED",
      render: (record: any) => {
        return record.isCanceled === true ? "YES" : "NO";
      },
      key: "isCanceled",
      width: "7%",
    },
  ];
  return loading ? (
    <>
      <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
      <Skeleton key={"3"} active />{" "}
    </>
  ) : (
    <>
      <Card>
        {" "}
        <Tag color={"red"}> INVOICES</Tag>
      </Card>
      <Table
        className={styles.table}
        columns={invoiceColumns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
        sticky
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default DetailedUserInvoicesTable;
