import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddCourse from '../components/courseDetails/addCourse';
import CourseDetailsCollapse from '../components/courseDetails/courseDetailsCollapse';
import { Sections } from '../interfaces/adminInterfaces';
import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';

const AdminService = adminService
interface sectionWillAdd {
    sectionName: string;
    sectionLocation: string;
    sectionOrder: number;
}

const CoursesDetail: React.FC = () => {

    const { courseId } = useParams()
    const [sections, setSections] = useState<Sections[]>([])
    const [courseLocation, setCourseLocation] = useState<string>('')
    const [sectionWillAdd, setSectionWillAdd] = useState<sectionWillAdd>({} as sectionWillAdd)

    const getSectionsAndContents = () => {
        if (!courseId) return alertNotification('error', 'Yanlış kurs id!')
        AdminService.getWholeContentByCourseId(+courseId)
            .then((res) => {
                setCourseLocation(res.data.courseLocation)
                setSections(res.data.sections)
            })
            .catch((err) => {
                // console.log(err)
                alertNotification('error', err.response.data.message)
            })
    }

    const updateContentNote = (id: number, notes: string) => {
        adminService.updateContentNote(id, { notes })
            .then(() => {
                getSectionsAndContents()
            })
            .catch((err) => {
                // console.log(err)
                alertNotification('error', err.message)
            })
    }
    const updateContentName = (id: number, contentName: string) => {
        adminService.updateContentName(id, { contentName })
            .then(() => {
                getSectionsAndContents()
            })
            .catch((err) => {
                // console.log(err)
                alertNotification('error', err.message)
            })
    }

    const addSection = () => {
        if (!courseId) return alertNotification('error', 'Yanlış kurs id!')
        // console.log(sectionWillAdd);
        adminService.addSection(+courseId, sectionWillAdd)
            .then(() => {
                getSectionsAndContents()
            })
            .catch((err) => {
                // console.log(err)
                alertNotification('error', err.message)
            })
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        if (name === 'sectionOrder') return setSectionWillAdd({ ...sectionWillAdd, [name]: +value })
        setSectionWillAdd({ ...sectionWillAdd, [name]: value })
    }

    useEffect(() => {
        getSectionsAndContents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <Row>
                <Col span={16}>
                    <CourseDetailsCollapse
                        sections={sections}
                        updateContentNote={updateContentNote}
                        updateContentName={updateContentName}
                        courseLocation={courseLocation}
                        getSectionsAndContents={getSectionsAndContents}
                    />
                </Col>
                <Col span={8}>
                    <AddCourse sections={sections}
                        handleInputChange={handleInputChange}
                        addSection={addSection}
                    />
                </Col>
            </Row>


        </>
    );
}
export default CoursesDetail