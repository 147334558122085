import React, { useEffect } from "react";
import { Button, Form, Input, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import adminService from "../../services/adminService";
import alertNotification from "../../utils/alertNotification";

interface Props {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  product: any;
  getProducts: () => Promise<void>
}
const UpdateProductForm: React.FC<Props> = ({ product,getProducts,setShowModal }) => {
  const [form] = Form.useForm();
  console.log(product.images.join(','))

  const handleSubmit = async (values: any) => {

    values.images = values.images.includes(',') ? values.images.split(",") : typeof(values.images) === 'object' ? values.images :  [values.images];

    values.attributes =values.attributes.includes(',') ? values.attributes.split(",") : [values.attributes];
    try {
      await adminService.updateMarketplaceProduct(product.id, values);
      setShowModal(false);
      alertNotification("success", "Ürün başarıyla güncellendi");
      getProducts()
    } catch (error:any) {
      alertNotification("error", error.response.data.message);
    }

  };

  useEffect(() => {
    form.setFieldsValue(product);
  } , [product])
  
  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18, offset: 1 }}
        onFinish={handleSubmit}
      >
        <Form.Item name={"title"} label="Başlık" initialValue={product.title}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Resimler:"
          name={"images"}
          initialValue={product?.images.join(",")}
        >
          <Input placeholder="Lütfen resim yollarının arasına virgül koyun" />
        </Form.Item>
        <Form.Item
          label="Attributes:"
          name={"attributes"}
          initialValue={product.attributes.join(",")}
        >
          <Input placeholder="Lütfen attribute'lerin arasına virgül koyun" />
        </Form.Item>
        <Form.Item
          label="Tarif No:"
          name={"tarifNumber"}
          initialValue={product.tarifNumber}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Tasarım Var?" name={"needsDesign"}>
          <Checkbox defaultChecked={product.needsDesign} />
        </Form.Item>
        <Form.Item label="Açıklama" name="description">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Onayla
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateProductForm;
