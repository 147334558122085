import { Skeleton, Table } from "antd";

import { PackagePlans } from "../../../interfaces/adminInterfaces";

import columnsList from "../ColumnsLists";
import styles from "./Tables.module.css";

interface TableProps {
    datas: PackagePlans[];
    loading: boolean;
}

const PackagesTable: React.FC<TableProps> = ({
    datas,
    loading,
}) => {

    const data: PackagePlans[] = datas;



    return loading ? (
        <>
            <Skeleton key={"1"} active /> <Skeleton key={"2"} active />{" "}
            <Skeleton key={"3"} active />{" "}
        </>
    ) : (
        <>
            <Table
                className={styles.table}
                columns={columnsList.packagesColumns}
                dataSource={data}
                rowKey="id"
                summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
                sticky
                bordered
            // scroll={{ x: 2500 }}

            />
        </>
    );
};

export default PackagesTable;
