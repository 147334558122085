import { Skeleton, Table, Pagination } from "antd";
import type { PaginationProps } from "antd";
import { IAnnouncements } from "@src/interfaces/adminInterfaces";
import styles from "./Tables.module.css";
import columnsList from "../ColumnsLists";


interface TableProps {
    data: IAnnouncements[];
    loading: boolean;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>
    totalAnnouncementCount: number
};

export default function AnnouncementsTable({ data, loading, page, setPage, totalAnnouncementCount }: TableProps) {
    const onChange: PaginationProps["onChange"] = (value) => {
        setPage(value);
    };

    return loading ? (
        <div>
            <Skeleton key={"1"} active />
            <Skeleton key={"2"} active />{" "}
            <Skeleton key={"3"} active />{" "}
        </div>
    ) : (
        <div>
            <Table
                className={styles.table}
                columns={columnsList.announcementsColumns}
                dataSource={data}
                pagination={false}
                rowKey="id"
                summary={() => <Table.Summary fixed={"top"}></Table.Summary>}
                sticky
                bordered
                scroll={{ x: 2000 }}
            // onRow={(record) => {
            //     return {
            //         onClick: (event) => {
            //             handleDelete(event, record?.id, record?.valid);
            //         },
            //     };
            // }}
            />
            <Pagination
                showSizeChanger={false}
                current={page}
                onChange={onChange}
                total={totalAnnouncementCount}
            />
        </div>
    )
};
