import React, { useEffect, useState, useRef, Provider } from 'react';
import { Form, Input, Upload, Button, Radio, Checkbox, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import adminService from '../services/adminService';
import { useNavigate, useParams } from 'react-router-dom';
import alertNotification from '../utils/alertNotification';
import { API_IMAGE_URL, SP_IMAGE_URL } from "../config"
import { Editor } from '@tinymce/tinymce-react'
import { IService, Providers } from '@src/interfaces/adminInterfaces';
import { EditOutlined, DeleteFilled } from '@ant-design/icons';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import TextArea from 'antd/es/input/TextArea';



const EditProvider: React.FC = () => {
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);


  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currImagePath, setCurrImagePath] = useState<File | string | undefined>('')
  const [edit, setEdit] = useState<number[]>([])
  const [key, setKey] = useState<number>()
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [currServices, setCurrServices] = useState<{ serviceId: number, serviceFee: number | null, serviceName?: string | null, description: string | null }[]>()

  const params = useParams();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<Providers>({
    id: '',
    name: '',
    description: '',
    profile_image: '',
    status: '',
    request_status: '',
    services: [],
    is_premium: null,
    email: ''
  })

  const getProvider = async (id?: number | string) => {

    try {
      setLoading(true);
      const res = await adminService.getProvider(id)
      setCurrServices(res.data?.services!)
      setFormValues(res.data)
      setCurrImagePath(res.data?.profile_image);
      form.setFieldsValue({
        name: res.data.name,
        status: res.data.status,
        description: res.data.description,
        id: res.data.id
      })
      return res.data
    } catch (err: any) {
      setError(true);
      alertNotification('error', err.response.data.message);
    }

  };

  const getServices = async (approval: 'requested' | 'declined' | 'accepted' | 'all', page: number, provider: any) => {
    try {
      const servicesInfo = await adminService.getServices(page, approval)
      const serviceIds = provider.services?.map((item: any) => item.serviceId);
      const services = servicesInfo.data?.data
      const filtered = services.filter((item: any) => !serviceIds?.includes(item.id));
      provider.services = []
      setFormValues(provider)
      setServices(filtered)
    } catch (err: any) {
      alertNotification('error', err.message)
    }

  }

  const deletService = async (serviceId: number | string) => {
    try {
      await adminService.deleteServiceFromProvider(serviceId, params.id!)

      // console.log(a)
      const providersServices = await getProvider(params.id);
      await getServices('accepted', 1, providersServices!);
    } catch (e) {
      alertNotification('error', 'Hizmet silinirken bir hata oluştu lütfen tekrar deneyiniz.')
    }
  }

  useEffect(() => {
    (async () => {
      const provider = await getProvider(params.id);
      await getServices('accepted', 1, provider!);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFormSubmit = () => {
    const formData = new FormData();
    // formData.append('id', String(formValues.id));
    formData.append('image', formValues.profile_image as File);
    formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    formData.append('status', String(formValues.status));
    formData.append('services', JSON.stringify(formValues.services))
    formData.append('is_premium', String(formValues.is_premium))

    adminService.updateProvider(Number(formValues.id), formData)
      .then((res) => {
        // console.log('res', res);
        alertNotification('success', 'Basariyla güncellendi')
        navigate(`/service-providers`);
        form.resetFields();
      })
      .catch((err) => {
        // console.log('err', err);
        alertNotification('error', err.response.data.message);
      });
  };


  return (
    <div style={{ display: 'flex', width: "100%", flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ marginBottom: '20px' }}>Hizmet Sağlayıcı Güncelleme</h2>

      <img src={`${SP_IMAGE_URL}/${currImagePath}`} width='10%' style={{ marginBottom: '20px' }} alt="addPhoto" />
      <Form
        style={{ width: "80%" }}
        onFinish={handleFormSubmit}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item label="id" name="id" style={{ display: 'none' }}>
          <Input
            value={formValues.id}
            style={{ display: 'none' }}
          />
        </Form.Item>

        <Form.Item label="Name" name="name">
          <Input
            value={formValues.name}
            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Radio.Group onChange={(e) => setFormValues({ ...formValues, status: e.target.value })} value={formValues.status}>
            <Radio value={'active'}>Active</Radio>
            <Radio value={'passive'}>Passive</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label='Is Premium'>
          <Checkbox
            checked={formValues.is_premium!}
            onChange={(e) => setFormValues({ ...formValues, is_premium: e.target.checked })}
          />
        </Form.Item>
        {
          currServices?.length ? (
            <Form.Item label='Hizmetler' style={{ display: 'flex', flexDirection: 'column', }}>
              {
                currServices.map((el: {
                  serviceId: number,
                  serviceName?: string | null,
                  serviceFee: number | null,
                  description: string | null
                }) => {
                  return (
                    <div key={el.serviceId} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                      {/* <label style={{ minWidth: '20%', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{el.serviceName} <span>:</span></label> */}
                      <div>{el.serviceName}</div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input disabled={!edit.includes(el.serviceId)} defaultValue={el.serviceFee!} style={{ width: '20%' }} onChange={(e) => {
                          const fee = e.target.value;
                          setFormValues(prevState => {
                            const updatedServices = [...prevState?.services!];
                            const index = updatedServices.findIndex(service => service.serviceId === el.serviceId);
                            if (index !== -1) {
                              updatedServices[index].serviceFee = Number(fee);
                            } else {
                              updatedServices.push({ serviceId: el.serviceId, serviceFee: Number(fee), description: el.description });
                            }
                            return { ...prevState, services: updatedServices };
                          })

                        }} />
                        <EditOutlined style={{ margin: '5px', cursor: 'pointer' }} onClick={() => {
                          const curr = [...edit]
                          if (edit.includes(el.serviceId)) {
                            const filtered = curr.filter(id => id !== el.serviceId)
                            setEdit(filtered)
                            form.resetFields()

                          } else {
                            curr.push(el.serviceId)
                            setEdit(curr)
                          }

                        }} />
                        <DeleteFilled style={{ cursor: "pointer", color: "red" }} onClick={() => { deletService(el.serviceId) }} />
                      </div>
                      <div style={{ width: '75%' }}>
                        <TextArea
                          disabled={!edit.includes(el.serviceId)}
                          placeholder="Açıklama "
                          style={{ margin: '8px 0', width: '75%' }}
                          defaultValue={el.description!}
                          onChange={(e) => {
                            const desc = e.target.value;
                            setFormValues(prevState => {
                              const updatedServices = [...prevState?.services!];
                              const index = updatedServices.findIndex(service => service.serviceId === el.serviceId);
                              if (index !== -1) {
                                updatedServices[index].description = desc;
                              } else {

                                updatedServices.push({ serviceId: el.serviceId, serviceFee: el.serviceFee, description: desc });
                              }
                              return { ...prevState, services: updatedServices };
                            });
                          }}
                        />

                      </div>

                    </div>

                  );
                })
              }
            </Form.Item>) : null
        }


        <Form.Item label='Hizmet Ekle'>
          <Checkbox.Group>
            {
              services.map((el: IService) => (
                <div key={el.id} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                  <Checkbox
                    value={el.id}
                    onChange={(e) => {
                      const serviceId = e.target.value
                      let currSelected = [...selectedServices]
                      if (currSelected.includes(serviceId)) {
                        currSelected = currSelected.filter((id) => serviceId !== id)
                        const addedServices = [...formValues.services!]
                        const stayedServices = addedServices.filter((service) => service.serviceId !== el.id)
                        setFormValues({ ...formValues, services: stayedServices })
                      }
                      else currSelected.push(serviceId)
                      setSelectedServices(currSelected)
                    }}
                  >{el.serviceName} :</Checkbox>
                  {
                    selectedServices.includes(el.id) &&
                    <>
                      <Input
                        placeholder="Hizmet bedeli "
                        style={{ margin: 8, width: '20%' }}
                        onChange={(e) => {
                          const fee = e.target.value
                          let currServices = [...formValues.services!]
                          const newServiceFee = { serviceId: el.id, serviceFee: Number(fee), description: null }
                          const index = currServices.findIndex(service => service.serviceId === el.id);
                          if (index !== -1) {
                            if (fee.trim().length === 0) {
                              const updatedServices = formValues.services?.filter((service: any) => service.serviceId !== el.id)
                              currServices = updatedServices!
                            } else currServices[index].serviceFee = Number(fee);
                          } else {
                            currServices.push(newServiceFee);
                          }
                          setFormValues({ ...formValues, services: currServices })
                        }}
                      />
                      <TextArea
                        placeholder="Açıklama "
                        style={{ margin: 8, width: '75%' }}
                        value={el.description}
                        onChange={(e) => {
                          const desc = e.target.value;
                          setFormValues(prevState => {
                            const updatedServices = [...prevState?.services!];
                            const index = updatedServices.findIndex(service => service.serviceId === el.id);
                            if (index !== -1) {
                              updatedServices[index].description = desc;
                            } else {

                              updatedServices.push({ serviceId: el.id, serviceFee: null, description: desc });
                            }
                            return { ...prevState, services: updatedServices };
                          });
                        }}
                      />
                    </>
                  }
                </div>
              ))
            }
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label='Description' >
          <TextArea
            value={formValues.description}
            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
            placeholder="Hizmet sağlayıcı açıklaması giriniz.."
            autoSize={{ minRows: 20 }}
          />

        </Form.Item>





        {/* <Editor
          value={formValues.description}
          onEditorChange={(value) => setFormValues({ ...formValues, description: value })}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        /> */}


        {/* </Form.Item> */}

        <Form.Item label="Image" name="image">
          <Upload
            beforeUpload={(file) => {
              setFormValues({ ...formValues, profile_image: file });
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
          <p style={{ marginBottom: '20px', fontStyle: 'italic', color: 'red' }}>
            ** Yüklediğiniz fotoğrafların dosya adlarını ilgili hizmet sağlayıcı ile aynı isimde oluşturmanızı rica ederiz...
          </p>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>

  );
};

export default EditProvider;