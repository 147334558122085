import { Avatar, Card, Skeleton } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";

interface LoadingCardInterface {
  loading: boolean;
}

const LoadingCard: React.FC<LoadingCardInterface> = ({ loading }) => {
  return (
    <Card
      style={{ width: "100%", height: "100%", marginTop: 16 }}
      actions={[
        <SettingOutlined key="setting" />,
        <EditOutlined key="edit" />,
        <EllipsisOutlined key="ellipsis" />,
      ]}
    >
      <Skeleton loading={loading} avatar active>
        <Meta
          avatar={<Avatar size={64} icon={<UserOutlined />} />}
          title="Card title"
          description="This is the description"
        />
      </Skeleton>
    </Card>
  );
};
export default LoadingCard;
