import adminService from '../services/adminService'
import alertNotification from '../utils/alertNotification'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UpdateServiceForm from '../components/servicesForms/UpdateServiceForm'
import { IService } from '../interfaces/adminInterfaces'
import Loading from '../components/loader/Loading'

const Service = () => {

    const { id } = useParams()

    const [service, setService] = useState<IService>()

    const getService = () => {
        adminService.getService(+id!)
            .then((res) => setService(res.data))
            .catch((err) => alertNotification('error', err.response.data.message))
    }



    useEffect(() => {
        getService()
    }, [])

    return (
        <div>
            {
                service ?
                    <UpdateServiceForm
                        service={service}
                        id={+id!}
                    />
                    :
                    <Loading />
            }

        </div>
    )
}

export default Service
