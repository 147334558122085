import React from "react";
import { Button, Card, Tag } from "antd";
import WalletEditForm from "./WalletEditForm";

interface Props {
  balance: number;
  userId: number;
  getUserDetail: (id?: string) => void;
}
const UserWalletCard: React.FC<Props> = ({
  balance,
  userId,
  getUserDetail,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Card style={{ height: "100%" }}>
      <Tag color="#2db7f5">WALLET</Tag>
      <hr color="#2db7f5" />
      <p style={{ marginTop: "18px" }}>Current Balance</p>
      <h1 style={{ color: "#2dd45b", fontWeight: "bold" }}>
        ${balance ? balance : 0}
      </h1>
      <Button type="primary" onClick={() => setOpen(true)}>
        Düzenle
      </Button>
      <WalletEditForm
        open={open}
        setOpen={setOpen}
        userId={userId}
        getUserDetail={getUserDetail}
      />
    </Card>
  );
};

export default UserWalletCard;
