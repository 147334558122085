import CreateBSRRankFomr from '../components/bsrForms/CreateBSRRankFomr';
import CreateBSRForm from '../components/bsrForms/CreateBSRCategoryForm';
import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';
import { Button, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import UpdateBSRRankForm from '../components/bsrForms/UpdateBSRRankForm';

const EditBSR = () => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Tarih",
      dataIndex: "date",
      key: "date",

    },
    {
      title: "BSR Rank",
      dataIndex: "bsrRank",
      key: "bsrRank",

    },
    {
      title: "Satış Sayısı",
      dataIndex: "sales",
      key: "sales",

    },
    {
      title: "Amazon Kategori Id",
      dataIndex: "amzCategoryId",
      key: "amzCategoryId",

    },

    {
      title: "Sil",
      render: (record: any) => {
        return <Button style={{ border: "1px solid red", color: "red" }} onClick={deleteRank} >Sil</Button>;
      },
      key: "sil",

    },
    {
      title: "Düzenle",
      render: (record: any) => {
        return <Button type='primary' onClick={() => {
          setSelected(record.id)
          setShowUpdateModal(true)
        }}>Düzenle</Button>;
      },
      key: "addressLine",

    },
  ]
  const [selected, setSelected] = useState();
  const [category, setCategory] = useState();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const params = useParams();

  const deleteRank = async () => {
    try {
      await adminService.deleteAmazonCategoryRank(Number(params.id))
    } catch (error) {

    }
  }
  const getCategoryById = async () => {
    // console.log(params)
    try {
      const res = await adminService.getAmazonCategoryById(Number(params?.id))
      setCategory(res.data)

    } catch (error) {
      alertNotification("error", "Kategori getirilemedi")
    }
  }
  const handleUpdateOk = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateCancel = () => {

    setShowUpdateModal(false);
  };

  useEffect(() => {
    getCategoryById()
  }, [params?.id])

  return (
    <div>
      <Table dataSource={category} columns={columns} />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "1rem" }}>
        <CreateBSRRankFomr />
        <Modal width="70%" bodyStyle={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }} title="Kategori Oluştur" footer={null} open={showUpdateModal} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
          <UpdateBSRRankForm selected={Number(selected)} id={Number(params.id)} setShowUpdateModal={setShowUpdateModal} getCategoryById={getCategoryById} />
        </Modal>
      </div>
    </div>
  )
}

export default EditBSR