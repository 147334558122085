import { useContext } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "../pageTables/Tables.module.css";
import AuthContext from "../../../context/AuthContext";

interface DataType {
  id: string;
  coursesTrendMasIyzProd: string;
  coursesTrendMasStrProd: string;
  coursesAmazonMasIyzProd: string;
  coursesAmazonMasStrProd: string;
}
interface CoursesLinksTableInterface {
  generateId: Function;
  handleRender: Function;
}
const CoursesLinksTable: React.FC<CoursesLinksTableInterface> = ({
  generateId,
  handleRender,
}) => {
  const { user } = useContext(AuthContext);
  const userId = user?.id;

  const data: DataType[] = [
    {
      id: "id",
      // Courses Links
      coursesTrendMasIyzProd: `https:/community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=course&productId=${generateId(
        3
      )}-3-${generateId(3)}`,
      coursesTrendMasStrProd: `https:/community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=course&productId=${generateId(
        3
      )}-7-${generateId(3)}`,
      coursesAmazonMasIyzProd: `https:/community.rexven.com/payment/?sellerId=${userId}&platform=iyzico&type=course&productId=${generateId(
        3
      )}-4-${generateId(3)}`,
      coursesAmazonMasStrProd: `https:/community.rexven.com/payment/?sellerId=${userId}&platform=stripe&type=course&productId=${generateId(
        3
      )}-8-${generateId(3)}`,
    },
  ];

  const coursesLinksColumns: ColumnsType<DataType> = [
    {
      title: "COURSES",
      key: "courses",
      children: [
        {
          title: "TRENDYOL MASTER",
          key: "trendyolMaster",
          children: [
            {
              title: "STRIPE",
              render: (text) => handleRender(text.coursesTrendMasStrProd),
              key: "trendyolMasterStripe",
            },
            {
              title: "IYZICO",
              render: (text) => handleRender(text.coursesTrendMasIyzProd),
              key: "trendyolMasterIyzico",
            },
          ],
        },
        {
          title: "AMAZON MASTER",
          key: "amazonMaster",
          children: [
            {
              title: "STRIPE",
              render: (text) => handleRender(text.coursesAmazonMasStrProd),
              key: "amazonMasterStripe",
            },
            {
              title: "IYZICO",
              render: (text) => handleRender(text.coursesAmazonMasIyzProd),
              key: "amazonMasterIyzico",
            },
          ],
        },
      ],
    },
  ];
  return (
    <>
      <Table
        className={styles.table}
        columns={coursesLinksColumns}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.id}
        bordered
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default CoursesLinksTable;
