import React from "react";
import type { ColumnsType } from "antd/es/table";
import {
  Table,
  Pagination,
  Space,
  Button,
  Form,
  PaginationProps,
  Modal,
  Select,
} from "antd";
import adminService from "../../../services/adminService";
import alertNotification from "../../../utils/alertNotification";

export interface GiftedTableProps {
  cancelGifted: (id: number) => Promise<void>;
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  datas: any;
}
const { Option } = Select;
const GiftMembersTable: React.FC<GiftedTableProps> = ({
  cancelGifted,
  current,
  setCurrent,
  datas,
}) => {
  const [form] = Form.useForm();
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [selectedProduct, setSelectedProduct] =
    React.useState<number | null>(null);
  const columns: ColumnsType<any> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İsim",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Soyisim",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "packageName",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Lifetime",
      dataIndex: "isLifeTime",
      key: "isLifeTime",
    },
    {
      title: "Başlangıç",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "Bitiş",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Durum",
      dataIndex: "isActive",
      key: "isActive",
    },

    {
      title: "İptal",
      render: (_, record) => (
        <Space size="middle">
          {
            <Button
              disabled={record.isActive === "Pasif"}
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => cancelGifted(record?.id)}
            >
              İptal Et
            </Button>
          }
        </Space>
      ),
      key: "delete",
    },
    {
      title: "Düzenle",
      render: (_, record) => (
        <Space size="middle">
          {
            <Button
            
              style={{ backgroundColor: "blue", color: "white" }}
              onClick={() => {
                setSelectedProduct(record.id);
                setOpenUpdateModal(true);
              }}
            >
              Düzenle
            </Button>
          }
        </Space>
      ),
      key: "edit",
    },
  ];

  const onChange: PaginationProps["onChange"] = (value) => {
    setCurrent(value);
  };

  const handleSubmit = async (values:any) => {
    try {
      if(selectedProduct){
        
        await adminService.updateGift(selectedProduct, values);
        alertNotification("success", "Güncelleme başarılı!");
      }
    } catch (error: any) {
      alertNotification("error", error.response.data.message);
    } finally {
      form.resetFields();
      setOpenUpdateModal(false)
    }
  };
  return (
    <div style={{maxWidth:"100%", overflow:'scroll'}}>
      <Table dataSource={datas} pagination={false} columns={columns} />
      <Modal
        width="70%"
        title="Ürün Güncelle"
        footer={null}
        open={openUpdateModal}
        onOk={() => {
          setSelectedProduct(null);
          setOpenUpdateModal(false);
        }}
        onCancel={() => {
          setSelectedProduct(null);
          setOpenUpdateModal(false);
        }}
      >
        <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18, offset: 1 }}
        onFinish={handleSubmit}
        >
          <Form.Item name={"packageName"} label="Paket Adı">
            <Select>
              <Option value="Elit">Elit</Option>
              <Option value="Rexven Premium">Rexven Premium</Option>
              <Option value="Rexven Plus">Rexven Plus</Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 20 }}>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Onayla
          </Button>
        </Form.Item>
        </Form>
      </Modal>
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        defaultCurrent={1}
        total={20}
        style={{ marginTop: "0.5rem" }}
      />
    </div>
  );
};

export default GiftMembersTable;
