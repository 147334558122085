import React, { useEffect, useState } from 'react'
import adminService from '../services/adminService';
import alertNotification from '../utils/alertNotification';
import { Button, Form, Input, InputNumber } from 'antd';
import { useParams } from 'react-router-dom';
import { ExclusiveProduct } from '@src/interfaces/adminInterfaces';

const EditExclusiveProduct = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [product, setProduct] = useState<ExclusiveProduct>();

  const handleFormSubmit = (values: any) => {
    let payload = {
      asin: values.asin,
      leadTime: Number(values.leadTime),
      shippingCost: Number(values.shippingCost),
      productCost: Number(values.productCost),
      maxOrderQuantity: Number(values.maxOrderQuantity)
    }
    adminService.editExclusiveProduct(Number(values.id), payload)
      .then((res) => {
        // console.log('res', res);
        alertNotification('success', 'Basariyla güncellendi')

        form.resetFields();
      })
      .catch((err) => {
        // console.log('err', err);
        alertNotification('error', err.response.data.message);
      });
  };
  const getProduct = async () => {
    try {
      const res = await adminService.getExclusiveProductById(id!);
      let product = res.data;
      // console.log(product)
      setProduct({ id: product.id, asin: product.asin, shippingCost: product.shippingCost, productCost: product.productCost, maxOrderQuantity: product.maxOrderQuantity, leadTime: product.leadTime })

    } catch (error) {
      alertNotification("error", "Ürün Bulunamadı!")
    }
  }
  useEffect(() => {
    getProduct();
  }, [id])

  return (
    <div style={{ display: 'flex', width: "100%", flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ marginBottom: '20px' }}>Ürün Güncelleme Sayfası</h2>
      {
        product?.id && <Form style={{ width: "80%" }} onFinish={handleFormSubmit} form={form} labelCol={{ span: 4 }} initialValues={{ id: product!.id, asin: product!.asin, productCost: product!.productCost, shippingCost: product!.shippingCost, leadTime: product!.leadTime, maxOrderQuantity: product!.maxOrderQuantity }} wrapperCol={{ offset: 1, span: 18 }}>

          <Form.Item label="ASIN" name="asin">
            <Input />
          </Form.Item>
          <Form.Item label="Ürün Maliyeti" name="productCost" >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="BSR" name="bsr" >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Kargo Maliyeti" name="shippingCost" >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="id" name="id" style={{ display: "none" }} >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Tedarik Süresi(gün)" name="leadTime" >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Max. Sipariş Miktarı" name="maxOrderQuantity" >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }

    </div>
  )
}

export default EditExclusiveProduct