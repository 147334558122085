import { Button, Modal, Select, Table } from 'antd';
import adminService from '../services/adminService'
import alertNotification from '../utils/alertNotification';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CreateBSRCategoryForm from '../components/bsrForms/CreateBSRCategoryForm';
import UpdateBSRCategoryForm from '../components/bsrForms/UpdateBSRCategoryForm';
import axios from 'axios';

const countries = [
  { label: "USA", value: "usa" },
  { label: "Canada", value: "canada" },
  { label: "France", value: "france" },
  { label: "Britain", value: "britain" },
  { label: "Spain", value: "spain" },
  { label: "Italy", value: "italy" },
  { label: "Australia", value: "autralia" },
  { label: "Mexico", value: "mexico" },

]

const AmazonBSR = () => {

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Kategori",
      dataIndex: "categoryName",
      key: "categoryName",

    },
    {
      title: "Ülke",
      dataIndex: "country",
      key: "country",

    },
    {
      title: "Amazon Kategori Id",
      dataIndex: "amazonCategoryId",
      key: "amazonCategoryId",

    },

    {
      title: "Bsr Rank",
      render: (record: any) => {
        return <Link to={`/amazon-bsr/${record.id}`}>Bsr Detay</Link>;
      },
      key: "addressLine",

    },
    {
      title: "Düzenle",
      render: (record: any) => {
        return <Button style={{ border: "1px solid blue", color: "blue" }} onClick={() => {
          // console.log(record)
          setSelectedCategory(record.id)
          setShowUpdateModal(true)
        }}>Düzenle</Button>;
      },
      key: "addressLine",

    },
    {
      title: "Sil",
      render: (record: any) => {
        return <Button style={{ border: "1px solid red", color: "red" }} onClick={() => deleteCategory(record.id)}>Sil</Button>;
      },
      key: "addressLine",

    },
  ]
  const [categories, setCategories] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [country, setCountry] = useState("usa");

  const onChange = (value: string) => {
    setCountry(value)
  };

  const deleteCategory = async (id: number) => {
    try {
      await adminService.deleteAmazonCategory(id)
      await getCategories();
    } catch (error) {
      // console.log("Silinemedi")
    }
  }
  const showCreateModal = () => {
    setOpenCreateModal(true)
  }

  const handleOk = () => {
    setOpenCreateModal(false);
  };

  const handleCancel = () => {
    setOpenCreateModal(false);
  };
  const handleUpdateOk = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateCancel = () => {

    setShowUpdateModal(false);
  };

  const getCategories = async () => {
    try {
      const res = await adminService.getAmazonCategories(country);
      setCategories(res.data)
      // console.log(res.data)
    } catch (error) {
      alertNotification("error", "Kategoriler getirilemedi")
    }
  }
  const startCron = async () => {
    try {
      await adminService.startCron();
    } catch (error) {
      alertNotification("error", "Cron işleminde bir hata oluştu!")
    }
  }
  useEffect(() => {
    getCategories();

  }, [country])

  return (
    <div>
      <Button style={{ marginBottom: "1rem", border: "1px solid green" }} onClick={showCreateModal}>Yeni Kategori</Button>

      <Select onChange={onChange}
        options={countries} placeholder="Ülke seçiniz" />

      <div style={{ display: "flex" }}>

        <Button onClick={startCron} style={{ marginBottom: "1rem", border: "1px solid red" }}>Yeniden Hesapla</Button>
        <div><ExclamationCircleOutlined style={{ color: "red", padding: "6px" }} />Güncelleme veya ekleme yaptığınız zaman database'in güncellenmesi için bu buttona basmanız yeterli olacaktır</div>
      </div>
      <Table columns={columns} dataSource={categories} pagination={{ pageSize: 30 }} />
      <Modal width="70%" bodyStyle={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }} title="Kategori Oluştur" footer={null} open={openCreateModal} onOk={handleOk} onCancel={handleCancel}>
        <CreateBSRCategoryForm setOpenCreateModal={setOpenCreateModal} getCategories={getCategories} />
      </Modal>
      <Modal width="70%" bodyStyle={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }} title="Kategori Oluştur" footer={null} open={showUpdateModal} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
        <UpdateBSRCategoryForm selected={selectedCategory!} country={country} setShowUpdateModal={setShowUpdateModal} />
      </Modal>
    </div>
  )
}

export default AmazonBSR