
import { IDeloitteCreateBsrObject } from '../../pages/DeloitteBsrDetail';
import { Button, Form, FormInstance, Input } from 'antd'
import React from 'react'

interface IUpdateBsrFormProps {
  formSubmit: (values: IDeloitteCreateBsrObject) => void;
  formReference: FormInstance;
}

function UpdateBsrForm({formSubmit, formReference}: IUpdateBsrFormProps) {
  return (
    <div>
      <Form
        onFinish={formSubmit}
        form={formReference}
        labelCol={{ span: 6 }}
      >
        <Form.Item
          label='BSR'
          name='bsrRank'
          rules={[{ required: true, message: 'Please input BSR!' }]}
        >
          <Input
            type='number'
          />
        </Form.Item>
        <Form.Item
          label='Sale Count'
          name='sales'
          rules={[{ required: true, message: 'Please input Sale Count!' }]}
        >
          <Input
            type='number'
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit'>Submit</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default UpdateBsrForm