import { Image } from "antd";

import styles from "../../styles/PageNotFound.module.css";

import rexvenLogo from "../assets/rexven-logo.svg";

export default function Loading() {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.innerContainer}>
        <Image preview={false} src="/rexven-logo.jpg" alt="Rexven-Logo" />
        <Image preview={false} src={rexvenLogo} alt="Rexven-Logo" />
      </div>
    </div>
  );
}
