import { Route, Routes } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";

import AdminRoutes from "./routers/AdminRouter";

// pages
import Coupons from "./pages/Coupons";
import CraeteCoupon from "./pages/CraeteCoupon";
import ErrorLogs from "./pages/ErrorLogs";
import Home from "./pages/Home";
import Invoices from "./pages/Invoices";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Subscriptions from "./pages/Subscriptions";
import Transactions from "./pages/Transactions";
import UserDetail from "./pages/UserDetails";
import Users from "./pages/Users";
import Courses from "./pages/Courses";
import ProductLinks from "./pages/ProductLinks";
import UserCourses from "./pages/UserCourses";
import Packages from "./pages/Packages";
import CoursesDetail from "./pages/CoursesDetail";
import CreateProviders from "./pages/CreateProviders";
import ServiceProviders from "./pages/ServiceProviders";
import CreateQuestion from "./pages/CreateQuestion";
import FaqPage from "./pages/FaqPage";
import EditQuestion from "./pages/EditQuestion";
import EditProvider from "./pages/EditProvider";
import GiftMembers from "./pages/GiftMembers";
import CreateExclusiveProduct from "./pages/CreateExclusiveProduct";
import EditExclusiveProduct from "./pages/EditExclusiveProduct";
import ExclusiveProducts from "./pages/ExclusiveProducts";
import ProviderSubsCreate from "./pages/ProviderSubsCreate";
import ServiceProviderSub from "./pages/ServiceProviderSub";
import ProviderSubsEdit from "./pages/ProviderSubsEdit";
import ProviderSubsDetail from "./pages/ProviderSubsDetail";
import Plans from "./pages/Plans";
import PlanCreate from "./pages/PlanCreate";
import UnPaids from "./pages/UnPaids";
import BirFatura from "./pages/BirFatura";
import AmazonBSR from "./pages/AmazonBSR";
import EditBSR from "./pages/EditBSR";
import BlogPost from "./pages/BlogPost";
import AliExpressExcel from "./pages/AliExpressExcel";
import EditBlog from "./pages/EditBlog";
import CreateBlog from "./pages/CreateBlog";
import Producers from "./pages/Producers";
import AICats from "./pages/AICats";
import EditAICat from "./pages/EditAICat";
import CreateAICat from "./pages/CreateAICat";
import CreateAIData from "./pages/CreateAIData";
import EditAIData from "./pages/EditAIData";
import Services from "./pages/Services";
import Service from "./pages/Service";
import ContactFormAnswers from "./pages/ContactFormAnswers";
import Announcements from "./pages/Announcements";
import CreateAnnouncement from "./pages/CreateAnnouncement";
import TestAI from "./pages/TestAI";
import WalletTransactions from "./pages/WalletTransactions";
import DeloitteAmazonBsr from "./pages/DeloitteAmazonBsr";
import DeloitteBsrDetail from "./pages/DeloitteBsrDetail";

import MarketplaceProducts from "./pages/MarketplaceProducts";
import CreateStore from "./pages/CreateStore";


function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path={"/login"} element={<Login />} />
          {/* ADMIN ROUTES */}
          <Route element={<AdminRoutes />}>
            <Route path={"/"} element={<Home />} />
            <Route path={"/users"} element={<Users />} />
            <Route path={"users/:id"} element={<UserDetail />} />
            <Route path={"/error-logs"} element={<ErrorLogs />} />
            <Route path={"/courses"} element={<Courses />} />
            <Route path={"/courses/:courseId"} element={<CoursesDetail />} />
            <Route path={"/user-courses"} element={<UserCourses />} />
            <Route path={"/invoices"} element={<Invoices />} />
            <Route path={"/coupons"} element={<Coupons />} />
            <Route path={"/subscriptions"} element={<Subscriptions />} />
            <Route path={"/transactions"} element={<Transactions />} />
            <Route path={"/create-coupon"} element={<CraeteCoupon />} />
            <Route path={"/product-links"} element={<ProductLinks />} />
            <Route path={"/packages"} element={<Packages />} />
            <Route path={"/create-provider"} element={<CreateProviders />} />
            <Route path={"/service-providers"} element={<ServiceProviders />} />
            <Route path={"/service-providers/:id"} element={<EditProvider />} />
            <Route
              path={"/wallet-transactions"}
              element={<WalletTransactions />}
            />
            <Route
              path={"/create-provider-sub"}
              element={<ProviderSubsCreate />}
            />
            <Route
              path={"/service-providers-sub"}
              element={<ServiceProviderSub />}
            />
            <Route
              path={"/service-providers-sub/:id"}
              element={<ProviderSubsEdit />}
            />
            <Route
              path={"/service-providers-sub/detail/:id"}
              element={<ProviderSubsDetail />}
            />
            <Route path={"/create-question"} element={<CreateQuestion />} />
            <Route path={"/questions"} element={<FaqPage />} />
            <Route path={"/gift-members"} element={<GiftMembers />} />
            <Route path={"/questions/:id"} element={<EditQuestion />} />
            <Route
              path={"/create-exclusive-product"}
              element={<CreateExclusiveProduct />}
            />
            <Route
              path={"/edit-exclusive-product/:id"}
              element={<EditExclusiveProduct />}
            />
            <Route
              path={"/exclusive-products"}
              element={<ExclusiveProducts />}
            />
            <Route path={"/plans"} element={<Plans />} />
            <Route path={"/plans-create"} element={<PlanCreate />} />
            <Route path={"/unpaids"} element={<UnPaids />} />
            <Route path={"/birfatura"} element={<BirFatura />} />
            <Route path={"/amazon-bsr"} element={<AmazonBSR />} />
            <Route path={"/amazon-bsr/:id"} element={<EditBSR />} />
            <Route path={"/ali-express"} element={<AliExpressExcel />} />
            <Route path={"/producers"} element={<Producers />} />

            <Route path={"/blog-posts"} element={<BlogPost />} />
            <Route path={"/blog-posts/:lang/:id"} element={<EditBlog />} />
            <Route path={"/create-blog-post"} element={<CreateBlog />} />

            <Route path={"/ai-cats"} element={<AICats />} />
            <Route path={"/ai-cats/:id"} element={<EditAICat />} />
            <Route path={"/create-ai-cat"} element={<CreateAICat />} />
            <Route path={"/test-ai"} element={<TestAI />} />

            <Route path={"/create-ai-data"} element={<CreateAIData />} />
            <Route path={"/ai-data/:id"} element={<EditAIData />} />
            <Route path={"/services"} element={<Services />} />
            <Route path={"/services/:id"} element={<Service />} />
            <Route
              path={"/contact-form-answers"}
              element={<ContactFormAnswers />}
            />
            <Route path={"/announcements"} element={<Announcements />} />
            <Route
              path={"/create-announcement"}
              element={<CreateAnnouncement />}
            />
            <Route
              path="/deloitte-amazon-bsr"
              element={<DeloitteAmazonBsr />}
            />
            <Route

              path="/marketplace-products"
              element={<MarketplaceProducts />}
            />
            <Route 
              path={`/deloitte-amazon-bsr/:categoryId`}
              element={<DeloitteBsrDetail />}
            />

            <Route path={"/create-store"} element={<CreateStore />} />

            <Route path={"*"} element={<PageNotFound />} />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
