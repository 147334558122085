import adminService from '../services/adminService';
import { useEffect, useState } from 'react'
import ContactFormAnswersTable from '../components/tables/pageTables/ContactFormAnswersTable'
import { Pagination } from 'antd';

const ContactFormAnswers = () => {

    const [answers, setAnswers] = useState([]);
    const [current, setCurrent] = useState(1);
    const [totalPagecount, setTotalPageCount] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchAnswers = () => {
        setLoading(true)
        adminService.getContactFormAnswers(current)
            .then((res) => {
                setAnswers(res.data.data)
                setTotalPageCount(res.data.totalPageCount)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchAnswers()
    }, [current])

    return (
        <>
            <ContactFormAnswersTable
                answers={answers}
                loading={loading}
            />
            <Pagination
                showSizeChanger={false}
                current={current}
                onChange={(value) => setCurrent(value)}
                pageSize={20}
                total={totalPagecount * 20}
            />
        </>
    )
}

export default ContactFormAnswers