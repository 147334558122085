import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Row, Col, AutoComplete } from "antd";
import adminService from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { FaqCategory, Question } from "@src/interfaces/adminInterfaces";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const CreateAIData = () => {
  const [form] = Form.useForm();
  const [formalt] = Form.useForm();
  const [categories, setCategories] = useState<FaqCategory[]>([
    { id: 1, name: "hello" },
  ]);
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [allAiCats, setAllAiCats] = useState<any[]>([]);
  const [selectedCat, setSelectedCat] = useState<any>();
  const onSelect = (data: string) => {
    let selectedCat =
      allAiCats.filter((item: any) => item.categoryName === data).length > 0
        ? allAiCats.filter((item: any) => item.categoryName === data)[0].id
        : 0;

    setSelectedCat(selectedCat);
    console.log("onSelect", data, selectedCat);
  };

  const getPanelValue = (text: string) => {
    //uppercase category names
    let allCatsCategoryNames: string[] = [];
    allAiCats.map((item: any) => {
      if (item.categoryName) {
        allCatsCategoryNames.push(item.categoryName);
      }
    });
    let values: any[] = [];
    allCatsCategoryNames.map((item: string) => {
      if (item && item.toUpperCase().includes(text.toUpperCase())) {
        values.push({ value: item });
      }
    });
    values.push({ value: "Top" });
    return !text ? [] : values;
  };
  const handleAiCatSubmit = async (values: any) => {
    try {
    
      console.log(values.info)
      let tempValue={
        info:values.info,
        aiCategoryChainId:selectedCat
      }
      await adminService.createAIData(tempValue);

      form.resetFields();
      alertNotification("success", "Kayıt başarılı!");
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  const getAllAiCats = async () => {
    try {
      const res = await adminService.getAICats("all");
      setAllAiCats(res.data);
    } catch (error) {
      alertNotification("error", "Bir hata oluştu!");
    }
  };

  useEffect(() => {
    getAllAiCats();
  }, []);
  return (
    <>
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
        Sıkça Sorulan Soruları Ekle
      </h1>
      <Row justify="space-between">
        <Col span={24}>
          <h1 style={{ textAlign: "center" }}>
            Yeni bir soru eklemek için formu doldurunuz
          </h1>
          <Form
            style={{ width: "100%" }}
            onFinish={handleAiCatSubmit}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Form.Item label="Parent Category" name="categoryId">
              <AutoComplete
                options={options}
                style={{ width: 400 }}
                onSelect={onSelect}
                onSearch={(text) => setOptions(getPanelValue(text))}
                placeholder="Category Name"
              />
            </Form.Item>
            <Form.Item label="Info" name="info">
              <TextArea style={{ height: "500px" }} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateAIData;
